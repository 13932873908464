import { IImageData, LabelName } from "@store/labels/types";
import { LabelsSelector } from "@store/selectors/LabelsSelector";
import { BlastSelector } from "@store/selectors/BlastSelector";
import { IDeserializedPreblastDocument } from "./DrillMapSerializer";
import { LabelType } from "@data/enums/LabelType";
import { PreblastApi } from "@api/elevat3dApi/blast/PreblastApi";

export type JSONFilesSpec = {
  labelNameFile: File;
  annotationFiles: File[];
};

export class PreblastDocumentImporter {
  public labelType: LabelType[];
  constructor(labelType: LabelType[]) {
    this.labelType = labelType;
  }

  public async invoke(
    onSuccess: (
      loadedPreblastDocument: IDeserializedPreblastDocument,
      imageData: IImageData,
      labelNames: LabelName[]
    ) => any,
    onFailure: (error?: Error) => any
  ): Promise<void> {
    const sourceImagesData = LabelsSelector.getImageData();
    const { siteId, blastId } = BlastSelector.getBlastDetails();

    await PreblastApi.invokeGetPreblastDocument({
      siteId,
      blastId,
      file: sourceImagesData.fileData,
    })
      .then(({ deserializedPreblastDocument, labels }) =>
        onSuccess(
          deserializedPreblastDocument,
          PreblastDocumentImporter.injectNewImageData(
            sourceImagesData,
            deserializedPreblastDocument,
            labels.map((l) => l.id)
          ),
          labels
        )
      )
      .catch((error) => {
        onFailure(error as Error);
        const err = error as Error;
        console.log(err.stack);
      });
  }

  public static injectNewImageData(
    sourceImageData: IImageData,
    preblastDocument: IDeserializedPreblastDocument,
    labelNameIds: string[] = []
  ) {
    const rectsToAdd = preblastDocument.image_data.labelRects;
    const linesToAdd = preblastDocument.image_data.labelLines;
    const pointsToAdd = preblastDocument.image_data.labelPoints;
    return {
      ...sourceImageData,
      labelRects: rectsToAdd,
      labelLines: linesToAdd,
      labelPoints: pointsToAdd,
      labelNameIds,
    };
  }
}
