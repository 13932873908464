import styles from "./LeftBar.module.scss";
import { Link } from "react-router-dom";
import { useState } from "react";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import StarOutline from "@mui/icons-material/StarOutline";
import FilledStar from "@mui/icons-material/Star";
import {
  useAccountViewContextState,
  useAccountViewDispatch,
  updateLocalFavouriteBlasts,
} from "../accountViewContext";
import { BlastWithoutSiteContext } from "data/Elevat3dApiTypes/BlastTypes";
import BlastsApi from "@api/elevat3dApi/BlastsApi";

export default function FavouritesTab() {
  const dispatch = useAccountViewDispatch();
  const { localFavouriteBlasts, isFavouritesLoading } =
    useAccountViewContextState();
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);

  const unfavouriteBlast = (blast: BlastWithoutSiteContext) => {
    const newFavourites = localFavouriteBlasts.filter(
      (favourite) => favourite.blast_id !== blast.blast_id
    );
    dispatch(updateLocalFavouriteBlasts(newFavourites));
    BlastsApi.favouriteBlast({
      siteId: blast.site_id,
      blastId: blast.blast_id,
      favourite: false,
    });
  };

  const renderContent = () => {
    if (isFavouritesLoading) {
      return (
        <div>
          <p className={styles.HelperText}>Loading favourites...</p>
        </div>
      );
    }
    if (localFavouriteBlasts.length > 0) {
      return (
        <>
          {localFavouriteBlasts.map((blast) => {
            return (
              <li key={blast.blast_id}>
                <Link
                  className={`${styles.Link}`}
                  to={`/sites/${blast.site_id}/blasts/${blast.blast_id}`}
                >
                  <p>
                    <span className={styles.SpecialText}>
                      {blast.block_name}
                    </span>{" "}
                    <span className={styles.StandardText}>at</span>{" "}
                    <span className={styles.SpecialText}>
                      {blast.site_name}
                    </span>
                  </p>
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      unfavouriteBlast(blast);
                    }}
                    className={styles.FavouriteWrapper}
                  >
                    <FilledStar className={styles.Icon} />
                  </div>
                </Link>
              </li>
            );
          })}
        </>
      );
    }

    return (
      <div>
        <p className={styles.HelperText}>
          Click the star next to a blast to add it to your favourites.
        </p>
      </div>
    );
  };

  return (
    <div className={`${styles.ExpandableListWrapper} ${styles.WithBorder}`}>
      <div
        onClick={() => setDropdownIsOpen(!dropdownIsOpen)}
        className={styles.ListHeader}
      >
        <StarOutline className={styles.Icon} />
        <h2 className={styles.Text}>Favourites</h2>
        <div
          className={`${styles.DropdownArrowWrapper} ${
            dropdownIsOpen ? styles.Open : ""
          }`}
        >
          <ArrowRightIcon
            className={`${styles.Icon} ${dropdownIsOpen ? styles.Open : ""}`}
          />
        </div>
      </div>
      <ul className={`${styles.List} ${dropdownIsOpen ? styles.Open : ""}`}>
        {renderContent()}
      </ul>
    </div>
  );
}
