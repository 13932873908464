import CustomTextInput from "@views/Common/CustomTextInput/CustomTextInput";
import { TextButton } from "@views/Common/TextButton/TextButton";
import styles from "./AuthView.module.scss";
import React, { useState } from "react";
import { LoadingState } from "@data/enums/LoadingState";
import FirebaseAuth from "modules/firebaseModules/auth";
import { useNavigate } from "react-router-dom";

export default function ForgotPasswordForm() {
  const [email, setEmail] = useState("");
  const [loadingState, setLoadingState] = useState(LoadingState.IDLE);
  const navigate = useNavigate();
  const [errorState, setErrorState] = useState<{
    message:
      | "Email is required"
      | "Email not recognized"
      | "Sorry, something went wrong. Please try again later.";
    error: boolean;
  }>({
    message: "Email is required",
    error: false,
  });

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorState({ ...errorState, error: false });
    setEmail(e.target.value);
  };
  const sendPasswordResetEmail = async (e) => {
    e.preventDefault();
    if (email === "") {
      setErrorState({ error: true, message: "Email is required" });
      return;
    }

    setLoadingState(LoadingState.LOADING);

    if (!(await FirebaseAuth.emailExists(email))) {
      setErrorState({ error: true, message: "Email not recognized" });
      setLoadingState(LoadingState.IDLE);
      return;
    }
    try {
      await FirebaseAuth.sendPasswordResetEmail(email);
      navigate("/auth/reset-password");
    } catch (error) {
      setErrorState({
        error: true,
        message: "Sorry, something went wrong. Please try again later.",
      });
    } finally {
      setLoadingState(LoadingState.IDLE);
    }
  };

  return (
    <>
      <div className={styles.header}>
        <h2>Forgot password</h2>
      </div>
      <main className={styles.main}>
        <span
          style={{ marginBottom: "15px" }}
          onClick={() => navigate("login")}
          className={styles.link}
        >
          Go back
        </span>
        <form className={styles.form}>
          <p style={{ marginBottom: "5px" }}>
            Enter the email address associated with your Elevat3d account so
            that we can send you a link to reset your password.
          </p>
          <CustomTextInput
            id="email-input"
            required={true}
            onChange={handleEmailChange}
            type="email"
            error={errorState.error}
            {...(errorState.error && {
              helperText: errorState.message,
            })}
            label="Email"
            autoComplete={true}
            fullWidth={true}
          />
          <div className={styles.footer}>
            <TextButton
              type="submit"
              externalClassName={styles.button}
              onClick={sendPasswordResetEmail}
              id="forgot-password"
              label="Send password reset email"
              isDisabled={loadingState === LoadingState.LOADING}
              isLoading={loadingState === LoadingState.LOADING}
            />
          </div>
        </form>
      </main>
    </>
  );
}
