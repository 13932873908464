import { ContextType } from "@data/enums/ContextType";
import { updateBlastDetails } from "@store/blast/actionCreators";
import {
  updateEditorSize,
  updateLeftBarOpen,
} from "@store/general/actionCreators";
import { resetLabels } from "@store/labels/actionCreators";
import { resetPreblastState } from "@store/preblast/actionCreators";
import classNames from "classnames";
import { LoadingState } from "data/enums/LoadingState";
import { ISize } from "interfaces/ISize";
import { ContextManager } from "logic/context/ContextManager";
import Elevat3dApi from "@api/elevat3dApi/Elevat3dApi";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Outlet, useParams } from "react-router-dom";
import { Settings } from "settings/Settings";
import { PlatformModel } from "staticModels/PlatformModel";
import LoaderOverlay from "views/LoaderOverlay/LoaderOverlay";
import MobileMainView from "views/MobileMainView/MobileMainView";
import NotificationsView from "views/NotificationsView/NotificationsView";
import PopupView from "views/PopupView/PopupView";
import { SizeItUpView } from "views/SizeItUpView/SizeItUpView";
import { PopupWindowType } from "../../data/enums/PopupWindowType";
import { AppState } from "../../store";
import styles from "./ProjectView.module.scss";
import LeftNavigationBar from "./LeftNavigationBar/LeftNavigationBar";
import TopNavigationBar from "./TopNavigationBar/TopNavigationBar";
import BlastsApi from "@api/elevat3dApi/BlastsApi";

interface IProps {
  activePopupType: PopupWindowType;
  windowSize: ISize;
  appLoadingState: LoadingState;
  activeContext: ContextType;
  updateEditorSize: (size: ISize) => void;
  updateLeftBarOpen: (isOpen: boolean) => void;
  updateBlastDetails: typeof updateBlastDetails;
  resetLabels: typeof resetLabels;
  leftBarOpen: boolean;
  resetPreblastState: typeof resetPreblastState;
}

const ProjectView: React.FC<IProps> = ({
  activePopupType,
  windowSize,
  appLoadingState,
  activeContext,
  updateEditorSize,
  leftBarOpen,
  updateBlastDetails,
  updateLeftBarOpen,
  resetLabels,
  resetPreblastState,
}) => {
  const { siteId, blastId } = useParams<{ siteId: string; blastId: string }>();

  useEffect(() => {
    resetLabels();
    resetPreblastState();
    (async () => await BlastsApi.viewProject(siteId, blastId))();
    updateBlastDetails({ siteId, blastId });
  }, []);
  const leftTabBorderDoubleClick = () => {
    if (!leftBarOpen) ContextManager.switchCtx(ContextType.LEFT_NAVBAR);
    else if (leftBarOpen && activeContext === ContextType.LEFT_NAVBAR)
      ContextManager.restoreCtx();

    updateLeftBarOpen(!leftBarOpen);
  };

  const getClassName = () => {
    return classNames(styles.EditorView, {
      [styles.withPopup]: !!activePopupType,
    });
  };

  const renderContent = () => {
    if (
      !!PlatformModel.mobileDeviceData.manufacturer &&
      !!PlatformModel.mobileDeviceData.os
    ) {
      return <MobileMainView />;
    }
    if (
      windowSize.height < Settings.EDITOR_MIN_HEIGHT ||
      windowSize.width < Settings.EDITOR_MIN_WIDTH
    ) {
      return <SizeItUpView />;
    } else {
      return (
        <>
          <div className={getClassName()} draggable={false}>
            <TopNavigationBar />
            <div className={styles.bottom}>
              <LeftNavigationBar
                isOpen={leftBarOpen}
                onBorderDoubleClick={leftTabBorderDoubleClick}
              />
              <Outlet />
            </div>
          </div>
          <PopupView />
          <NotificationsView />
          {appLoadingState === LoadingState.LOADING && <LoaderOverlay />}
        </>
      );
    }
  };

  return <>{renderContent()}</>;
};

const mapDispatchToProps = {
  updateEditorSize,
  updateLeftBarOpen,
  updateBlastDetails,
  resetLabels,
  resetPreblastState,
};

const mapStateToProps = (state: AppState) => ({
  activePopupType: state.general.activePopupType,
  windowSize: state.general.windowSize,
  appLoadingState: state.general.appLoadingState,
  activeContext: state.general.activeContext,
  leftBarOpen: state.general.leftBarOpen,
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectView);
