import { TextButton } from "@views/Common/TextButton/TextButton";
import styles from "./GenericPreblastPopup.module.scss";

type Props = {
  onAccept?: () => void;
  onReject?: () => void;
  acceptLabel?: string;
  isAcceptDisabled?: boolean;
  isRejectDisabled?: boolean;
  rejectLabel?: string;
  hideReject?: boolean;
  children: React.ReactNode;
  title?: string;
};

export default function GenericPreblastPopup({
  hideReject,
  onAccept,
  onReject,
  children,
  title,
  rejectLabel,
  acceptLabel,
  isAcceptDisabled,
  isRejectDisabled,
}: Props) {
  return (
    <div className={styles.popupContent}>
      {title && <div className={styles.heading}>{title}</div>}
      <div className={styles.main}>{children}</div>
      <div className={styles.footer}>
        {!hideReject && (
          <TextButton
            externalClassName={styles.TextButton}
            onClick={onReject}
            label={rejectLabel || "Cancel"}
            isDisabled={isRejectDisabled}
            buttonTheme="update"
          />
        )}
        <TextButton
          style={{ marginLeft: "20px"}}
          externalClassName={styles.TextButton}
          onClick={onAccept}
          label={acceptLabel || "Accept"}
          isDisabled={isAcceptDisabled}
          buttonTheme="update"
        />

      </div>
    </div>
  );
}
