import { auth } from "modules/firebaseModules";

export async function post(requestBody: any, url: string) {
  try {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(requestBody),
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        authorization: (await auth.currentUser.getIdToken(true)).toString(),
      },
    });
    if (!response.ok) {
      throw new Error(`Error! status: ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    if (error instanceof Error) {
      console.log("error message: ", error.message);
      throw error;
    } else {
      console.log("unexpected error: ", error);
      throw error;
    }
  }
}

export async function get(url: string) {
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        authorization: (await auth.currentUser.getIdToken(true)).toString(),
      },
    });
    if (!response.ok) {
      throw new Error(`Error! Status code ${response.status}`);
    }
    const result = await response.json();
    return result;
  } catch (error) {
    console.log(error);
    throw error;
  }
}
