import styles from "./ContextBar.module.scss";
import { Link } from "react-router-dom";
import { Fragment } from "react";

export type ActiveRoute = {
  name: string;
  href: string;
  shortName: string;
  clickable?: boolean;
}[];

interface IProps {
  activeRoute: ActiveRoute;
}

function ContextBar({ activeRoute }: IProps) {
  return (
    <div className={styles.ContextBar}>
      <div className={styles.Route}>
        {activeRoute.length > 0 ? (
          activeRoute.map((route, index, arr) => {
            const clickable =
              typeof route.clickable === "undefined" ? true : route.clickable;
            return (
              <Fragment key={route.href}>
                {clickable && index !== arr.length - 1 ? (
                  <Link
                    to={route.href}
                    className={`${styles.Link} ${styles.Clickable}`}
                  >
                    <p className={styles.Text}>{route.name}</p>
                  </Link>
                ) : (
                  <div className={`${styles.Link}`}>
                    <p className={styles.Text}>{route.name}</p>
                  </div>
                )}
                {index !== arr.length - 1 && (
                  <div className={styles.Separator}>/</div>
                )}
              </Fragment>
            );
          })
        ) : (
          <div> Loading...</div>
        )}
      </div>
    </div>
  );
}

export default ContextBar;
