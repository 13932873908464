import { store } from "../..";
import { PopupWindowType } from "../../data/enums/PopupWindowType";
import { ContextType } from "../../data/enums/ContextType";
import { CustomCursorStyle } from "../../data/enums/CustomCursorStyle";
import { CursorMode } from "@data/enums/CursorMode";

export class GeneralSelector {
  public static getActivePopupType(): PopupWindowType {
    return store.getState().general.activePopupType;
  }

  public static getActiveContext(): ContextType {
    return store.getState().general.activeContext;
  }

  public static getPreventCustomCursorStatus(): boolean {
    return store.getState().general.preventCustomCursor;
  }

  public static getCursorMode(): CursorMode {
    return store.getState().general.cursorMode;
  }

  public static getCustomCursorStyle(): CustomCursorStyle {
    return store.getState().general.customCursorStyle;
  }

  public static getZoom(): number {
    return store.getState().general.zoom;
  }

  public static getEnablePerClassColorationStatus(): boolean {
    return store.getState().general.enablePerClassColoration;
  }

}
