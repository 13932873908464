import React from "react";
import "./Loader.scss";
import CircularProgress from "@mui/material/CircularProgress";

const Loader: React.FC = () => {
  return (
    <div className="loader">
      <CircularProgress />
      <div className="text">Loading...</div>
    </div>
  );
};

export default Loader;
