import { auth } from ".";
import {
  signInWithEmailAndPassword,
  signOut,
  signInWithCustomToken,
  fetchSignInMethodsForEmail,
  sendPasswordResetEmail,
} from "firebase/auth";
import { store } from "index";

export default class FirebaseAuth {
  static async signIn(email: string, password: string) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  static async sendPasswordResetEmail(email: string) {
    return sendPasswordResetEmail(auth, email, {
      url: process.env.REACT_APP_BASE_URL + "/auth/password-reset-success",
    });
  }

  static async emailExists(email: string) {
    try {
      await fetchSignInMethodsForEmail(auth, email);
      return true;
    } catch (error) {
      return false;
    }
  }

  static async signOut() {
    await signOut(auth);
  }

  static async signInWithToken(token: string) {
    return signInWithCustomToken(auth, token);
  }
}
