import Elevat3dApi  from "api/elevat3dApi/Elevat3dApi";
import useSWR from "swr";
import { BlastWithoutSiteContext } from "data/Elevat3dApiTypes/BlastTypes";
import { Site } from "data/Elevat3dApiTypes/SiteTypes";

export function useRecentBlasts() {
  return useSWR<BlastWithoutSiteContext[]>(
    "/users/recent_blasts/",
    Elevat3dApi.protectedFetcher
  );
}

export function useFavouriteBlasts() {
  return useSWR<BlastWithoutSiteContext[]>(
    "/users/favourites/",
    Elevat3dApi.protectedFetcher
  );
}

export function useSites() {
  return useSWR<Site[]>("/sites/", Elevat3dApi.protectedFetcher);
}

export function useUser() {
  return useSWR("/users/me/", Elevat3dApi.protectedFetcher);
}
