import { store } from "../..";
import {
  IImageData,
  LabelLine,
  LabelName,
  LabelPoint,
  LabelPolygon,
  LabelRect,
} from "../labels/types";
import { find } from "lodash";
import { LabelType } from "../../data/enums/LabelType";

export class LabelsSelector {
  public static getLabelNames(): LabelName[] {
    return store.getState().labels.labels;
  }

  public static getLabelNameById(id: string): LabelName | undefined {
    const labelName: LabelName[] = LabelsSelector.getLabelNames();
    return find(labelName, { id });
  }

  public static getActiveLabelNameId(): string {
    return store.getState().labels.activeLabelNameId;
  }

  public static getActiveLabelType(): LabelType {
    return store.getState().labels.activeLabelType;
  }

  public static getImageFile(): File {
    return store.getState().labels.imageData.fileData;
  }
  
  public static getImageData(): IImageData {
    return store.getState().labels.imageData;
  }

  public static getActiveLabelId(): string | null {
    return store.getState().labels.activeLabelId;
  }

  public static getHighlightedLabelId(): string | null {
    return store.getState().labels.highlightedLabelId;
  }

  public static getActiveRectLabel(): LabelRect | null {
    const activeLabelId: string | null = LabelsSelector.getActiveLabelId();

    if (activeLabelId === null) return null;

    return find(LabelsSelector.getImageData().labelRects, {
      id: activeLabelId,
    });
  }

  public static getActivePointLabel(): LabelPoint | null {
    const activeLabelId: string | null = LabelsSelector.getActiveLabelId();

    if (activeLabelId === null) return null;

    return find(LabelsSelector.getImageData().labelPoints, {
      id: activeLabelId,
    });
  }

  public static getActivePolygonLabel(): LabelPolygon | null {
    const activeLabelId: string | null = LabelsSelector.getActiveLabelId();

    if (activeLabelId === null) return null;

    return find(LabelsSelector.getImageData().labelPolygons, {
      id: activeLabelId,
    });
  }

  public static getActiveLineLabel(): LabelLine | null {
    const activeLabelId: string | null = LabelsSelector.getActiveLabelId();

    if (activeLabelId === null) return null;

    return find(LabelsSelector.getImageData().labelLines, {
      id: activeLabelId,
    });
  }
}
