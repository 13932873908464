import { BlastDetails } from "@store/blast/types";
import { updateAppLoadingState } from "@store/general/actionCreators";
import { AppState } from "@store/index";
import { IImageData } from "@store/labels/types";
import {
  updateActivePopup,
  updateActivePreblastStep,
  updateChangedDrillMap,
  updateStepsState,
} from "@store/preblast/actionCreators";
import {
  PreblastPopups,
  PreblastSteps,
  StepsState as PreblastStepsState,
} from "@store/preblast/types";
import { connect } from "react-redux";
import styles from "./PreblastStepsComponent.module.scss";
import "./PreblastStepsComponent.scss";
import { Link, useParams } from "react-router-dom";
import { PreblastStepsData } from "@data/info/project/preblast/PreblastStepsData";
import CustomStepper from "@views/Common/CustomStepper/CustomStepper";
import { PreblastApi } from "@api/elevat3dApi/blast/PreblastApi";
import { LoadingState } from "@data/enums/LoadingState";
import { LabelsSelector } from "@store/selectors/LabelsSelector";

interface Props {
  imageData: IImageData;
  updateAppLoadingState: typeof updateAppLoadingState;
  blastDetails: BlastDetails;
  activePreblastStep: PreblastSteps;
  updateActivePreblastStep: typeof updateActivePreblastStep;
  updateActivePreblastPopup: typeof updateActivePopup;
  updateChangedDrillMap: typeof updateChangedDrillMap;
  preblastStepsState: PreblastStepsState;
  changedDrillMap: boolean;
}

function PreblastStepsComponent({
  imageData,
  activePreblastStep,
  updateActivePreblastStep,
  updateAppLoadingState,
  updateActivePreblastPopup,
  updateChangedDrillMap,
  preblastStepsState,
  changedDrillMap,
}: Props) {
  const { siteId, blastId } = useParams<{ siteId: string; blastId: string }>();

  const onStepClick = async (step: PreblastSteps) => {
    if (
      activePreblastStep === PreblastSteps.DRILLMAP &&
      step !== PreblastSteps.DRILLMAP &&
      changedDrillMap
    ) {
      console.log("hey there bitch");
      updateAppLoadingState(LoadingState.LOADING);
      await PreblastApi.uploadDrillMapImage({ siteId, blastId });
      await PreblastApi.uploadDrillMap(
        imageData.labelLines,
        LabelsSelector.getLabelNames(),
        { siteId, blastId }
      );
      updateAppLoadingState(LoadingState.IDLE);
      updateChangedDrillMap(false);
    }
    switch (step) {
      case PreblastSteps.UPLOAD_IMAGE: {
        updateActivePreblastStep(PreblastSteps.UPLOAD_IMAGE);
        break;
      }
      case PreblastSteps.HOLE_DETECTION: {
        if (imageData) {
          updateActivePreblastStep(PreblastSteps.HOLE_DETECTION);
        }

        break;
      }
      case PreblastSteps.SCALE_POINTS: {
        if (preblastStepsState[PreblastSteps.SCALE_POINTS].accessible) {
          updateActivePreblastStep(PreblastSteps.SCALE_POINTS);
        }
        break;
      }
      case PreblastSteps.BLAST_PARAMETERS: {
        if (preblastStepsState[PreblastSteps.BLAST_PARAMETERS].accessible) {
          updateActivePreblastStep(PreblastSteps.BLAST_PARAMETERS);
        }

        break;
      }
      case PreblastSteps.DRILLMAP: {
        if (preblastStepsState[PreblastSteps.DRILLMAP].accessible) {
          if (preblastStepsState[PreblastSteps.DRILLMAP].complete === false) {
            updateActivePreblastPopup(PreblastPopups.DRILLMAP);
          }
          updateActivePreblastStep(PreblastSteps.DRILLMAP);
        }
        break;
      }
      case PreblastSteps.SUMMARY: {
        if (preblastStepsState[PreblastSteps.SUMMARY].accessible) {
          updateActivePreblastStep(PreblastSteps.SUMMARY);
        }
        break;
      }
    }
  };

  return (
    <div className={styles.RightBarContent}>
      <CustomStepper<PreblastSteps>
        stepsData={PreblastStepsData}
        stepsState={preblastStepsState}
        activeStep={activePreblastStep}
        onStepClick={onStepClick}
      />
      {/* Uncomment below when blast analysis introduced */}
      {/* {preblastStepsState[PreblastSteps.SUMMARY].complete && (
        <div className={styles.nextSteps}>
          Move on to{" "}
          <Link to={`/editor/sites/${siteId}/blasts/${blastId}/during`}>
            blast analysis
          </Link>
        </div>
      )} */}
    </div>
  );
}

const mapDispatchToProps = {
  updateAppLoadingState,
  updateActivePreblastStep: updateActivePreblastStep,
  updatePreblastStepsState: updateStepsState,
  updateActivePreblastPopup: updateActivePopup,
  updateChangedDrillMap: updateChangedDrillMap,
};

const mapStateToProps = (state: AppState) => ({
  imageData: state.labels.imageData,
  blastDetails: state.blast.blastDetails,
  activePreblastStep: state.preblast.activeStep,
  preblastStepsState: state.preblast.stepsState,
  changedDrillMap: state.preblast.changedDrillMap,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreblastStepsComponent);
