
import { useNavigate, Outlet } from "react-router-dom";
import styles from "./AuthView.module.scss";
import Logo from "assets/images/elevated-drillmap-ico-transparent.png";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "modules/firebaseModules";
import Loader from "@views/Common/Loader/Loader";

function AuthView() {
  const [user, loading, authError] = useAuthState(auth);
  const navigate = useNavigate();

  if (loading) {
    return <Loader />;
  }
  if (user) {
    navigate("/recents");
  }

  return (
    <div className={styles.wrapper}>
      <img src={Logo} alt="logo" className={styles.logo} draggable={false} />

      <div className={styles.formWrapper}>
        <Outlet />
      </div>
    </div>
  );
}

export default AuthView;
