import { TextButton } from "@views/Common/TextButton/TextButton";
import styles from "./UploadImage.module.scss";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { updateImageData } from "@store/labels/actionCreators";
import { connect } from "react-redux";
import { ImageDataUtil } from "utils/ImageDataUtil";
import { AppState } from "@store/index";
import { IImageData } from "@store/labels/types";
import {
  updateActivePopup,
  updateActivePreblastStep,
  updatePreblastImage,
} from "@store/preblast/actionCreators";
import { PreblastPopups, PreblastSteps } from "@store/preblast/types";
import { updateAppLoadingState } from "@store/general/actionCreators";
import { LoadingState } from "@data/enums/LoadingState";
import { ImageUtil } from "utils/ImageUtil";
import ImageUploader from "@views/Common/ImageUploader/ImageUploader";
import { PreblastApi } from "@api/elevat3dApi/blast/PreblastApi";

type Props = {
  imageData: IImageData;
  updateImageData: typeof updateImageData;
  updateActivePreblastStep: typeof updateActivePreblastStep;
  updateActivePreblastPopup: typeof updateActivePopup;
  updateAppLoadingState: typeof updateAppLoadingState;
  updatePreblastImage: typeof updatePreblastImage;
  preblastImage: File | null;
};

function UploadImage({
  imageData,
  updateImageData,
  updateActivePreblastStep,
  updateActivePreblastPopup,
  updateAppLoadingState,
  updatePreblastImage,
  preblastImage,
}: Props) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { siteId, blastId } = useParams<{ siteId: string; blastId: string }>();

  useEffect(() => {
    if (imageData) {
      updatePreblastImage(imageData.fileData);
    }
  }, []);

  const handleFilesChange = async (files: FileList) => {
    if (files && files.length > 0) {
      updateAppLoadingState(LoadingState.LOADING);
      const compressedFile = await ImageUtil.fixHeight(files[0], 800);

      updateAppLoadingState(LoadingState.IDLE);
      updatePreblastImage(compressedFile as File);
    }
  };

  async function uploadFile() {
    try {
      updateAppLoadingState(LoadingState.LOADING);
      await PreblastApi.uploadPreblastImage(preblastImage, { siteId, blastId });
      updateImageData(ImageDataUtil.createImageDataFromFileData(preblastImage));
    } catch (error) {
      updateAppLoadingState(LoadingState.ERROR);
    } finally {
      updateAppLoadingState(LoadingState.IDLE);
    }
  }

  const onContinue = async () => {
    if (imageData) {
      if (!(imageData.fileData.size === preblastImage.size)) {
        // If an image was previously uploaded and a new one was selected, warn the user about progress being reset
        updateActivePreblastPopup(PreblastPopups.CHANGE_IMAGE_CONFIRMATION);
      } else {
        updateActivePreblastStep(PreblastSteps.HOLE_DETECTION);
      }
    } else {
      await uploadFile();
      updateActivePreblastStep(PreblastSteps.HOLE_DETECTION);
    }
  };

  return (
    <div className={styles.Content}>
      <div className={styles.DropzoneWrapper}>
        <ImageUploader
          openFolder={() => fileInputRef.current.click()}
          ref={fileInputRef}
          file={preblastImage}
          onFilesChange={handleFilesChange}
        />
        {preblastImage && (
          <div className={styles.Actions}>
            <div className={styles.Action}>
              <TextButton
                externalClassName={styles.Button}
                onClick={() => fileInputRef.current.click()}
                label="Change file"
                buttonTheme="update"
              />
            </div>
            <div className={styles.Action}>
              <TextButton
                externalClassName={styles.Button}
                label="Continue"
                onClick={onContinue}
                buttonTheme="update"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  updateImageData,
  updateActivePreblastStep: updateActivePreblastStep,
  updateActivePreblastPopup: updateActivePopup,
  updateAppLoadingState,
  updatePreblastImage,
};

const mapStateToProps = (state: AppState) => ({
  imageData: state.labels.imageData,
  preblastImage: state.preblast.preblastImage,
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadImage);
