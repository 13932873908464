import styles from "./AuthView.module.scss";
import { Link } from "react-router-dom";

export default function PasswordResetSuccess() {
  return (
    <>
      <div className={styles.header}>
        <h2>Password reset successful</h2>
      </div>
      <main className={styles.main}>
        <p>
          Your password was successfully reset. Click{" "}
          <Link to="/auth/login" className={styles.link}>
            here
          </Link>{" "}
          to login.
        </p>
      </main>
    </>
  );
}
