export enum DuringBlastActions {
  UPDATE_ACTIVE_STEP = "@@UPDATE_ACTIVE_STEP",
  UPDATE_VIDEO = "@@UPDATE_VIDEO",
  UPDATE_STEPS_STATE = "@@UPDATE_STEPS_STATE",
  UPDATE_VIDEO_THUMBNAIL = "@@UPDATE_VIDEO_THUMBNAIL",
}

export enum FaSteps {
  UPLOAD_VIDEO,
  ANALYSIS,
}

type StepsState = {
  complete: boolean;
  accessible: boolean;
};

export type FaStepsState = {
  [Key in FaSteps]: StepsState;
};

export type DuringBlastState = {
  videoData: { thumbnail: File; video: File };
  fumeAnalysis: {
    stepsState: FaStepsState;
    activeStep: FaSteps;
  };
};

interface UpdateStepsState {
  type: typeof DuringBlastActions.UPDATE_STEPS_STATE;
  payload: {
    stepsState: FaStepsState;
  };
}

interface UpdateActiveStep {
  type: typeof DuringBlastActions.UPDATE_ACTIVE_STEP;
  payload: {
    activeStep: FaSteps;
  };
}

interface UpdateVideoThumbnail {
  type: typeof DuringBlastActions.UPDATE_VIDEO_THUMBNAIL;
  payload: { thumbnail: File };
}
interface UpdateVideo {
  type: typeof DuringBlastActions.UPDATE_VIDEO;
  payload: {video: File}
}

export type DuringBlastActionTypes =
  | UpdateActiveStep
  | UpdateVideo
  | UpdateVideoThumbnail
  | UpdateStepsState;
