import { updateActivePopup } from "@store/preblast/actionCreators";
import GenericPreblastPopup from "../GenericPreblastPopup/GenericPreblastPopup";
import styles from "./ScalePointsPopup.module.scss";
import { connect } from "react-redux";

type Props = {
  updateActivePreblastPopup: typeof updateActivePopup;
};

function ScalePointsPopup({updateActivePreblastPopup}: Props) {
  const onAccept = () => {
    updateActivePreblastPopup(null);
  };
  return (
    <GenericPreblastPopup
      acceptLabel={"Ok"}
      onAccept={onAccept}
      hideReject={true}
      title="Scale Points"
    >
      <div>
        Add two points to the image and specify the distance between them to
        determine the scale of the image.
      </div>
    </GenericPreblastPopup>
  );
}

const mapDispatchToProps = {
  updateActivePreblastPopup: updateActivePopup,
};

export default connect(null, mapDispatchToProps)(ScalePointsPopup);
