import styles from "./LeftBar.module.scss";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import RecentsIcon from "@mui/icons-material/AccessTime";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useRecentBlasts } from "modules/hooksAndHOCs/swrApiHooks";

type Props = {
  isActive: boolean;
  onClick: () => void;
};
export default function RecentsTab({ isActive, onClick }: Props) {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const navigate = useNavigate();
  const { data } = useRecentBlasts();
  const recentBlasts = data || [];
  return (
    <div className={`${styles.ExpandableListWrapper}`}>
      <Link
        className={`${styles.Link} ${isActive ? styles.Active : ""}`}
        onClick={(e) => {
          // e.preventDefault();
          onClick();
        }}
        onDoubleClick={() => navigate("/recents")}
        to={"/recents"}
      >
        <div className={styles.ListHeader}>
          <RecentsIcon className={styles.Icon} />
          <h2 className={styles.Text}>Recents</h2>
          {/* <div
            onClick={(e) => {e.preventDefault(); setDropdownIsOpen(!dropdownIsOpen)}}
            className={`${styles.DropdownArrowWrapper} ${
              dropdownIsOpen ? styles.Open : ""
            }`}
          >
            <ArrowRightIcon className={`${styles.Icon}`} />
          </div> */}
        </div>
      </Link>

      <ul className={`${styles.List} ${dropdownIsOpen ? styles.Open : ""}`}>
        {recentBlasts.length > 0 ? (
          recentBlasts.map((blast) => {
            return (
              <li key={blast.blast_id}>
                <Link
                  className={`${styles.Link}`}
                  to={`/sites/${blast.site_id}/blasts/${blast.blast_id}`}
                >
                  <p>
                    <span className={styles.SpecialText}>
                      {blast.block_name}
                    </span>{" "}
                    <span className={styles.StandardText}>at</span>{" "}
                    <span className={styles.SpecialText}>
                      {blast.site_name}
                    </span>
                  </p>
                </Link>
              </li>
            );
          })
        ) : (
          <li>
            <p>No recent blasts</p>
          </li>
        )}
      </ul>
    </div>
  );
}
