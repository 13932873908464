import { INotification, NotificationsActionType } from "./types";
import { Actions } from "../Actions";

export function submitNewNotification(
  notification: INotification
): NotificationsActionType {
  return {
    type: Actions.SUBMIT_NEW_NOTIFICATION,
    payload: {
      notification,
    },
  };
}

export function deleteNotificationById(id: string): NotificationsActionType {
  return {
    type: Actions.DELETE_NOTIFICATION_BY_ID,
    payload: {
      id,
    },
  };
}
