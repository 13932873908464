import styles from "./AccountView.module.scss";
import { connect } from "react-redux";
import { useReducer } from "react";
import { Outlet } from "react-router-dom";
import AccountTopNavigationBar from "./TopNavigationBar/AccountTopNavigationBar";
import LeftBar from "./LeftBar/LeftBar";
import LoaderOverlay from "../LoaderOverlay/LoaderOverlay";
import { Snackbar, Alert } from "@mui/material";
import "./AccountView.scss";
import useSWR from "swr";
import {
  AccountViewContext,
  reducer,
  initialState,
  updateAlertState,
  updateLocalFavouriteBlasts,
  updateIsFavouritesLoading,
} from "./accountViewContext";
import { BlastWithoutSiteContext } from "data/Elevat3dApiTypes/BlastTypes";
import Elevat3dApi from "api/elevat3dApi/Elevat3dApi";

function AccountView() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { data, isLoading } = useSWR<BlastWithoutSiteContext[]>(
    "/users/favourites/",
    async (url) => {
      dispatch(updateIsFavouritesLoading(true));
      const favourites = await Elevat3dApi.get(url);
      dispatch(updateLocalFavouriteBlasts(favourites));
      dispatch(updateIsFavouritesLoading(false));
      return favourites;
    }
  );

  return (
    <AccountViewContext.Provider value={{ state, dispatch }}>
      <div className={`AccountViewWrapper ${styles.Wrapper}`}>
        <AccountTopNavigationBar />
        <div className={styles.MainWrapper}>
          <LeftBar className={styles.LeftBar} />
          <div className={styles.Right}>
            <Outlet />
          </div>
        </div>
      </div>

      {state.showLoaderOverlay && <LoaderOverlay />}
      <Snackbar
        open={state.alertState.open}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        onClose={() =>
          dispatch(updateAlertState({ ...state.alertState, open: false }))
        }
      >
        <Alert severity={state.alertState.severity}>
          {state.alertState.message}
        </Alert>
      </Snackbar>
    </AccountViewContext.Provider>
  );
}

export default connect(null, null)(AccountView);
