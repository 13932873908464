import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, setPersistence, browserLocalPersistence } from "firebase/auth";
import { getStorage } from "firebase/storage";

export const firebaseConfig = {
  apiKey: "AIzaSyB8oqGGRWADgTnMFR9x1UMq2dTUTUjfH8o",
  authDomain: "elevat3d-proj.firebaseapp.com",
  projectId: "elevat3d-proj",
  storageBucket: "elevat3d-proj.appspot.com",
  messagingSenderId: "757931753210",
  appId: "1:757931753210:web:15f2fc9b031aafa9e49343",
  measurementId: "G-VKDE6427MJ",
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const storage = getStorage(app);

setPersistence(auth, browserLocalPersistence);

export default app;
