import CustomDialog from "views/Common/CustomDialog/CustomDialog";
import CustomTextInput from "views/Common/CustomTextInput/CustomTextInput";
import { useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import styles from "./AddBlastDialog.module.scss";
import dialogStyles from "views/Common/CustomDialog/CustomDialog.module.scss";
import { useParams } from "react-router-dom";
import DateInput from "views/Common/DateInput/DateInput";
import {
  useAccountViewDispatch,
  updateShowLoaderOverlay,
} from "../../accountViewContext";
import BlastsApi from "@api/elevat3dApi/BlastsApi";
import { Blast } from "data/Elevat3dApiTypes/BlastTypes";

type Props = {
  open: boolean;
  onClose: () => void;
  onAddBlast: (newBlastId: string) => void;
};

export default function AddBlastDialog({ open, onClose, onAddBlast }: Props) {
  const { siteId } = useParams<{ siteId: string }>();
  const [error, setError] = useState(false);
  const dispatch = useAccountViewDispatch();
  const [blastState, setBlastState] = useState({
    blockName: "",
    blastDate: new Date().toISOString(),
    error: false,
  });

  const handleAddBlast = async () => {
    setError(false);
    if (blastState.blockName === "") {
      setBlastState({
        ...blastState,
        error: true,
      });
      return;
    }
    dispatch(updateShowLoaderOverlay(true));
    try {
      const newBlast = (await BlastsApi.createBlast({
        siteId,
        blockName: blastState.blockName,
        blastDate: blastState.blastDate,
      })) as Blast;

      onAddBlast(newBlast.blast_id);
      onClose();
    } catch (error) {
      setError(true);
    } finally {
      dispatch(updateShowLoaderOverlay(false));
    }
  };
  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      acceptLabel="Add blast"
      rejectLabel="Cancel"
      onAccept={handleAddBlast}
      onReject={onClose}
      title="New blast"
      Icon={AddIcon}
    >
      <div>
        <p className={styles.Text}>
          Enter the block name and blast date to create a new blast.
        </p>
        <div className={styles.InputsWrapper}>
          <CustomTextInput
            className={styles.Input}
            onChange={(e) =>
              setBlastState({
                ...blastState,
                blockName: e.target.value,
                error: false,
              })
            }
            error={blastState.error}
            helperText={blastState.error ? "Block name is required" : ""}
            id="block-name"
            label="Block name"
            required={true}
          />
          <div className={styles.DateInputWrapper}>
            <DateInput
              id="blast-date"
              className={styles.DateInput}
              value={blastState.blastDate}
              label="Blast date"
              onChange={(date: string) =>
                setBlastState({ ...blastState, blastDate: date })
              }
            />
          </div>
        </div>
        {error && (
          <p className={dialogStyles.ErrorText}>
            Sorry, something went wrong while trying to add a new site. Please
            try again later.
          </p>
        )}
      </div>
    </CustomDialog>
  );
}
