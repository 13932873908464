import React from "react";
import "./EditorBottomNavigationBar.scss";
import { IImageData } from "../../../store/labels/types";
import { AppState } from "../../../store";
import { connect } from "react-redux";
import { ContextType } from "../../../data/enums/ContextType";
import classNames from "classnames";
import { TextField, Tooltip } from "@mui/material";
import {
  updateImageData,
  updateActiveLabelId,
} from "../../../store/labels/actionCreators";
import RegExpressions from "../../../utils/RegExpressions";
import { PreblastSteps, Scale } from "@store/preblast/types";
import { updateScale } from "@store/preblast/actionCreators";

interface IProps {
  imageData: IImageData;
  totalImageCount: number;
  activeContext: ContextType;
  updateActiveLabelId: (activeLabelId: string | null) => any;
  updateImageData: ( newImageData: IImageData) => any;
  preblastActiveStep: PreblastSteps;
  updateScaleState: typeof updateScale;
  scaleState: Scale;
}

const EditorBottomNavigationBar: React.FC<IProps> = ({
  imageData,
  activeContext,
  updateImageData,
  updateActiveLabelId,
  preblastActiveStep,
  scaleState,
  updateScaleState,
}) => {
  const getClassName = () => {
    return classNames("EditorBottomNavigationBar");
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!RegExpressions.floatingPoint.test(event.target.value)) return;
    let scaleDistanceCm = parseFloat(event.target.value);

    if (isNaN(scaleDistanceCm)) {
      scaleDistanceCm = 0;
    }
    updateScaleState({
      ...scaleState,
      scaleDistanceCm: scaleDistanceCm,
    });
  };

  const onScaleInputClick = () => {
    /* Set the currently active scale point to be inactive to prevent the user from
      mistakenly deleting the last added scale point when deleting text from the scale input.*/
    updateActiveLabelId(null);
  };

  return (
    <div className={getClassName()}>
      <div className="ImageNavigationContainer">
        <div className="CurrentImageName">
          {" "}
          {imageData && imageData.fileData.name}{" "}
        </div>
      </div>
      {preblastActiveStep === PreblastSteps.SCALE_POINTS && (
        <div className="ScaleInputWrapper">
          <div className="Text">Scale:</div>
          <Tooltip title="Enter the distance between the 2 scale points">
            <TextField
              onClick={onScaleInputClick}
              variant="outlined"
              size="small"
              value={scaleState.scaleDistanceCm?.toString() || ""}
              onChange={onChange}
              InputProps={{
                className: "ScaleInput",
                endAdornment: "cm",
              }}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = {
  updateImageData,
  updateActiveLabelId,
  updateScaleState: updateScale,
};

const mapStateToProps = (state: AppState) => ({
  windowSize: state.general.windowSize,
  imagesData: state.labels.imageData,
  activeContext: state.general.activeContext,
  preblastActiveStep: state.preblast.activeStep,
  scaleState: state.preblast.scale,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorBottomNavigationBar);
