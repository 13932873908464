import styles from "./CustomDialog.module.scss";
import { Dialog } from "@mui/material";
import { TextButton } from "../TextButton/TextButton";
import ClearIcon from "@mui/icons-material/Clear";

interface IProps {
  open: boolean;
  onClose: () => void;
  acceptLabel?: string;
  rejectLabel?: string;
  hasReject?: boolean;
  onAccept?: () => void;
  onReject?: () => void;
  title?: string;
  children: JSX.Element;
  Icon?: any;
}

export default function CustomDialog({
  open,
  onClose,
  onAccept,
  onReject,
  acceptLabel = "Yes",
  rejectLabel = "Cancel",
  hasReject = true,
  children,
  title,
  Icon,
}: IProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        className: styles.CustomDialog,
        style: { borderRadius: "0" },
      }}
    >
      <div className={styles.Header}>
        {Icon && <Icon className={styles.Icon} />}
        {title}
        <div onClick={onClose} className={styles.ClearIcon}>
          <ClearIcon />
        </div>
      </div>
      <div className={styles.Content}>{children}</div>
      <div className={styles.Footer}>
        <TextButton
          externalClassName={styles.TextButton}
          label={acceptLabel}
          onClick={onAccept}
        />
        {hasReject && (
          <TextButton
            externalClassName={styles.TextButton}
            label={rejectLabel}
            onClick={onReject}
          />
        )}
      </div>
    </Dialog>
  );
}
