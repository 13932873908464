import { BlastPattern } from "@data/enums/BlastPattern";
import { LabelLine, LabelName, LabelRect } from "@store/labels/types";
import { Scale } from "@store/preblast/types";
import { post } from "api/requests";
import {
  DrillMapSerializer,
  IPreblastDocumentResponse,
  IDrillmapResponse,
} from "logic/import/DrillMapSerializer";
import Elevat3dApi from "../Elevat3dApi";
import { IImageData } from "@store/labels/types";
import { get } from "../../requests";
import PreblastStorageImageUtil from "@utils/FirebaseStorageUtil/PreblastImageStorageUtil";
import { EditorModel } from "staticModels/EditorModel";
import PreblastImageStorageUtil from "@utils/FirebaseStorageUtil/PreblastImageStorageUtil";

export class PreblastApi extends Elevat3dApi {
  public static GetDrillMapResponse(response: any, imageData: IImageData) {
    return DrillMapSerializer.deserializeMapResponse(response, imageData);
  }

  public static GetHoleMapResponse(response: any, fileData: File) {
    return DrillMapSerializer.deserializeHoleResponse(response, fileData);
  }

  public static async invokeGetPreblastDocument(params: {
    siteId: string;
    blastId: string;
    file: File;
  }) {
    /* Get the pre-blast document for a blast, given it's siteId and blastId. Returns nothing 
      if no pre-blast document, blast, or site don't exist. */
    const url = `${Elevat3dApi.apiRoot}/api/v1/sites/${params.siteId}/blasts/${params.blastId}/pre/`;
    const preblastDocument = await get(url)
      .then((response: IPreblastDocumentResponse) => {
        const deserializedPreblastDocument =
          DrillMapSerializer.deserializePreblastDocumentResponse(
            response,
            params.file
          );
        const labels = DrillMapSerializer.getLabelsFromPreblastDocument(
          deserializedPreblastDocument
        );
        return { deserializedPreblastDocument, labels };
      })
      .catch((err) => {
        console.log("error");
        throw err;
      });

    return preblastDocument;
  }

  public static async InvokeDrillMap(
    request: any,
    imageData: IImageData,
    siteId: string,
    blastId: string
  ) {
    const url = `${Elevat3dApi.apiRoot}/api/v1/sites/${siteId}/blasts/${blastId}/pre/graph/`;
    return await post(request, url).then((response: IDrillmapResponse) =>
      PreblastApi.GetDrillMapResponse(response, imageData)
    );
  }

  public static async InvokeGetHoles(
    request: any,
    fileData: File,
    siteId: string,
    blastId: string
  ) {
    const url = `/sites/${siteId}/blasts/${blastId}/pre/detection/?image_guid=1`;
    const response = await this.post(url, request)
      .then((response: any) =>
        PreblastApi.GetHoleMapResponse(response, fileData)
      )
      .catch((error) => {
        console.log(error);
        const err = error as Error;
        console.log(err.stack);
        throw err;
      });

    return response;
  }

  public static async saveBoundingBoxes(
    siteId: string,
    blastId: string,
    rectLabels: LabelRect[]
  ) {
    const requestBody =
      DrillMapSerializer.createSaveBoundingBoxesRequest(rectLabels);

    const url = `/sites/${siteId}/blasts/${blastId}/pre/bounding_boxes/`;
    return await this.put(url, requestBody);
  }

  public static async createSite(siteName: string, location: string = "N/A") {
    const requestBody = { site_name: siteName, location: location };
    const url = `/sites/`;
    return await this.post(url, requestBody);
  }

  public static async saveBlastParameters(
    blastDetails: { siteId: string; blastId: string },
    blastParameters: {
      spacing: number;
      burden: number;
      blastPattern: BlastPattern;
    }
  ) {
    const requestBody = {
      spacing: blastParameters.spacing,
      burden: blastParameters.burden,
      blast_pattern: blastParameters.blastPattern,
    };
    const url = `/sites/${blastDetails.siteId}/blasts/${blastDetails.blastId}/pre/blast_parameters/`;
    return await this.put(url, requestBody);
  }

  public static async saveScalePoints(
    blastDetails: { siteId: string; blastId: string },
    scale: Scale
  ) {
    const requestBody = DrillMapSerializer.createSaveScaleRequest(scale);
    const url = `/sites/${blastDetails.siteId}/blasts/${blastDetails.blastId}/pre/scale/`;
    return await this.put(url, requestBody);
  }

  public static async getPreblastReport(blastDetails: {
    siteId: string;
    blastId: string;
  }) {
    const url = `/sites/${blastDetails.siteId}/blasts/${blastDetails.blastId}/pre/preblast_report/`;
    return await this.get(url);
  }

  public static async createPreblastReport(blastDetails: {
    siteId: string;
    blastId: string;
  }) {
    const url = `/sites/${blastDetails.siteId}/blasts/${blastDetails.blastId}/pre/preblast_report/`;
    return await this.post(url);
  }

  public static async getDrillMap(siteId: string, blastId: string) {
    const url = `/sites/${siteId}/blasts/${blastId}/pre/drillmap/`;
    return await this.get(url);
  }

  public static async getPreblastSummary(blastDetails: {
    blastId: string;
    siteId: string;
  }) {
    const url = `/sites/${blastDetails.siteId}/blasts/${blastDetails.blastId}/pre/summary/`;
    return await this.get(url);
  }

  /**
   * Uploads the preblast iamge to blob storage for further analysis.
   * Deletes all progress made on preblast in the database.
   * @param file
   * @param blastDetails
   */
  public static async uploadPreblastImage(
    file: File,
    blastDetails: { siteId: string; blastId: string }
  ) {
    const { siteId, blastId } = blastDetails;
    try {
      // first delete previous progress.
      await this.delete(`/sites/${siteId}/blasts/${blastId}/pre/`);
      await this.put(`/sites/${siteId}/blasts/${blastId}/pre/image_details/`, {
        image_name: file.name,
      });
      await PreblastStorageImageUtil.uploadPlainImage(file, {
        siteId,
        blastId,
      });
    } catch (error) {
      throw error;
    }
  }

  public static async uploadDrillMapImage(
    blastDetails: { blastId: string; siteId: string },
    onComplete?: () => void
  ) {
    await new Promise((resolve) => setTimeout(resolve, 200));
    EditorModel.canvas.toBlob(async (blob) => {
      try {
        const drillmapImage = new File([blob], "drillmap.png", {
          type: "image/png",
        });
        await PreblastImageStorageUtil.uploadPreblastDrillmap(drillmapImage, {
          blastId: blastDetails.blastId,
          siteId: blastDetails.siteId,
        });
      } catch (error) {
      } finally {
        typeof onComplete !== "undefined" && onComplete();
      }
    }, "image/png");
  }

  public static async uploadDrillMap(
    labelLines: LabelLine[],
    labelNames: LabelName[],
    blastDetails: { blastId: string; siteId: string }
  ) {
    const { blastId, siteId } = blastDetails;
    const requestBody = DrillMapSerializer.createSaveDrillMapRequest(
      labelLines,
      labelNames
    );
    return await this.put(
      `/sites/${siteId}/blasts/${blastId}/pre/drillmap`,
      requestBody
    );
  }
}
