import React from "react";
import "./ExitProjectPopup.scss";
import { GenericYesNoPopup } from "../GenericYesNoPopup/GenericYesNoPopup";
import { PopupActions } from "../../../logic/actions/PopupActions";
import { useNavigate, useParams } from "react-router-dom";

export default function ExitProjectPopup() {
  const { siteId, blastId } = useParams();

  const navigate = useNavigate();

  const renderContent = () => {
    return (
      <div className="ExitProjectPopupContent">
        <div className="Message">
          Are you sure you want to leave the editor? Any unsaved changes will be lost.
        </div>
      </div>
    );
  };

  const onAccept = () => {
    PopupActions.close();
    navigate(`/sites/${siteId}/blasts/${blastId}`);
  };

  const onReject = () => {
    PopupActions.close();
  };

  return (
    <GenericYesNoPopup
      title={"Exit project"}
      renderContent={renderContent}
      acceptLabel={"Exit"}
      onAccept={onAccept}
      rejectLabel={"Back"}
      onReject={onReject}
    />
  );
}
