import { LabelsSelector } from "../../store/selectors/LabelsSelector";
import {
  IImageData,
  LabelLine,
  LabelName,
  LabelPoint,
  LabelPolygon,
  LabelRect,
} from "../../store/labels/types";
import { filter } from "lodash";
import { store } from "../../index";
import { updateImageData } from "../../store/labels/actionCreators";
import { LabelType } from "../../data/enums/LabelType";
import {
  updateChangedDrillMap,
  updateChangedHoles,
} from "@store/preblast/actionCreators";
import { EditorContext } from "@logic/context/EditorContext";

export class LabelActions {
  public static deleteActiveLabel() {
    const activeImageData: IImageData = LabelsSelector.getImageData();
    const activeLabelId: string = LabelsSelector.getActiveLabelId();
    LabelActions.deleteImageLabelByID(activeImageData, activeLabelId);
  }

  public static updateImageDataWithHistory(
    prevImageData: IImageData,
    newImageData: IImageData
  ) {
    EditorContext.historyStack.push(prevImageData);
    store.dispatch(updateImageData(newImageData));
  }

  public static undo() {
    // TODO: implement undo

    const prevImageData = EditorContext.historyStack.pop();
    const activeImage = LabelsSelector.getImageData();
    if (prevImageData) {
      // store.dispatch(updateImageData());
    }
  }

  public static deleteImageLabelByID(imageData: IImageData, labelId: string) {
    switch (LabelsSelector.getActiveLabelType()) {
      case LabelType.POINT:
        LabelActions.deletePointLabelByID(imageData, labelId);
        break;
      case LabelType.LINE:
        store.dispatch(updateChangedDrillMap(true));
        LabelActions.deleteLineLabelByID(imageData, labelId);
        break;
      case LabelType.RECT:
        store.dispatch(updateChangedHoles(true));
        LabelActions.deleteRectLabelByID(imageData, labelId);
        break;
      case LabelType.POLYGON:
        LabelActions.deletePolygonLabelByID(imageData, labelId);
        break;
    }
  }

  public static deleteRectLabelByID(
    imageData: IImageData,
    labelRectId: string
  ) {
    const newImageData = {
      ...imageData,
      labelRects: filter(imageData.labelRects, (currentLabel: LabelRect) => {
        return currentLabel.id !== labelRectId;
      }),
    };
    LabelActions.updateImageDataWithHistory(newImageData, newImageData);
  }

  public static deletePointLabelByID(
    imageData: IImageData,
    labelPointId: string
  ) {
    const newImageData = {
      ...imageData,
      labelPoints: filter(imageData.labelPoints, (currentLabel: LabelPoint) => {
        return currentLabel.id !== labelPointId;
      }),
    };
    LabelActions.updateImageDataWithHistory(newImageData, newImageData);
  }

  public static deleteLineLabelByID(
    imageData: IImageData,
    labelLineId: string
  ) {
    const newImageData = {
      ...imageData,
      labelLines: filter(imageData.labelLines, (currentLabel: LabelLine) => {
        return currentLabel.id !== labelLineId;
      }),
    };
    LabelActions.updateImageDataWithHistory(newImageData, newImageData);
  }

  public static deletePolygonLabelByID(
    imageData: IImageData,
    labelPolygonId: string
  ) {
    const newImageData = {
      ...imageData,
      labelPolygons: filter(
        imageData.labelPolygons,
        (currentLabel: LabelPolygon) => {
          return currentLabel.id !== labelPolygonId;
        }
      ),
    };
    LabelActions.updateImageDataWithHistory(newImageData, newImageData);
  }

  public static labelExistsInLabelNames(label: string): boolean {
    const labelNames: LabelName[] = LabelsSelector.getLabelNames();
    return labelNames
      .map((labelName: LabelName) => labelName.name)
      .includes(label);
  }
}
