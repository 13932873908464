import { DuringBlastActionTypes, DuringBlastState, FaSteps } from "./types";
import { DuringBlastActions } from "./types";

export const initialState: DuringBlastState = {
  videoData: {
    thumbnail: null,
    video: null,
  },
  fumeAnalysis: {
    stepsState: {
      [FaSteps.UPLOAD_VIDEO]: {
        complete: false,
        accessible: true,
      },
      [FaSteps.ANALYSIS]: {
        complete: false,
        accessible: false,
      },
    },
    activeStep: FaSteps.UPLOAD_VIDEO,
  },
};

export function duringBlastReducer(
  state = initialState,
  action: DuringBlastActionTypes
): DuringBlastState {
  switch (action.type) {
    case DuringBlastActions.UPDATE_ACTIVE_STEP: {
      return {
        ...state,
        fumeAnalysis: {
          ...state.fumeAnalysis,
          activeStep: action.payload.activeStep,
        },
      };
    }
    case DuringBlastActions.UPDATE_STEPS_STATE: {
      return {
        ...state,
        fumeAnalysis: {
          ...state.fumeAnalysis,
          stepsState: action.payload.stepsState,
        },
      };
    }
    case DuringBlastActions.UPDATE_VIDEO: {
      return {
        ...state,
        videoData: {
          ...state.videoData,
          video: action.payload.video,
        },
      };
    }
    case DuringBlastActions.UPDATE_VIDEO_THUMBNAIL: {
      return {
        ...state,
        videoData: {
          ...state.videoData,
          thumbnail: action.payload.thumbnail,
        },
      };
    }

    default: {
      return state;
    }
  }
}
