import { IDeserializedPreblastDocument } from "logic/import/DrillMapSerializer";
import {
  AlertState,
  BlastParameters,
  PreblastActionTypes,
  PreblastPopups,
  PreblastSteps,
  StepsState,
  PreblastActions,
  Scale,
} from "./types";

export function updateBlastParameters(
  blastParameters: BlastParameters
): PreblastActionTypes {
  return {
    type: PreblastActions.UPDATE_BLAST_PARAMETERS,
    payload: {
      blastParameters,
    },
  };
}

export function updateActivePreblastStep(
  activeStep: PreblastSteps
): PreblastActionTypes {
  return {
    type: PreblastActions.UPDATE_ACTIVE_STEP,
    payload: { activeStep },
  };
}

export function updateActivePopup(
  activePopup: PreblastPopups | null
): PreblastActionTypes {
  return {
    type: PreblastActions.UPDATE_ACTIVE_POPUP,
    payload: { activePopup },
  };
}

export function updateStepsState(stepsState: StepsState): PreblastActionTypes {
  return {
    type: PreblastActions.UPDATE_STEPS_STATE,
    payload: { stepsState },
  };
}

export function updateAlertState(alertState: AlertState): PreblastActionTypes {
  return {
    type: PreblastActions.UPDATE_ALERT_STATE,
    payload: { alertState },
  };
}

export function updateScale(scale: Scale): PreblastActionTypes {
  return {
    type: PreblastActions.UPDATE_SCALE,
    payload: { scale },
  };
}

export function updatePreblastDocument(
  preblastDocument: IDeserializedPreblastDocument | null
): PreblastActionTypes {
  return {
    type: PreblastActions.UPDATE_PREBLAST_DOCUMENT,
    payload: { preblastDocument },
  };
}

export function updatePreblastImage(
  preblastImage: File | null
): PreblastActionTypes {
  return {
    type: PreblastActions.UPDATE_PREBLAST_IMAGE,
    payload: { preblastImage },
  };
}

export function updateChangedHoles(changedHoles: boolean): PreblastActionTypes {
  return {
    type: PreblastActions.UPDATE_CHANGED_HOLES,
    payload: { changedHoles },
  };
}

export function updateChangedDrillMap(
  changedDrillMap: boolean
): PreblastActionTypes {
  return {
    type: PreblastActions.UPDATE_CHANGED_DRILLMAP,
    payload: { changedDrillMap },
  };
}

export function resetPreblastState(): PreblastActionTypes {
  return {
    type: PreblastActions.RESET,
  };
}
