import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { AppInitializer } from "./logic/initializer/AppInitializer";
import { initializeStore } from "store";
import { createRoot } from "react-dom/client";

export const store = initializeStore();

AppInitializer.init();
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

serviceWorker.unregister();
