import { ISize } from "@interfaces/ISize";
import {
  IImageData,
  LabelLine,
  LabelName,
  LabelPoint,
  LabelRect,
} from "@store/labels/types";
import { LabelUtil } from "@utils/LabelUtil";
import { v4 as uuidv4 } from "uuid";
import { BlastParameters, Scale } from "@store/preblast/types";
import { BlastPattern } from "@data/enums/BlastPattern";
import { LabelStatus } from "@data/enums/LabelStatus";

const scaleDownFactor = 1;

export interface Node {
  type: string;
  value: {
    x: number;
    y: number;
  };
}

export interface IDrillmapEdge {
  source: { x: number; y: number };
  target: { x: number; y: number };
  directed: boolean;
  metadata: {
    color: string;
    direction: "vertical" | "horizontal";
    distance_m: number;
    error: number;
    target: number;
    weight: number;
  };
}

export interface IDrillmapResponse {
  image_id: string;
  nodes: Node[];
  edges: IDrillmapEdge[];
}

export interface PreblastSummary {
  average_burden: number;
  average_spacing: number;
  planned_burden: number;
  planned_spacing: number;
  drillmap_area_m2: number;
  drilling_accuracy: number;
  holes_gt_20_percent: number;
  holes_lt_5_percent: number;
  num_holes_drilled: number;
}

export interface IDeserializedPreblastDocument {
  bounding_boxes?: {
    id: string;
    height: number;
    width: number;
    x: number;
    y: number;
    isCreatedByAI: boolean;
  }[];
  image_data: IImageData;
  image_details?: {
    image_name: string;
    image_id: string;
  };
  scale?: {
    source: {
      x: number;
      y: number;
    };
    target: {
      x: number;
      y: number;
    };
    distance_m: number;
  };
  blast_parameters?: {
    spacing: number;
    burden: number;
    blast_pattern: BlastPattern;
  };
  summary: PreblastSummary;
  report_name?: string;
  drillmap?: {
    image_id: string;
    image_width: number;
    image_height: number;
    image_rotation: number;
    user_id: string;
    drill_graph: {
      nodes: Node[];
      edges: IDrillmapEdge[];
    };
  };
}

export interface IPreblastDocumentResponse {
  bounding_boxes?:
    | {
        id: string;
        height: number;
        width: number;
        x: number;
        y: number;
        isCreatedByAI: boolean;
      }[]
    | null;
  image_details?: {
    image_name: string;
    image_id: string;
  } | null;
  scale?: {
    source: {
      x: number;
      y: number;
    };
    target: {
      x: number;
      y: number;
    };
    distance_m: number;
  };
  blast_parameters?: {
    spacing: number;
    burden: number;
    blast_pattern: BlastPattern;
  };
  report_name?: string;
  summary?: PreblastSummary;
  holes?: {
    image_id: string;
    image_width: number;
    image_height: number;
    image_rotation: number;
    user_id: string;
    drill_graph: {
      nodes: Node[];
      edges: IDrillmapEdge[];
    };
  };
}

export class DrillMapSerializer {
  public static createDrillMapRequest(params: {
    img: IImageData;
    imageSize: ISize;
    blastParams?: BlastParameters;
    scaleSource?: LabelPoint;
    scaleTarget?: LabelPoint;
    scaleDistanceCm?: number;
  }) {
    const {
      imageSize,
      img,
      blastParams,
      scaleSource,
      scaleTarget,
      scaleDistanceCm,
    } = params;
    return {
      img_id: "1",
      original_width: imageSize.width,
      original_height: imageSize.height,
      image_rotation: 0,
      bbox_list: img.labelRects.map((r) => {
        return {
          id: r.id,
          description: "hole",
          x: r.rect.x,
          y: r.rect.y,
          width: r.rect.width,
          height: r.rect.height,
          rotation: 0,
          rectanglelabel: "Hole",
        };
      }),
      ...(!!blastParams && {
        blast_params: {
          spacing: blastParams.spacing,
          burden: blastParams.burden,
          blast_pattern: blastParams.blastPattern,
        },
      }),
      ...(!!scaleSource &&
        !!scaleTarget && {
          scale: {
            source: {
              x: Math.ceil(scaleSource.point.x),
              y: Math.ceil(scaleSource.point.y),
            },
            target: {
              x: Math.ceil(scaleTarget.point.x),
              y: Math.ceil(scaleTarget.point.y),
            },
            distance_m: scaleDistanceCm / 100.0,
          },
        }),
    };
  }

  public static createSaveDrillMapRequest(
    labelLines: LabelLine[],
    labelNames: LabelName[]
  ) {
    const nodes = [];
    const edges = [];

    // Convert LabelLines to GraphNodes and GraphEdges
    for (const labelLine of labelLines) {
      const labelName = labelNames.find(
        (labelName) => labelName.id === labelLine.labelId
      );
      const graphNode = {
        type: "Hole",
        value: {
          x: labelLine.line.start.x,
          y: labelLine.line.start.y,
        },
      };
      nodes.push(graphNode);

      const graphEdge = {
        source: {
          x: labelLine.line.start.x,
          y: labelLine.line.start.y,
        },
        target: {
          x: labelLine.line.end.x,
          y: labelLine.line.end.y,
        },
        directed: false,
        metadata: {
          error: labelLine.metadata.error,
          direction: "vertical",
          color: labelName.color,
          distance_m: labelLine.metadata.distanceMetres,
          target: labelLine.metadata.target,
          weight: labelLine.metadata.distanceMetres,
        },
      };
      edges.push(graphEdge);
    }

    // Create the GraphOut object
    const graph = {
      nodes,
      edges,
    };

    return graph;
  }

  public static createSaveBoundingBoxesRequest(rectLabels: LabelRect[]) {
    return {
      bounding_boxes_list: rectLabels.map((r) => {
        return {
          x: r.rect.x,
          y: r.rect.y,
          width: r.rect.width,
          height: r.rect.height,
          isCreatedByAI: r.isCreatedByAI,
        };
      }),
    };
  }

  public static createSaveScaleRequest(scale: Scale) {
    const { source: scaleSource, target: scaleTarget, scaleDistanceCm } = scale;
    return {
      source: {
        x: Math.ceil(scaleSource.point.x),
        y: Math.ceil(scaleSource.point.y),
      },
      target: {
        x: Math.ceil(scaleTarget.point.x),
        y: Math.ceil(scaleTarget.point.y),
      },
      distance_m: scaleDistanceCm / 100.0,
    };
  }

  public static deserializePreblastDocumentResponse(
    response: IPreblastDocumentResponse,
    file: File
  ): IDeserializedPreblastDocument {
    const imageDetails = response.image_details;
    let labelRects: LabelRect[] = [];

    const boundingBoxes = response.bounding_boxes;
    if (boundingBoxes) {
      labelRects = boundingBoxes.map((bbox) => {
        const rect = {
          x: bbox.x,
          y: bbox.y,
          height: bbox.height,
          width: bbox.width,
        };
        return LabelUtil.createLabelRect(
          bbox.isCreatedByAI ? "ai-hole" : "",
          rect,
          bbox.isCreatedByAI
        );
      });
    }

    const blastParameters = response.blast_parameters;

    const scalePoints = response.scale;

    let labelPoints: LabelPoint[] = [];

    if (scalePoints) {
      labelPoints = [
        LabelUtil.createLabelPoint({
          id: "scale-source",
          point: {
            x: scalePoints.source.x,
            y: scalePoints.source.y,
          },
          status: LabelStatus.ACCEPTED,
        }),

        LabelUtil.createLabelPoint({
          id: "scale-target",
          point: {
            x: scalePoints.target.x,
            y: scalePoints.target.y,
          },
          status: LabelStatus.ACCEPTED,
        }),
      ];
    }

    const drillmapResponse = response.holes;

    let labelLines: LabelLine[] = [];

    if (drillmapResponse) {
      labelLines = this.getLabelLinesFromDrillmapResponse({
        nodes: drillmapResponse.drill_graph.nodes,
        edges: drillmapResponse.drill_graph.edges,
        image_id: drillmapResponse.image_id,
      });
    }

    const preblastSummary = response.summary;

    return {
      ...(blastParameters && { blast_parameters: blastParameters }),
      ...(scalePoints && { scale: scalePoints }),
      ...(boundingBoxes && { bounding_boxes: boundingBoxes }),
      ...(preblastSummary && { summary: preblastSummary }),
      image_details: imageDetails,
      image_data: {
        fileData: file,
        loadStatus: false,
        labelRects: labelRects,
        labelPoints: labelPoints,
        labelLines: labelLines,
        labelPolygons: [],
        labelNameIds: [],
        labelsForLines: [],
        scaleDistanceCentimeters: scalePoints?.distance_m / 100.0 || 0,
      },
      drillmap: drillmapResponse,
      ...(response.report_name && { report_name: response.report_name }),
    };
  }

  public static getLabelsFromPreblastDocument(
    preblastDocument: IDeserializedPreblastDocument
  ) {
    const green = "#2ded47";
    let labels: LabelName[] = [
      {
        id: "ai-hole",
        name: "ai-hole",
        color: green,
      },
    ];

    let labelsForLines: LabelName[] = [];
    if (preblastDocument.drillmap) {
      const drillmapResponse = preblastDocument.drillmap;

      labelsForLines = this.getLabelsForLinesFromDrillmap({
        edges: drillmapResponse.drill_graph.edges,
        nodes: drillmapResponse.drill_graph.nodes,
        image_id: drillmapResponse.image_id,
      });
    }

    return [...labels, ...labelsForLines];
  }

  public static deserializeHoleResponse(
    response: any,
    fileData: File
  ): IImageData {
    const labelRects = response.Holes.map((h) => {
      const rect = {
        x: h.x,
        y: h.y,
        height: h.height,
        width: h.width,
      };
      return LabelUtil.createLabelRect("ai-hole", rect, true);
    });

    return {
      fileData,
      loadStatus: false,
      labelRects: labelRects,
      labelPoints: [],
      labelLines: [],
      labelPolygons: [],
      labelNameIds: ["ai-hole"],
      labelsForLines: [],
      scaleDistanceCentimeters: 0,
    };
  }

  public static getLabelIdForLineFromDrillmapEdge(edge: IDrillmapEdge): string {
    return (
      edge.source.x +
      "," +
      edge.source.y +
      "-" +
      edge.target.x +
      "," +
      edge.target.y
    );
  }

  public static getLabelLinesFromDrillmapResponse(response: IDrillmapResponse) {
    const labelLines = response.edges.map((e) => {
      const line = LabelUtil.createLine(
        e.source.x / scaleDownFactor,
        e.source.y / scaleDownFactor,
        e.target.x / scaleDownFactor,
        e.target.y / scaleDownFactor
      );
      return LabelUtil.createLabelLine(
        this.getLabelIdForLineFromDrillmapEdge(e),
        line,
        {
          distanceMetres: e.metadata.distance_m,
          error: e.metadata.error,
          target: e.metadata.target,
          direction: e.metadata.direction,
        }
      );
    });
    return labelLines;
  }

  public static getLabelsForLinesFromDrillmap(
    response: IDrillmapResponse
  ): LabelName[] {
    return response.edges.map((e) => {
      return {
        id: e.source.x + "," + e.source.y + "-" + e.target.x + "," + e.target.y,
        name:
          "(" +
          e.source.x +
          "," +
          e.source.y +
          ") -> (" +
          e.target.x +
          "," +
          e.target.y +
          ")",
        color: e.metadata.color,
      };
    });
  }

  public static deserializeMapResponse(
    response: IDrillmapResponse,
    imageData: IImageData
  ): IImageData {
    const labelsForLines = this.getLabelsForLinesFromDrillmap(response);

    const labelLines = this.getLabelLinesFromDrillmapResponse(response);

    return {
      fileData: imageData.fileData,
      loadStatus: false,
      labelRects: imageData.labelRects,
      labelPoints: imageData.labelPoints,
      labelLines: labelLines,
      labelPolygons: [],
      labelNameIds: [
        ...imageData.labelNameIds,
        ...labelsForLines.map((l) => l.id),
      ],
      labelsForLines: labelsForLines,
      scaleDistanceCentimeters: 0,
    };
  }
}
