import { IImageData } from "../store/labels/types";

export class ImageDataUtil {
  public static createImageDataFromFileData(
    fileData: File,
  ): IImageData {
    return {
      fileData,
      loadStatus: false,
      labelRects: [],
      labelPoints: [],
      labelLines: [],
      labelPolygons: [],
      labelNameIds: [],
      labelsForLines: [],
      scaleDistanceCentimeters: 0,
    };
  }

  public static cleanAnnotations(item: IImageData): IImageData {
    return {
      ...item,
      labelRects: [],
      labelPoints: [],
      labelLines: [],
      labelPolygons: [],
      labelNameIds: [],
    };
  }
}
