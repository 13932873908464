import { get } from "../requests";
import Elevat3dApi  from "./Elevat3dApi";

export default class SitesApi extends Elevat3dApi {
  static async createSite(siteName: string, location?: string) {
    try {
      const response = await this.post("/sites/", {
        site_name: siteName,
        location,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
  static async getSites() {
    // doesn't require any parameters passed in because the user's JWT is decoded
    // on the backend to extract their user id and use it to get their corresponding sites.
    const url = `${this.apiRoot}/api/v1/sites/`;
    return await get(url);
  }

  static async deleteSite(siteId: string) {
    try {
      const response = await this.delete("/sites/" + siteId + "/");
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getSite(siteId: string) {
    try {
      const response = await this.post("/sites/", {
        site_id: siteId,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async updateSite(
    siteId: string,
    reuqestBody: { site_name: string; location: string }
  ) {
    try {
      return await this.patch(`/sites/${siteId}/`, reuqestBody);
    } catch (error) {
      throw error;
    }
  }
}
