import { store } from "../..";
import { BlastParameters, Scale } from "@store/preblast/types";

export class PreblastSelector {
  public static getBlastParameters(): BlastParameters {
    return store.getState().preblast.blastParameters;
  }
  public static getScaleState(): Scale {
    return store.getState().preblast.scale;
  }
}
