import { zip } from "lodash";

export type ImageMap = { [s: string]: HTMLImageElement };

export class ImageRepository {
  private static repository: ImageMap = {};

  public static storeImage(image: HTMLImageElement) {
    ImageRepository.repository["1"] = image;
  }

  public static get(uuid?: string): HTMLImageElement {
    //presume only one image with id = 1.
    return ImageRepository.repository["1"];
  }
}
