import { Snackbar, Alert, AlertColor } from "@mui/material";
import { Site } from "data/Elevat3dApiTypes/SiteTypes";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams, Link } from "react-router-dom";
import GenericTable, { Header, SortState } from "../GenericTable/GenericTable";
import tableStyles from "../GenericTable/GenericTable.module.scss";
import { convertTZ, formatDate } from "utils/DatetimeUtil";
import Cookies from "js-cookie";
import AddIcon from "@mui/icons-material/Add";
import Actions, { Action } from "../Actions/Actions";
import AddSiteDialog from "./AddSiteDialog";
import Elevat3dApi  from "api/elevat3dApi/Elevat3dApi";
import OutletLoadingScreen from "../OutletLoadingScreen/OutletLoadingScreen";
import ContextBar from "views/AccountView/ContextBar/ContextBar";
import useSWR from "swr";

// headers should contain site name, location, and date of creation
const headers: Header<Site>[] = [
  {
    name: "Site name",
    header: "site_name",
    sortable: true,
    sortDirection: "asc",
    sortFunction: (a: Site, b: Site) => {
      return a.site_name.localeCompare(b.site_name);
    },
  },
  {
    name: "Location",
    header: "location",
    sortable: true,
    sortDirection: "asc",
    sortFunction: (a: Site, b: Site) => {
      return a.location.localeCompare(b.location);
    },
  },
  {
    name: "Date created",
    header: "created_at_utc",
    sortable: true,
    sortDirection: "asc",
    sortFunction: (a: Site, b: Site) => {
      return (
        new Date(a.created_at_utc).getTime() -
        new Date(b.created_at_utc).getTime()
      );
    },
  },
];

export default function SitesListView() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [addSiteDialogOpen, setAddSiteDialogOpen] = useState(false);
  const [childKey, setChildKey] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [alertState, setAlertState] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor;
  }>({ open: false, message: "", severity: "success" });
  const [sortState, setSortState] = useState<SortState<Site>>({
    header: "created_at_utc",
    direction: "desc",
  });
  const navigate = useNavigate();
  const {
    data: sites,
    error,
    mutate,
  } = useSWR(
    "/sites/",
    async (url) => {
      setIsLoading(true);
      const data = await Elevat3dApi.get(url);
      setIsLoading(false);
      return data;
    },
    {
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    // If sites are cached (already loaded on mount), set loading to false
    if (sites) {
      setIsLoading(false);
    }
  }, [sites]);

  useEffect(() => {
    if (sites) {
      setIsLoading(false);
    }
    if (searchParams.get("refresh") === "true") {
      // refresh cached data for cases like when site is deleted, to ensure data is not stale.
      searchParams.delete("refresh");
      setSearchParams(searchParams);
      mutate();
    }
  }, []);

  const onAddSite = (newSiteId: string) => {
    setAlertState({
      open: true,
      message: "Site successfully created",
      severity: "success",
    });
    navigate("/sites/" + newSiteId + "/blasts");
  };

  const renderContent = () => {
    if (error) {
      return <div>There was an error</div>;
    }
    if (isLoading || !sites) {
      return <OutletLoadingScreen />;
    }
    return (
      <div>
        <ContextBar
          activeRoute={[{ name: "Sites", shortName: "Sites", href: "/sites" }]}
        />
        <Actions>
          <Action
            Icon={AddIcon}
            label="New site"
            onClick={() => setAddSiteDialogOpen(true)}
          />
        </Actions>
        <GenericTable<Site>
          headers={headers}
          sortState={sortState}
          setSortState={setSortState}
        >
          {sites
            .sort((a, b) => {
              return sortState.direction === "asc"
                ? headers
                    .find((header) => header.header === sortState.header)
                    .sortFunction(a, b)
                : headers
                    .find((header) => header.header === sortState.header)
                    .sortFunction(b, a);
            })
            .map((site: Site) => {
              const hasLocation = !!site.location;
              const location = hasLocation ? site.location : "No location";
              return (
                <Link
                  to={`/sites/${site.id}/blasts`}
                  className={`${tableStyles.Row}`}
                  style={{
                    gridTemplateColumns: `repeat(${headers.length}, 1fr)`,
                  }}
                  key={site.id}
                >
                  <div className={tableStyles.Cell}>
                    <p className={tableStyles.Text}>{site.site_name}</p>
                  </div>
                  <div
                    className={`${tableStyles.Cell} ${
                      !hasLocation ? tableStyles.Null : ""
                    }`}
                  >
                    <p className={tableStyles.Text}>{location}</p>
                  </div>
                  <div className={tableStyles.Cell}>
                    <p className={tableStyles.Text}>
                      {formatDate(
                        convertTZ(
                          new Date(site.created_at_utc),
                          Cookies.get("timezone")
                        )
                      )}
                    </p>
                  </div>
                </Link>
              );
            })}
        </GenericTable>
        <AddSiteDialog
          key={childKey}
          onAddSite={onAddSite}
          open={addSiteDialogOpen}
          onClose={() => {
            setChildKey(childKey + 1);
            setAddSiteDialogOpen(false);
          }}
        />
      </div>
    );
  };

  return (
    <>
      {renderContent()}
      <Snackbar
        open={alertState.open}
        onClose={() => setAlertState({ ...alertState, open: false })}
        autoHideDuration={4000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity={alertState.severity}>{alertState.message}</Alert>
      </Snackbar>
    </>
  );
}
