import React from "react";
import { connect } from "react-redux";
import { LabelType } from "@data/enums/LabelType";
import { LoadingState } from "@data/enums/LoadingState";
import { HoleImporter } from "@logic/import/HoleImporter";
import {
  updateAppLoadingState,
  updatePreventCustomCursorStatus,
} from "@store/general/actionCreators";

import {
  updateActiveLabelType,
  updateImageData,
  updateLabelNames,
} from "@store/labels/actionCreators";
import { IImageData, LabelName } from "@store/labels/types";
import styles from "./HoleDetectionPopup.module.scss";
import GenericPreblastPopup from "../GenericPreblastPopup/GenericPreblastPopup";
import { PreblastApi } from "@api/elevat3dApi/blast/PreblastApi";
import { AppState } from "@store/index";
import { BlastDetails } from "@store/blast/types";
import { updateActivePopup } from "@store/preblast/actionCreators";
import { PreblastPopups } from "@store/preblast/types";

interface IProps {
  updateImageData: (imageData: IImageData) => any;
  updateLabelNamesAction: (labels: LabelName[]) => any;
  updateActiveLabelTypeAction: (activeLabelType: LabelType) => any;
  updateAppLoadingState: typeof updateAppLoadingState;
  blastDetails: BlastDetails;
  updateActivePreblastPopup: typeof updateActivePopup;
  imageData: IImageData;
  updatePreventCustomCursorStatus: typeof updatePreventCustomCursorStatus;
}

const HoleDetectionPopup: React.FC<IProps> = ({
  updateImageData,
  updateLabelNamesAction,
  updateActiveLabelTypeAction,
  updateAppLoadingState,
  blastDetails,
  updateActivePreblastPopup,
  imageData,
  updatePreventCustomCursorStatus,
}) => {
  const onAnnotationLoadSuccess = async (
    imageData: IImageData,
    labelNames: LabelName[]
  ) => {
    updateImageData(imageData);
    updateLabelNamesAction(labelNames);

    updateActiveLabelTypeAction(LabelType.RECT);
    PreblastApi.saveBoundingBoxes(
      blastDetails.siteId,
      blastDetails.blastId,
      imageData.labelRects
    );
    updateAppLoadingState(LoadingState.IDLE);
    updatePreventCustomCursorStatus(false);
    updateActivePreblastPopup(PreblastPopups.HOLE_DETECTION_CONFIRMATION);
  };

  const onAnnotationsLoadFailure = () => {
    updateAppLoadingState(LoadingState.ERROR);
    updatePreventCustomCursorStatus(false);
    alert(
      "Error occured while processing request, please try again later or contact support."
    );
  };

  const onAccept = () => {
    const importer = new HoleImporter([LabelType.RECT]);
    updateActivePreblastPopup(null);
    updatePreventCustomCursorStatus(true);
    updateAppLoadingState(LoadingState.LOADING);
    importer.invoke(onAnnotationLoadSuccess, onAnnotationsLoadFailure);
  };

  const onReject = () => {
    updateActivePreblastPopup(null);
  };

  const renderText = () => {
    let text =
      "This will try to detect visible holes within the image and draw bounding boxes around them.";
    if (imageData && imageData.labelRects.length > 0) {
      text += " Note that this will overwrite existing bounding boxes.";
      if (imageData.labelLines.length > 0) {
        text += " The drill map will be removed as well.";
      }
    }

    return text;
  };

  return (
    <GenericPreblastPopup
      onAccept={onAccept}
      onReject={onReject}
      title="Detect holes"
    >
      <div className={styles.msg}>{renderText()}</div>
    </GenericPreblastPopup>
  );
};

const mapDispatchToProps = {
  updateImageData,
  updateLabelNamesAction: updateLabelNames,
  updateActiveLabelTypeAction: updateActiveLabelType,
  updateAppLoadingState,
  updateActivePreblastPopup: updateActivePopup,
  updatePreventCustomCursorStatus,
};

const mapStateToProps = (state: AppState) => ({
  blastDetails: state.blast.blastDetails,
  imageData: state.labels.imageData,
});

export default connect(mapStateToProps, mapDispatchToProps)(HoleDetectionPopup);
