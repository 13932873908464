import styles from "./AccountTopNavigationBar.module.scss";
import Logo from "assets/images/elevated-drillmap-ico-transparent.png";
import AccountIconContainer from "./Account/AccountIconContainer";

export default function AccountTopNavigationBar() {
  return (
    <div className={styles.TopNavigationBar}>
      <div className={styles.LogoWrapper}>
        <div className={styles.Logo}>
          <img
            src={"/elevated-drillmap-ico.png"}
            alt="Logo"
            draggable={false}
          />
        </div>
      </div>
      <div className={styles.Middle}></div>
      <AccountIconContainer />
    </div>
  );
}
