import { AppState } from "@store/index";
import styles from "./RightBar.module.scss";
import { updateRightBarOpen } from "@store/general/actionCreators";
import { connect } from "react-redux";
import { ContextManager } from "@logic/context/ContextManager";
import { ContextType } from "@data/enums/ContextType";

type Props = {
  isOpen: boolean;
  children?: React.ReactNode;
  updateRightBarOpen: typeof updateRightBarOpen;
  activeContext: ContextType;
};

function RightBar({
  isOpen,
  updateRightBarOpen,
  children,
  activeContext,
}: Props) {
  const handleBorderDoubleClick = () => {
    if (!isOpen) ContextManager.switchCtx(ContextType.RIGHT_NAVBAR);
    else if (isOpen && activeContext === ContextType.RIGHT_NAVBAR)
      ContextManager.restoreCtx();

    updateRightBarOpen(!isOpen);
  };
  
  return (
    <div className={styles.rightBar}>
      {!isOpen && <div className={styles.closedBar} />}
      <div
        className={styles.draggableBorder}
        onDoubleClick={handleBorderDoubleClick}
      />
      {isOpen && <div className={styles.contentWrapper}>{children}</div>}
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  isOpen: state.general.rightBarOpen,
  activeContext: state.general.activeContext,
});

const mapDispatchToProps = {
  updateRightBarOpen: updateRightBarOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(RightBar);
