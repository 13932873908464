import { AppState } from "@store/index";
import { PreblastSteps, PreblastPopups } from "@store/preblast/types";
import { connect } from "react-redux";
import DetectHolesIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import GraphIcon from "@mui/icons-material/Timeline";
import styles from "./ActiveAction.module.scss";
import { updateActivePopup } from "@store/preblast/actionCreators";

type Props = {
  activePreblastStep: PreblastSteps;
  updateActivePreblastPopup: typeof updateActivePopup;
};

function ActiveAction({
  activePreblastStep,
  updateActivePreblastPopup,
}: Props) {
  const renderContent = () => {
    switch (activePreblastStep) {
      case PreblastSteps.HOLE_DETECTION: {
        const onClick = () => {
          updateActivePreblastPopup(PreblastPopups.HOLE_DETECTION);
        };
        return (
          <div onClick={onClick} className={styles.button}>
            <DetectHolesIcon />
            <span>Detect holes</span>
          </div>
        );
      }
      case PreblastSteps.DRILLMAP: {
        const onClick = () => {
          updateActivePreblastPopup(PreblastPopups.DRILLMAP);
        };
        return (
          <div onClick={onClick} className={styles.button}>
            <GraphIcon />
            <span>Drill map</span>
          </div>
        );
      }
      default: {
        return null;
      }
    }
  };
  return <div>{renderContent()}</div>;
}

const mapDispatchToProps = {
  updateActivePreblastPopup: updateActivePopup,
};

const mapStateToProps = (state: AppState) => ({
  activePreblastStep: state.preblast.activeStep,
});

export default connect(mapStateToProps, mapDispatchToProps)(ActiveAction);
