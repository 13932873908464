import { AnnotationImporter } from "./AnnotationImporter";
import { IImageData, LabelName } from "../../store/labels/types";
import { LabelsSelector } from "../../store/selectors/LabelsSelector";
import { find } from "lodash";
import { ImageUtil } from "../../utils/ImageUtil";
import { EditorModel } from "../../staticModels/EditorModel";
import { ISize } from "../../interfaces/ISize";
import { DrillMapSerializer } from "./DrillMapSerializer";
import { PreblastSelector } from "@store/selectors/PreblastSelector";
import { BlastSelector } from "@store/selectors/BlastSelector";
import { PreblastApi } from "@api/elevat3dApi/blast/PreblastApi";

export type JSONFilesSpec = {
  labelNameFile: File;
  annotationFiles: File[];
};

export class DrillMapImporter extends AnnotationImporter {
  public invoke(
    onSuccess: (imagesData: IImageData, labelNames: LabelName[]) => any,
    onFailure: (error?: Error) => any
  ): void {
    const sourceImageData = LabelsSelector.getImageData();
    const drillMapRequest = this.createRequest(sourceImageData);
    const blastDetails = BlastSelector.getBlastDetails();
    const labels = LabelsSelector.getLabelNames();
    PreblastApi.InvokeDrillMap(
      drillMapRequest,
      sourceImageData,
      blastDetails.siteId,
      blastDetails.blastId
    )
      .then((newImageData) =>
        onSuccess(
          DrillMapImporter.injectNewImageData(sourceImageData, newImageData),
          [...newImageData.labelsForLines, ...labels]
        )
      )
      .catch((error) => {
        onFailure(error as Error);
        const err = error as Error;
        console.log(err.stack);
      });
  }

  private createRequest(sourceImageData: IImageData) {
    const imageSize: ISize = ImageUtil.getSize(EditorModel.image);
    let blastParams = PreblastSelector.getBlastParameters();
    const scaleState = PreblastSelector.getScaleState();
    let blastParametersIsValid = true;
    for (const val of Object.values(blastParams)) {
      if (val === null) {
        // if any one of the blast parameters wasn't entered, then revert to default.
        blastParametersIsValid = false;
        break;
      }
      if (val === 0) {
        blastParametersIsValid = false;
        break;
      }
    }

    const validScaleInput =
      scaleState.source !== null &&
      scaleState.target !== null &&
      scaleState.scaleDistanceCm > 0;

    return validScaleInput
      ? blastParametersIsValid
        ? DrillMapSerializer.createDrillMapRequest({
            img: sourceImageData,
            imageSize,
            blastParams,
            scaleSource: scaleState.source,
            scaleTarget: scaleState.target,
            scaleDistanceCm: scaleState.scaleDistanceCm,
          })
        : DrillMapSerializer.createDrillMapRequest({
            img: sourceImageData,
            imageSize,
            scaleSource: scaleState.source,
            scaleTarget: scaleState.target,
            scaleDistanceCm: scaleState.scaleDistanceCm,
          })
      : blastParametersIsValid
      ? DrillMapSerializer.createDrillMapRequest({
          img: sourceImageData,
          imageSize,
          blastParams,
        })
      : DrillMapSerializer.createDrillMapRequest({
          img: sourceImageData,
          imageSize,
        });
  }
  
  public static injectNewImageData(
    sourceImageData: IImageData,
    newImageData: IImageData
  ): IImageData {
    const rectsToAdd = newImageData.labelRects;
    const linesToAdd = newImageData.labelLines;
    const labelNameIdsToAdd = newImageData.labelNameIds;
    return {
      ...sourceImageData,
      labelRects: rectsToAdd,
      labelLines: linesToAdd,
      labelNameIds: labelNameIdsToAdd,
    };
  }
}
