/**
 * The generic table is used to wrap table content. The number of headers should be the same number of columns in the table.
 */

import React, { useState } from "react";
import styles from "./GenericTable.module.scss";
import { convertDateDeltaToString } from "utils/DatetimeUtil";
import UpArrow from "@mui/icons-material/North";

export interface Header<T> {
  name: string;
  header: keyof T;
  sortable: boolean;
  sortDirection: "asc" | "desc";
  sortFunction: (a: T, b: T) => number;
}
export interface SortState<T> {
  header: keyof T;
  direction: "asc" | "desc";
}

interface GenericTableProps<T> {
  headers: Header<T>[];
  sortState: SortState<T>;
  setSortState: (sortState: SortState<T>) => void;
  children: React.ReactNode;
}


export default function GenericTable<T>({
  headers,
  sortState,
  setSortState,
  children,
}: GenericTableProps<T>) {
  const handleSortStateChange = (header: keyof T) => {
    if (sortState.header === header) {

      setSortState({
        header,
        direction: sortState.direction === "asc" ? "desc" : "asc",
      });
    } else {
      setSortState({ header, direction: "asc" });
    }
  };

  return (
    <div className={styles.Table}>
      <div
        className={styles.Header}
        style={{ gridTemplateColumns: `repeat(${headers.length}, 1fr)` }}
      >
        {headers.map((header) => {
          return (
            <div
              onClick={() => handleSortStateChange(header.header)}
              className={styles.HeaderColumn}
              key={header.header as string}
            >
              <div className={styles.HeaderColumnInner}>
                <p>{header.name}</p>
                {header.sortable && (
                  <UpArrow
                    className={`${styles.SortIcon} ${
                      sortState.header === header.header ? styles.Active : ""
                    } ${
                      sortState.direction === "asc" ? styles.Asc : styles.Desc
                    }`}
                  />
                )}
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles.Body}>{children}</div>
    </div>
  );
}
