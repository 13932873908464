import { AnnotationImporter } from "./AnnotationImporter";
import { IImageData, LabelName } from "@store/labels/types";
import { LabelsSelector } from "@store/selectors/LabelsSelector";
import { find } from "lodash";
import { BlastSelector } from "@store/selectors/BlastSelector";
import { PreblastApi } from "@api/elevat3dApi/blast/PreblastApi";

export type JSONFilesSpec = {
  labelNameFile: File;
  annotationFiles: File[];
};

export class HoleImporter extends AnnotationImporter {
  public invoke(
    onSuccess: (imagesData: IImageData, labelNames: LabelName[]) => any,
    onFailure: (error?: Error) => any
  ): void {
    const sourceImageData = LabelsSelector.getImageData();
    const blastDetails = BlastSelector.getBlastDetails();
    const oldLabels = LabelsSelector.getLabelNames();
    const green = "#2ded47";
    const holeLabel = [
      {
        id: "ai-hole",
        name: "hole",
        color: green,
      },
    ];

    PreblastApi.InvokeGetHoles(
      null,
      sourceImageData.fileData,
      blastDetails.siteId,
      blastDetails.blastId
    )
      .then((newImageData) =>
        onSuccess(
          HoleImporter.injectNewImageData(sourceImageData, newImageData),
          [...holeLabel, ...oldLabels]
        )
      )
      .catch((error) => {
        onFailure(error as Error);
        const err = error as Error;
        console.log(err.stack);
      });
  }

  public static injectNewImageData(
    sourceImageData: IImageData,
    newImageData: IImageData
  ): IImageData {
    const rectsToAdd = newImageData.labelRects;
    const labelNameIdsToAdd = newImageData.labelNameIds;
    return {
      ...sourceImageData,
      labelRects: rectsToAdd,
      labelNameIds: [...sourceImageData.labelNameIds, ...labelNameIdsToAdd],
      labelLines: [], // remove the old drill map (if there was one)
    };
  }
}
