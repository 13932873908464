import { LoadingState } from "@data/enums/LoadingState";
import CustomTextInput from "@views/Common/CustomTextInput/CustomTextInput";
import { TextButton } from "@views/Common/TextButton/TextButton";
import FirebaseAuth from "modules/firebaseModules/auth";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./AuthView.module.scss";

export default function LoginForm() {
  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.IDLE
  );
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState<{
    email: string;
    password: string;
  }>({ email: "", password: "" });
  const [error, setError] = useState<{
    email: boolean;
    password: boolean;
  }>({
    email: false,
    password: false,
  });
  const [invalidCredentials, setInvalidCredentials] = useState(false);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        const button = document.getElementById("sign-in");
        if (button) {
          button.click();
        }
      }
    };
    document.addEventListener("keypress", handleKeyPress);
    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, []);
  const validateInputs = () => {
    let emailIsValid = true;
    let passwordIsValid = true;

    if (credentials.email === "") {
      emailIsValid = false;
    }
    if (credentials.password === "") {
      passwordIsValid = false;
    }

    setError({
      email: !emailIsValid,
      password: !passwordIsValid,
    });
    if (!(emailIsValid && passwordIsValid)) {
      throw new Error("One or more inputs incorrect");
    }
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInvalidCredentials(false);
    setCredentials({ ...credentials, email: event.target.value });
    setError({
      ...error,
      email: false,
    });
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInvalidCredentials(false);
    setCredentials({ ...credentials, password: event.target.value });
    setError({
      ...error,
      password: false,
    });
  };

  async function signIn(e) {
    e.preventDefault();
    try {
      validateInputs();
    } catch (error) {
      // invalid inputs
      return;
    }

    setLoadingState(LoadingState.LOADING);
    try {
      await FirebaseAuth.signIn(credentials.email, credentials.password);
      setLoadingState(LoadingState.IDLE);
      navigate("/recents");
    } catch (error) {
      setInvalidCredentials(true);
      setLoadingState(LoadingState.ERROR);
    }
  }
  return (
    <>
      <div className={styles.header}>
        <h2>Sign in</h2>
      </div>
      <main className={styles.main}>
        <form className={styles.form}>
          <CustomTextInput
            id="email-input"
            required={true}
            onChange={handleEmailChange}
            type="email"
            error={error.email}
            {...(error.email && {
              helperText: "Email is required",
            })}
            label="Email"
            autoComplete={true}
            fullWidth={true}
          />

          <CustomTextInput
            id="password-input"
            required={true}
            type="password"
            error={error.password}
            helperText={error.password ? "Password is required" : ""}
            onChange={handlePasswordChange}
            label="Password"
            fullWidth={true}
          />

          {invalidCredentials && (
            <p className={styles["invalid-credentials"]}>
              Invalid credentials. Please try again.
            </p>
          )}
          <p className={styles.HelperText}>
            <span
              onClick={() => navigate("/auth/forgot-password")}
              className={styles.link}
            >
              Forgot password
            </span>{" "}
          </p>
          <p className={styles.HelperText}>
            Don't have an account?{" "}
            <a
              href="https://www.elevat3d.com/"
              rel="noreferrer"
              className={styles.link}
            >
              Get in touch
            </a>{" "}
            us so we can sign you up.
          </p>
          <div className={styles.footer}>
            <TextButton
              type="submit"
              externalClassName={styles.button}
              onClick={signIn}
              id="sign-in"
              label="Sign in"
              isDisabled={loadingState === LoadingState.LOADING}
              isLoading={loadingState === LoadingState.LOADING}
            />
          </div>
        </form>
      </main>
    </>
  );
}
