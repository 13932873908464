import React from "react";
import styles from "./DrillmapPopup.module.scss";
import { LabelType } from "../../../../../data/enums/LabelType";
import { PopupActions } from "../../../../../logic/actions/PopupActions";
import {
  updateAppLoadingState,
  updatePreventCustomCursorStatus,
} from "../../../../../store/general/actionCreators";
import { connect } from "react-redux";
import { IImageData, LabelName } from "../../../../../store/labels/types";
import {
  updateActiveLabelType,
  updateImageData,
  updateLabelNames,
} from "../../../../../store/labels/actionCreators";
import { DrillMapImporter } from "../../../../../logic/import/DrillMapImporter";
import { GenericYesNoPopup } from "../../../../PopupView/GenericYesNoPopup/GenericYesNoPopup";
import { LoadingState } from "../../../../../data/enums/LoadingState";
import GenericPreblastPopup from "../GenericPreblastPopup/GenericPreblastPopup";
import {
  updateActivePopup,
  updateActivePreblastStep,
  updateChangedHoles,
  updateStepsState,
} from "@store/preblast/actionCreators";
import { AppState } from "@store/index";
import { PreblastSteps, StepsState } from "@store/preblast/types";
import { BlastDetails } from "@store/blast/types";
import { PreblastApi } from "@api/elevat3dApi/blast/PreblastApi";

interface IProps {
  updateImageData: (imageData: IImageData) => any;
  updateLabelNamesAction: (labels: LabelName[]) => any;
  updateActiveLabelTypeAction: (activeLabelType: LabelType) => any;
  updateAppLoadingState: typeof updateAppLoadingState;
  updateActivePreblastPopup: typeof updateActivePopup;
  updatePreblastStepsState: typeof updateStepsState;
  updateActivePreblastStep: typeof updateActivePreblastStep;
  updatePreventCustomCursor: typeof updatePreventCustomCursorStatus;
  updateChangedHoles: typeof updateChangedHoles;
  preblastStepsState: StepsState;
  blastDetails: BlastDetails;
  imageData: IImageData;
}

const DrillmapPopup: React.FC<IProps> = ({
  updateImageData,
  updateLabelNamesAction,
  updateActiveLabelTypeAction,
  updateAppLoadingState,
  updateActivePreblastPopup,
  updateActivePreblastStep,
  updateChangedHoles,
  blastDetails,
  imageData,
}) => {
  const onAnnotationLoadSuccess = (
    imagesData: IImageData,
    labelNames: LabelName[]
  ) => {
    updateImageData(imagesData);
    updateLabelNamesAction(labelNames);
    updateActiveLabelTypeAction(LabelType.LINE);
    updateActivePreblastPopup(null);
    (async () => {
      PreblastApi.uploadDrillMapImage(blastDetails, () =>
        updateAppLoadingState(LoadingState.IDLE)
      );
    })();
    updateChangedHoles(false);
  };

  const onAnnotationLoadFailure = () => {
    updateAppLoadingState(LoadingState.ERROR);
    alert(
      "Error occured while processing request, please try again later or contact support."
    );
  };

  const onAccept = () => {
    const importer = new DrillMapImporter([LabelType.RECT]);
    PopupActions.close();
    importer.invoke(onAnnotationLoadSuccess, onAnnotationLoadFailure);

    updateAppLoadingState(LoadingState.LOADING);
  };

  const onGoBack = () => {
    updateActivePreblastPopup(null);
  };

  const renderContent = () => {
    if (imageData.labelRects.length < 2) {
      return (
        <GenericPreblastPopup
          title="Construct drill map"
          onAccept={() =>
            updateActivePreblastStep(PreblastSteps.HOLE_DETECTION)
          }
          acceptLabel="Ok"
          hideReject={true}
        >
          <div className={styles.msgWrapper}>
            <div className={styles.msg}>
              There must be at least two bounding boxes to construct a drill
              map. Please go back to the hole detection step to add more
              bounding boxes.
            </div>
          </div>
        </GenericPreblastPopup>
      );
    } else {
      return (
        <GenericPreblastPopup
          title="Construct drill map"
          onAccept={onAccept}
          onReject={onGoBack}
          acceptLabel="Continue"
          rejectLabel="Cancel"
        >
          <div className={styles.msgWrapper}>
            <div className={styles.msg}>
              This will detect the most optimal drill map for blasting.
            </div>
          </div>
        </GenericPreblastPopup>
      );
    }
  };

  return <>{renderContent()}</>;
};

const mapDispatchToProps = {
  updateImageData,
  updateLabelNamesAction: updateLabelNames,
  updateActiveLabelTypeAction: updateActiveLabelType,
  updateAppLoadingState,
  updateActivePreblastPopup: updateActivePopup,
  updatePreblastStepsState: updateStepsState,
  updateActivePreblastStep: updateActivePreblastStep,
  updatePreventCustomCursor: updatePreventCustomCursorStatus,
  updateChangedHoles: updateChangedHoles,
};

const mapStateToProps = (state: AppState) => ({
  preblastStepsState: state.preblast.stepsState,
  blastDetails: state.blast.blastDetails,
  imageData: state.labels.imageData,
});

export default connect(mapStateToProps, mapDispatchToProps)(DrillmapPopup);
