import classNames from "classnames";
import Elevat3dApi from "@api/elevat3dApi/Elevat3dApi";
import { useState } from "react";
import { useParams, useLocation, Link } from "react-router-dom";
import useSWRImmutable from "swr/immutable";
import styles from "./LeftNavigationBarContent.module.scss";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

function BlastOverviewLeftNavigatioBarContent() {
  const { siteId, blastId } = useParams<{ siteId: string; blastId: string }>();

  const { data, isLoading } = useSWRImmutable(
    [`/sites/${siteId}/blasts/${blastId}/`, `/sites/${siteId}/`],
    async (urls) => {
      const data = await Promise.all(urls.map((url) => Elevat3dApi.get(url)));
      const [blast, site] = data;
      return { blast: blast, site: site };
    }
  );

  const location = useLocation();
  const [showBlastOverviewSubroutes, setShowBlastOverviewSubroutes] =
    useState(true);
  const [showBlastSubroutes, setShowBlastSubroutes] = useState(false);

  const activeRoute = location.pathname.split("/").slice(-1)[0];

  return (
    <>
      {isLoading || !data ? (
        <div>Loading...</div>
      ) : (
        <ul className={styles.list}>
          <li className={classNames(styles.listItem)}>
            <Link
              to={`/editor/sites/${siteId}/blasts/${blastId}`}
              className={classNames(styles.row, {
                [styles.expand]: showBlastOverviewSubroutes,
                [styles.active]: activeRoute === "/",
              })}
            >
              <span>
                {data.blast.block_name} on {data.blast.blast_date}
              </span>
              <div
                className={styles.expandButton}
                onClick={(e) => {
                  e.preventDefault();
                  setShowBlastOverviewSubroutes(!showBlastOverviewSubroutes);
                }}
              >
                <ArrowRightIcon className={styles.expandIcon} />
              </div>
            </Link>
            <ul
              className={classNames(styles.list, {
                [styles.hidden]: !showBlastOverviewSubroutes,
              })}
            >
              <li key="pre" className={styles.listItem}>
                <Link
                  className={classNames(styles.row, {
                    [styles.active]: activeRoute === "pre",
                  })}
                  to={`/editor/sites/${siteId}/blasts/${blastId}/pre`}
                >
                  Pre-blast
                </Link>
              </li>
              {/* <li key="blast" className={styles.listItem}>
                <Link
                  className={classNames(styles.row, {
                    [styles.expand]: showBlastSubroutes,
                    [styles.active]: activeRoute === "during",
                  })}
                  to={`/editor/sites/${siteId}/blasts/${blastId}/during`}
                >
                  <span>During blast</span>
                  <div
                    className={styles.expandButton}
                    onClick={(e) => {
                      e.preventDefault();
                      setShowBlastSubroutes(!showBlastSubroutes);
                    }}
                  >
                    <ArrowRightIcon className={styles.expandIcon} />
                  </div>
                </Link>
                <ul
                  className={classNames(styles.list, {
                    [styles.hidden]: !showBlastSubroutes,
                  })}
                >
                  <li className={styles.listItem}>
                    <Link
                      className={classNames(styles.row, {
                        [styles.active]: activeRoute === "fume-analysis",
                      })}
                      to={`/editor/sites/${siteId}/blasts/${blastId}/during/fume-analysis`}
                    >
                      Fume analysis
                    </Link>
                  </li>
                </ul>
              </li> */}
            </ul>
          </li>
        </ul>
      )}
    </>
  );
}

export default BlastOverviewLeftNavigatioBarContent;
