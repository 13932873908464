import useSWRImmutable from "swr/immutable";
import { useParams, Link } from "react-router-dom";
import styles from "./BlastPath.module.scss";
import Elevat3dApi from "@api/elevat3dApi/Elevat3dApi";
import { PopupWindowType } from "@data/enums/PopupWindowType";
import { updateActivePopupType } from "@store/general/actionCreators";
import { connect } from "react-redux";

type Props = {
  updateActivePopupType: (activePopupType: PopupWindowType) => any;
};

function BlastPath({ updateActivePopupType }: Props) {
  const { siteId, blastId } = useParams<{ siteId: string; blastId: string }>();

  const { data } = useSWRImmutable(
    [`/sites/${siteId}/blasts/${blastId}/`, `/sites/${siteId}/`],
    async (urls) => {
      const data = await Promise.all(urls.map((url) => Elevat3dApi.get(url)));
      const [blast, site] = data;
      return { blast: blast, site: site };
    }
  );

  const openExitPopup = () => {
    updateActivePopupType(PopupWindowType.EXIT_PROJECT);
  };

  return (
    <>
      {data ? (
        <div className={styles.path}>
          <Link
            to={`/sites/${siteId}`}
            onClick={(e) => {
              e.preventDefault();
              openExitPopup();
            }}
            className={`${styles.link} ${styles.grey}`}
          >
            {data.site.site_name}
          </Link>
          <span className={styles.divider}>/</span>
          <Link
            className={`${styles.link}`}
            to={`/editor/sites/${siteId}/blasts/${blastId}`}
          >
            {data.blast.block_name} on {data.blast.blast_date}
          </Link>
        </div>
      ) : (
        <span>Loading...</span>
      )}
    </>
  );
}

const mapDispatchToProps = {
  updateActivePopupType: updateActivePopupType,
};

export default connect(null, mapDispatchToProps)(BlastPath);
