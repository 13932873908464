import styles from "./BlastOverview.module.scss";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import PreblastImageStorageUtil, * as PreblastStorage from "@utils/FirebaseStorageUtil/PreblastImageStorageUtil";
import { updateAppLoadingState } from "@store/general/actionCreators";
import { connect } from "react-redux";
import { TextButton } from "@views/Common/TextButton/TextButton";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import Elevat3dApi from "api/elevat3dApi/Elevat3dApi";
import useSWRImmutable from "swr/immutable";
import { PreblastApi } from "@api/elevat3dApi/blast/PreblastApi";

function BlastOverview() {
  const { siteId, blastId } = useParams<{ siteId: string; blastId: string }>();

  const [loadingPreblastReport, setLoadingPreblastReport] = useState(false);
  const [exportingPreblastReport, setExportingPreblastReport] = useState(false);
  const [preblastSummary, setPreblastSummary] = useState(null);
  const navigate = useNavigate();

  const { data, isLoading } = useSWRImmutable(
    [`/sites/${siteId}/blasts/${blastId}/`, `/sites/${siteId}/`],
    async (urls) => {
      const data = await Promise.all(urls.map((url) => Elevat3dApi.get(url)));
      const [blast, site] = data;
      return { blast: blast, site: site };
    }
  );

  useEffect(() => {
    (async () => {
      setLoadingPreblastReport(true);

      try {
        const response = await PreblastApi.getPreblastSummary({
          siteId,
          blastId,
        });
        setPreblastSummary(response);
      } catch (error) {
      } finally {
        setLoadingPreblastReport(false);
      }
    })();
  }, []);

  const exportPreblastReport = async () => {
    setExportingPreblastReport(true);
    let preblastReportName: string = "";

    const response = await PreblastApi.getPreblastReport({
      siteId,
      blastId,
    });
    if (response.status === 204) {

      const preblastCreationResponse = await PreblastApi.createPreblastReport({
        siteId,
        blastId,
      });

    
      preblastReportName = preblastCreationResponse.report_url;
    } else {
      preblastReportName = response;
    }

    const preblastReport =
      await PreblastImageStorageUtil.downloadPreblastReport(
        preblastReportName,
        { siteId, blastId }
      );
    const url = window.URL.createObjectURL(preblastReport);
    const a = document.createElement("a");
    a.href = url;
    a.download = preblastReportName;
    document.body.appendChild(a);
    a.click();
    a.remove();
    setExportingPreblastReport(false);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.box}>
          {loadingPreblastReport || !data ? (
            <div>Loading...</div>
          ) : (
            <>
              <div className={styles.heading}>
                <h2>
                  {data.blast.block_name} on {data.blast.blast_date}
                </h2>
              </div>
              {preblastSummary ? (
                <section className={styles.section}>
                  <h3>Pre-blast Report</h3>
                  <p>Pre-blast report is ready for exporting</p>
                  <div>
                    <TextButton
                      isLoading={exportingPreblastReport}
                      externalClassName={styles.exportButton}
                      renderIcon={() => <FileUploadIcon />}
                      onClick={exportPreblastReport}
                      label="Export"
                      buttonTheme="update"
                    />
                  </div>
                </section>
              ) : (
                <p>
                  To get started, go to{" "}
                  <Link to={`/editor/sites/${siteId}/blasts/${blastId}/pre`}>
                    Pre-blast
                  </Link>{" "}
                  and follow the instructions in the right panel.
                </p>
              )}

              <section></section>
              <section></section>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
const mapDispatchToProps = {
  updateAppLoadingState: updateAppLoadingState,
};

export default connect(null, mapDispatchToProps)(BlastOverview);
