import { BlastDetails } from "@store/blast/types";
import { AppState } from "@store/index";
import { connect } from "react-redux";
import styles from "./PreblastSummary.module.scss";
import { updateAppLoadingState } from "@store/general/actionCreators";
import { downloadFile } from "utils/FirebaseStorageUtil/FirebaseStorageUtil";
import { IPreblastSummary } from "@data/Elevat3dApiTypes/SummaryTypes";
import { PreblastApi } from "@api/elevat3dApi/blast/PreblastApi";
import { useState, useEffect } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { TextButton } from "@views/Common/TextButton/TextButton";
import PreblastImageStorageUtil from "@utils/FirebaseStorageUtil/PreblastImageStorageUtil";

type Props = {
  blastDetails: BlastDetails;
  updateAppLoadingState: typeof updateAppLoadingState;
};

function PreblastSummary({ blastDetails, updateAppLoadingState }: Props) {
  const [image, setImage] = useState<File>(null);
  const [data, setData] = useState<IPreblastSummary>(null);
  const [loadingPreblastReport, setLoadingPreblastReport] = useState(false);

  useEffect(() => {
    (async () => {
      const response = await PreblastApi.getPreblastSummary(blastDetails);
      setData(response);
      const file = await downloadFile(
        `drillmap/${blastDetails.siteId}/${blastDetails.blastId}/drill_graph.png`
      );
      setImage(file);
    })();
  }, []);

  const getImageUrl = () => {
    if (image) {
      return URL.createObjectURL(image);
    }
    return null;
  };

  const isLoading = !data || !image;

  const exportPreblastReport = async () => {
    setLoadingPreblastReport(true);
    let preblastReportName: string = "";

    const response = await PreblastApi.getPreblastReport(blastDetails);
    try {
      if (response.status === 204) {
        const response = await PreblastApi.createPreblastReport(blastDetails);
        preblastReportName = response.report_url;
      } else {
        preblastReportName = response;
      }
    } catch (error) {
      console.log(error);
      setLoadingPreblastReport(false);
      return;
    }

    try {
      const preblastReport =
        await PreblastImageStorageUtil.downloadPreblastReport(
          preblastReportName,
          blastDetails
        );

      const url = window.URL.createObjectURL(preblastReport);
      const a = document.createElement("a");
      a.href = url;
      a.download = preblastReportName;
      document.body.appendChild(a);
      a.click();
      a.remove();

      setLoadingPreblastReport(false);
    } catch (error) {
      console.log(error);
      setLoadingPreblastReport(false);
    }
  };

  const renderContent = () => {
    if (!isLoading) {
      return (
        <>
          <div className={styles.dataWrapper}>
            <div className={styles.dataContainer}>
              <span className={styles.key}>Drilling area</span>
              <span className={styles.value}>
                {data.drillmap_area_m2.toFixed(2)}m<sup>2</sup>
              </span>
            </div>
            <div className={styles.dataContainer}>
              <span className={styles.key}>Holes drilled</span>
              <span className={styles.value}>{data.num_holes_drilled}</span>
            </div>
            <div className={styles.dataContainer}>
              <span className={styles.key}>Drilling accuracy</span>
              <span className={styles.value}>
                {(data.drilling_accuracy * 100).toFixed(2)}%
              </span>
            </div>
            <div className={styles.dataContainer}>
              <span className={styles.key}>Planned burden</span>
              <span className={styles.value}>
                {data.planned_burden.toFixed(2)}m
              </span>
            </div>
            <div className={styles.dataContainer}>
              <span className={styles.key}>Planned spacing</span>
              <span className={styles.value}>
                {data.planned_spacing.toFixed(2)}m
              </span>
            </div>
            <div className={styles.dataContainer}>
              <span className={styles.key}>Holes drilled within 5%</span>
              <span className={styles.value}>{data.holes_lt_5_percent}</span>
            </div>
            <div className={styles.dataContainer}>
              <span className={styles.key}>Holes with &gt;20% error</span>
              <span className={styles.value}>{data.holes_gt_20_percent}</span>
            </div>
            <div className={styles.dataContainer}>
              <span className={styles.key}>Average burden</span>
              <span className={styles.value}>
                {data.average_burden.toFixed(2)}m
              </span>
            </div>
            <div className={styles.dataContainer}>
              <span className={styles.key}>Average spacing</span>
              <span className={styles.value}>
                {data.average_spacing.toFixed(2)}m
              </span>
            </div>
          </div>
          <div className={styles.imageContainer}>
            <img src={getImageUrl()} alt="drill graph" className={styles.img} />
          </div>
        </>
      );
    }
    return <div>Loading...</div>;
  };

  return (
    <div className={styles.preblastSummary}>
      <div className={styles.box}>
        <div className={styles.heading}>
          <h3>Preblast summary</h3>
          {!isLoading && (
            <div className={styles.exportWrapper}>
              <TextButton
                isLoading={loadingPreblastReport}
                externalClassName={styles.exportButton}
                renderIcon={() => <FileUploadIcon />}
                onClick={exportPreblastReport}
                label="Export"
                buttonTheme="update"
              />
            </div>
          )}
        </div>
        {renderContent()}
      </div>
    </div>
  );
}

const mapDispatchToProps = {
  updateAppLoadingState,
};
const mapStateToProps = (state: AppState) => ({
  blastDetails: state.blast.blastDetails,
});

export default connect(mapStateToProps, mapDispatchToProps)(PreblastSummary);
