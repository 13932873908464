import styles from "./BlastOverview.module.scss";
import ContextBar from "views/AccountView/ContextBar/ContextBar";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import { useBlastViewContext } from "../BlastView";
import OutletLoadingScreen from "views/AccountView/OutletLoadingScreen/OutletLoadingScreen";
import Actions, { Action } from "views/AccountView/Actions/Actions";
import Cookies from "js-cookie";
import { formatDate, convertTZ } from "utils/DatetimeUtil";
import { TextButton } from "views/Common/TextButton/TextButton";

export default function BlastOverview() {
  const location = useLocation();
  const navigate = useNavigate();
  const { data, dataIsLoading, refreshData, setBlastDeletionDialogOpen } =
    useBlastViewContext();
  const { siteId, blastId } = useParams<{ siteId: string; blastId: string }>();

  const startProject = () => {
    navigate(`/editor/sites/${siteId}/blasts/${blastId}/`)
  };

  const renderContent = () => {
    if (!data || dataIsLoading) {
      return <OutletLoadingScreen />;
    }

    const { site, blast } = data;

    const hasLastViewed = blast.last_viewed !== null;
    const lastViewed = hasLastViewed
      ? formatDate(convertTZ(blast.last_viewed))
      : "Never";

    return (
      <div>
        <ContextBar
          activeRoute={[
            { name: "Sites", href: "/sites", shortName: "Sites" },
            {
              name: site.site_name,
              href: `/sites/${siteId}`,
              shortName: site.site_name,
            },
            {
              name: "Blasts",
              href: `/sites/${siteId}/blasts`,
              shortName: "Blasts",
            },
            {
              name: blast.block_name,
              href: location.pathname,
              shortName: blast.block_name,
            },
          ]}
        />
        <Actions>
          <Action onClick={startProject} label="Go to editor" />
          <Action
            onClick={() => navigate(location.pathname + "/settings")}
            label="Settings"
          />
        </Actions>
        <div className={styles.Content}>
          <div className={styles.InfoWrapper}>
            <dl className={styles.Info}>
              <dt className={styles.Label}>Block name</dt>
              <dd className={styles.Value}>{blast.block_name}</dd>
            </dl>
            <dl className={styles.Info}>
              <dt className={styles.Label}>Date of blast</dt>
              <dd className={`${styles.Value}`}>{blast.blast_date}</dd>
            </dl>
            <dl className={styles.Info}>
              <dt className={styles.Label}>Permissions</dt>
              <dd className={styles.Value}>
                {blast.permissions.charAt(0).toUpperCase() +
                  blast.permissions.slice(1)}
              </dd>
            </dl>
            <dl className={styles.Info}>
              <dt className={styles.Label}>Date created</dt>
              <dd className={styles.Value}>
                {formatDate(
                  convertTZ(site.created_at_utc, Cookies.get("timezone"))
                )}
              </dd>
            </dl>
            <dl className={styles.Info}>
              <dt className={styles.Label}>Blast ID</dt>
              <dd className={styles.Value}>{blast.blast_id}</dd>
            </dl>
            <dl className={styles.Info}>
              <dt className={styles.Label}>Last viewed</dt>
              <dd
                className={`${styles.Value} ${
                  !hasLastViewed ? styles.Null : ""
                }`}
              >
                {lastViewed}
              </dd>
            </dl>
          </div>
          <div className={styles.Divider} />
          <footer className={styles.Footer}>
            <TextButton
              onClick={() => setBlastDeletionDialogOpen(true)}
                buttonTheme="delete"
              label="Delete blast"
            />
          </footer>
        </div>
      </div>
    );
  };
  return <>{renderContent()}</>;
}
