import { BlastPattern } from "@data/enums/BlastPattern";
import {
  PreblastActionTypes,
  PreblastState,
  PreblastSteps,
  Scale,
} from "./types";
import { PreblastActions } from "./types";
import { LabelUtil } from "utils/LabelUtil";

export const initialState: PreblastState = {
  blastParameters: {
    burden: 0,
    spacing: 0,
    blastPattern: BlastPattern.STAGGERED,
  },
  activeStep: PreblastSteps.UPLOAD_IMAGE,
  prevStep: PreblastSteps.UPLOAD_IMAGE,
  activePopup: null,
  stepsState: {
    [PreblastSteps.UPLOAD_IMAGE]: {
      complete: false,
      accessible: true,
    },
    [PreblastSteps.HOLE_DETECTION]: {
      complete: false,
      accessible: false,
    },
    [PreblastSteps.SCALE_POINTS]: {
      complete: false,
      accessible: false,
    },
    [PreblastSteps.BLAST_PARAMETERS]: {
      complete: false,
      accessible: false,
    },
    [PreblastSteps.DRILLMAP]: {
      complete: false,
      accessible: false,
    },
    [PreblastSteps.SUMMARY]: {
      complete: false,
      accessible: false,
    },
  },
  alertState: {
    open: false,
    message: "",
    severity: "success",
  },
  scale: {
    source: null,
    target: null,
    scaleDistanceCm: 0,
  },
  preblastDocument: {},
  preblastImage: null,
  changedHoles: false,
  changedDrillMap: false
};

export function preblastReducer(
  state = initialState,
  action: PreblastActionTypes
): PreblastState {
  switch (action.type) {
    case PreblastActions.UPDATE_BLAST_PARAMETERS: {
      return {
        ...state,
        blastParameters: action.payload.blastParameters,
      };
    }
    case PreblastActions.UPDATE_ACTIVE_STEP: {
      return {
        ...state,
        activeStep: action.payload.activeStep,
        prevStep: state.activeStep,
      };
    }
    case PreblastActions.UPDATE_ACTIVE_POPUP: {
      return {
        ...state,
        activePopup: action.payload.activePopup,
      };
    }
    case PreblastActions.UPDATE_STEPS_STATE: {
      return {
        ...state,
        stepsState: action.payload.stepsState,
      };
    }
    case PreblastActions.UPDATE_ALERT_STATE: {
      return {
        ...state,
        alertState: action.payload.alertState,
      };
    }
    case PreblastActions.UPDATE_SCALE: {
      return {
        ...state,
        scale: action.payload.scale,
      };
    }
    case PreblastActions.UPDATE_PREBLAST_DOCUMENT: {
      const preblastDocument = action.payload.preblastDocument;

      let newScale: Scale = {
        source: null,
        target: null,
        scaleDistanceCm: 0,
      };

      if (preblastDocument.scale) {
        const scale = preblastDocument.scale;
        newScale.source = LabelUtil.createLabelPoint({
          id: "scale-source",
          point: {
            x: scale.source.x,
            y: scale.source.y,
          },
        });
        newScale.target = LabelUtil.createLabelPoint({
          id: "scale-target",
          point: {
            x: scale.target.x,
            y: scale.target.y,
          },
        });
        newScale.scaleDistanceCm = scale.distance_m * 100;
      }

      let newBlastParameters = { ...state.blastParameters };

      if (preblastDocument.blast_parameters) {
        newBlastParameters.burden = preblastDocument.blast_parameters.burden;
        newBlastParameters.spacing = preblastDocument.blast_parameters.spacing;
        newBlastParameters.blastPattern =
          preblastDocument.blast_parameters.blast_pattern;
      }

      return {
        ...state,
        scale: newScale,
        blastParameters: newBlastParameters,
        preblastDocument,
      };
    }
    case PreblastActions.UPDATE_PREBLAST_IMAGE: {
      return {
        ...state,
        preblastImage: action.payload.preblastImage,
      };
    }
    case PreblastActions.UPDATE_CHANGED_HOLES: {
      return {
        ...state,
        changedHoles: action.payload.changedHoles,
      };
    }
    case PreblastActions.UPDATE_CHANGED_DRILLMAP: {
      return {
        ...state,
        changedDrillMap: action.payload.changedDrillMap,
      };
    }

    case PreblastActions.RESET: {
      return initialState;
    }
    default: {
      return state;
    }
  }
}
