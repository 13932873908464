import { PreblastSteps } from "@store/preblast/types";
import { Step, StepsDataMap } from "../Step";

export const PreblastStepsData: StepsDataMap<PreblastSteps> = {
  [PreblastSteps.UPLOAD_IMAGE]: {
    label: "Upload image",
    description: "Upload the image that you want to analyze.",
  },
  [PreblastSteps.HOLE_DETECTION]: {
    label: "Detect drill holes",
    description:
      "Automatically detect drill holes in the image and draw bounding boxes around them. If there are any errors or you need to add bounding boxes, you can manually do so. When satisfied, press continue.",
  },
  [PreblastSteps.SCALE_POINTS]: {
    label: "Draw scale points",
    description:
      "Place two points on the image and enter the distance between them in the input in the bottom navigation bar to determine the scale of the image.",
  },
  [PreblastSteps.BLAST_PARAMETERS]: {
    label: "Enter blast parameters",
    description:
      "Enter the burden, spacing, and drill pattern of the blast which are needed to construct the drill map.",
  },
  [PreblastSteps.DRILLMAP]: {
    label: "Construct drillmap",
    description: "Construct a drillmap from the detected drill holes.",
  },
  [PreblastSteps.SUMMARY]: {
    label: "Summary",
    description: "Get summary of the preblast.",
  },
};
