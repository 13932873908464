import { LabelsActionTypes, IImageData, LabelName } from "./types";
import { Actions } from "../Actions";
import { LabelType } from "../../data/enums/LabelType";

export function updateActiveLabelNameId(
  activeLabelNameId: string
): LabelsActionTypes {
  return {
    type: Actions.UPDATE_ACTIVE_LABEL_NAME_ID,
    payload: {
      activeLabelNameId,
    },
  };
}

export function updateActiveLabelId(activeLabelId: string): LabelsActionTypes {
  return {
    type: Actions.UPDATE_ACTIVE_LABEL_ID,
    payload: {
      activeLabelId,
    },
  };
}

export function updateHighlightedLabelId(
  highlightedLabelId: string
): LabelsActionTypes {
  return {
    type: Actions.UPDATE_HIGHLIGHTED_LABEL_ID,
    payload: {
      highlightedLabelId,
    },
  };
}

export function updateActiveLabelType(
  activeLabelType: LabelType
): LabelsActionTypes {
  return {
    type: Actions.UPDATE_ACTIVE_LABEL_TYPE,
    payload: {
      activeLabelType,
    },
  };
}

export function updateImageData(newImageData: IImageData): LabelsActionTypes {
  return {
    type: Actions.UPDATE_IMAGE,
    payload: {
      newImageData,
    },
  };
}

export function updateLabelNames(labels: LabelName[]): LabelsActionTypes {
  return {
    type: Actions.UPDATE_LABEL_NAMES,
    payload: {
      labels,
    },
  };
}

export function updateFirstLabelCreatedFlag(
  firstLabelCreatedFlag: boolean
): LabelsActionTypes {
  return {
    type: Actions.UPDATE_FIRST_LABEL_CREATED_FLAG,
    payload: {
      firstLabelCreatedFlag,
    },
  };
}

export function resetLabels(): LabelsActionTypes {
  return {
    type: Actions.RESET_LABELS,
  };
}
