import styles from "./BlastView.module.scss";
import { useParams, Outlet, Link, useOutletContext } from "react-router-dom";
import { useState, useEffect } from "react";
import useSWR from "swr";
import { Blast } from "data/Elevat3dApiTypes/BlastTypes";
import { Site } from "data/Elevat3dApiTypes/SiteTypes";
import BlastDeletionConfirmationDialog from "./BlastDeletionConfirmationDialog";
import Elevat3dApi from "api/elevat3dApi/Elevat3dApi";

export default function BlastView() {
  const { blastId, siteId } = useParams<{ blastId: string; siteId: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [blastDeletionDialogOpen, setBlastDeletionDialogOpen] = useState(false);
  const [childKey, setChildKey] = useState(0);

  const { data, error, mutate } = useSWR(
    [`/sites/${siteId}/blasts/${blastId}/`, `/sites/${siteId}/`],
    async (urls) => {
      setIsLoading(true);
      const data = await Promise.all(urls.map((url) => Elevat3dApi.get(url)));
      const [blast, site] = data;
      setIsLoading(false);
      return { blast: blast, site: site };
    },
    { revalidateOnFocus: false, errorRetryCount: 5, errorRetryInterval: 1000 }
  );

  useEffect(() => {
    // if data is already cached, set isLoading to false
    if (data) {
      setIsLoading(false);
    }
  }, [data]);

  return (
    <div className={styles.Wrapper}>
      {error ? (
        <div>
          This blast doesn't exist. Press{" "}
          <Link to={`/sites/${siteId}/blasts`}>here</Link> to go back.
        </div>
      ) : (
        <Outlet
          context={{
            setBlastDeletionDialogOpen,
            data,
            refreshData: mutate,
            dataIsLoading: isLoading,
          }}
        />
      )}
      {data && data.blast && (
        <BlastDeletionConfirmationDialog
          key={childKey}
          open={blastDeletionDialogOpen}
          blast={data.blast}
          site={data.site}
          onClose={() => {
            setChildKey(childKey + 1);
            setBlastDeletionDialogOpen(false);
          }}
        />
      )}
    </div>
  );
}

type BlastViewContext = {
  data: { site: Site; blast: Blast };
  dataIsLoading: boolean;
  refreshData: () => void;
  setBlastDeletionDialogOpen: (open: boolean) => void;
};

export const useBlastViewContext = () => {
  const context = useOutletContext<BlastViewContext>();
  return context;
};
