import { connect } from "react-redux";
import { AppState } from "../../../store";
import { IImageData } from "../../../store/labels/types";
import styles from "./EditorContainer.module.scss";
import Editor from "../Editor/Editor";
import { ContextManager } from "../../../logic/context/ContextManager";
import { ContextType } from "../../../data/enums/ContextType";
import EditorBottomNavigationBar from "../EditorBottomNavigationBar/EditorBottomNavigationBar";
import EditorTopNavigationBar from "../EditorTopNavigationBar/EditorTopNavigationBar";
import classnames from "classnames";
import { PreblastPopups, PreblastSteps } from "@store/preblast/types";
import BlastParametersDialog from "../PreblastView/BlastParametersDialog/BlastParametersDialog";

interface IProps {
  imageData: IImageData;
  preblastActivePopup: PreblastPopups;
  activePreblastStep: PreblastSteps;
}

const EditorContainer: React.FC<IProps> = ({
  imageData,
  preblastActivePopup,
  activePreblastStep,
}) => {
  const getEditorWrapperClassname = () => {
    return classnames(styles.editorWrapper, {
      [styles.noClickthrough]:
        (preblastActivePopup ||
          activePreblastStep === PreblastSteps.BLAST_PARAMETERS) &&
        localStorage.getItem("no-show-preblast-help") !== "true",
    });
  };

  return (
    <div className={styles.editorContainer}>
      <div
        className={getEditorWrapperClassname()}
        onMouseDown={() => ContextManager.switchCtx(ContextType.EDITOR)}
        key="editor-wrapper"
      >
        <EditorTopNavigationBar key="editor-top-navigation-bar" />

        <Editor
          imageData={imageData ? imageData: null}
          key="editor"
        />
        <EditorBottomNavigationBar
          imageData={imageData}
          totalImageCount={imageData ? 1 : 0}
          key="editor-bottom-navigation-bar"
        />
      </div>

      {activePreblastStep === PreblastSteps.BLAST_PARAMETERS && (
        <div className={styles.dialogWrapper}>
          <BlastParametersDialog />
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = {};

const mapStateToProps = (state: AppState) => ({
  imageData: state.labels.imageData,
  preblastActivePopup: state.preblast.activePopup,
  activePreblastStep: state.preblast.activeStep,
});

export default connect(mapStateToProps, mapDispatchToProps)(EditorContainer);
