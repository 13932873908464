import { ContextType } from "@data/enums/ContextType";
import { updateAppLoadingState } from "@store/general/actionCreators";
import { AppState } from "@store/index";
import {
  updateActiveLabelType,
  updateImageData,
  updateLabelNames,
} from "@store/labels/actionCreators";
import { IImageData, LabelName } from "@store/labels/types";
import { PreblastApi } from "@api/elevat3dApi/blast/PreblastApi";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { downloadFile } from "utils/FirebaseStorageUtil/FirebaseStorageUtil";
import { ImageDataUtil } from "utils/ImageDataUtil";
import EditorContainer from "../EditorContainer/EditorContainer";
import UploadImage from "../EditorContainer/UploadImage/UploadImage";
import RightBar from "../../Common/RightBar/RightBar";
import PreblastStepsComponent from "./PreblastSteps/PreblastStepsComponent";
import styles from "./PreblastView.module.scss";
import Elevat3dApi from "@api/elevat3dApi/Elevat3dApi";
import { PreblastDocumentImporter } from "@logic/import/PreblastDocumentImporter";
import { LabelType } from "@data/enums/LabelType";
import { Snackbar, Alert, CircularProgress } from "@mui/material";
import {
  AlertState,
  BlastParameters,
  PreblastPopups,
  PreblastSteps,
  Scale,
  StepsState,
} from "@store/preblast/types";
import {
  updateActivePopup,
  updateActivePreblastStep,
  updatePreblastDocument,
  updatePreblastImage,
  updateScale,
  updateStepsState,
} from "@store/preblast/actionCreators";
import {
  IDeserializedPreblastDocument,
  IPreblastDocumentResponse,
} from "@logic/import/DrillMapSerializer";
import { LoadingState } from "@data/enums/LoadingState";
import ChangeImageConfirmationDialog from "./PreblastPopupView/ChangeImageConfirmationDialog/ChangeImageConfirmationDialog";
import DrillmapPopup from "./PreblastPopupView/DrillmapPopup/DrillmapPopup";
import HoleDetectionConfirmationPopup from "./PreblastPopupView/HoleDetectionConfirmationPopup/HoleDetectionConfirmationPopup";
import ScalePointsPopup from "./PreblastPopupView/ScalePointsPopup/ScalePointsPopup";
import HoleDetectionPopup from "./PreblastPopupView/HoleDetectionPopup/HoleDetectionPopup";
import PreblastSummary from "./PreblastSummary/PreblastSummary";

interface Props {
  imageData: IImageData;
  updateImageData: typeof updateImageData;
  updateLabelNames: typeof updateLabelNames;
  updateActiveLabelType: typeof updateActiveLabelType;
  activePreblastStep: PreblastSteps;
  updatePreblastStepsState: typeof updateStepsState;
  updateActivePreblastStep: typeof updateActivePreblastStep;
  preblastStepsState: StepsState;
  preblastAlertState: AlertState;
  scale: Scale;
  blastParameters: BlastParameters;
  prevPreblastStep: PreblastSteps;
  updatePreblastDocument: typeof updatePreblastDocument;
  preblastDocument: IPreblastDocumentResponse | null;
  updateScale: typeof updateScale;
  updateAppLoadingState: typeof updateAppLoadingState;
  updateActivePreblastPopup: typeof updateActivePopup;
  highlightedLabelId: string;
  updatePreblastImage: typeof updatePreblastImage;
  preblastActivePopup: PreblastPopups;
  changedHoles: boolean;
}

function PreblastView({
  updateImageData,
  updateLabelNames,
  updateActiveLabelType,
  imageData,
  activePreblastStep,
  updatePreblastStepsState,
  preblastStepsState,
  updateActivePreblastStep,
  preblastAlertState,
  scale,
  blastParameters,
  preblastDocument,
  updatePreblastDocument,
  prevPreblastStep,
  updateScale,
  updateAppLoadingState,
  updateActivePreblastPopup,
  highlightedLabelId,
  updatePreblastImage,
  preblastActivePopup,
  changedHoles,
}: Props) {
  const { siteId, blastId } = useParams<{ siteId: string; blastId: string }>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const response = await Elevat3dApi.get(
          `/sites/${siteId}/blasts/${blastId}/pre/`
        );
        if (response.status === 204) {
          setIsLoading(false);
          return null;
        }
        const file = await downloadFile(
          `drillmap/${siteId}/${blastId}/1`,
          response.image_details.image_name
        );

        if (file) {
          updateImageData({
            ...ImageDataUtil.createImageDataFromFileData(file),
          });

          const importer = new PreblastDocumentImporter([LabelType.RECT]);
          await importer.invoke(onPreblastLoadSuccess, onPreblastLoadFailure);
        } else {
        }
        setIsLoading(false);
        return response;
      } catch (error) {
        setIsLoading(false);
      }
    })();
  }, []);

  const onPreblastLoadSuccess = (
    loadedPreblastDocument: IDeserializedPreblastDocument,
    newImageData: IImageData,
    labelNames: LabelName[]
  ) => {
    updateImageData(newImageData);
    newImageData && updatePreblastImage(newImageData.fileData);
    updateLabelNames(labelNames);
    updatePreblastDocument(loadedPreblastDocument);

    let newActiveStep: PreblastSteps = PreblastSteps.HOLE_DETECTION;
    if (
      loadedPreblastDocument.bounding_boxes &&
      loadedPreblastDocument.bounding_boxes.length > 0
    ) {
      newActiveStep = PreblastSteps.SCALE_POINTS;
      if (
        loadedPreblastDocument.scale &&
        loadedPreblastDocument.scale.source !== null &&
        loadedPreblastDocument.scale.target !== null &&
        loadedPreblastDocument.scale.distance_m !== 0
      ) {
        newActiveStep = PreblastSteps.BLAST_PARAMETERS;

        if (loadedPreblastDocument.blast_parameters) {
          const blastParams = loadedPreblastDocument.blast_parameters;
          if (blastParams.burden > 0 && blastParams.spacing > 0) {
            newActiveStep = PreblastSteps.DRILLMAP;
          }
          if (loadedPreblastDocument.drillmap) {
            updatePreblastStepsState({
              ...preblastStepsState,
              [PreblastSteps.DRILLMAP]: { accessible: true, complete: true },
            });
            if (loadedPreblastDocument.summary) {
              newActiveStep = PreblastSteps.SUMMARY;
            }
          }
        }
      }
    }
    updateActivePreblastStep(newActiveStep);
  };
  const onPreblastLoadFailure = () => {
    alert(
      "Error occured while processing request, please try again later or contact support."
    );
  };

  useEffect(() => {
    if (imageData && imageData.labelPoints.length > 0) {
      updateScale({
        ...scale,
        source: imageData.labelPoints[0],

        ...(imageData.labelPoints.length > 1 && {
          target: imageData.labelPoints[1],
        }),
      });
    }
  }, [imageData]);

  useEffect(() => {
    let newPreblastStepsState = { ...preblastStepsState };
    if (imageData) {
      newPreblastStepsState = {
        ...newPreblastStepsState,
        [PreblastSteps.UPLOAD_IMAGE]: {
          complete: true,
          accessible: true,
        },
      };

      if (imageData.labelRects.length > 0) {
        newPreblastStepsState = {
          ...newPreblastStepsState,
          [PreblastSteps.HOLE_DETECTION]: {
            complete: true,
            accessible: true,
          },
          [PreblastSteps.SCALE_POINTS]: { complete: false, accessible: true },
        };

        if (scale.source && scale.target && scale.scaleDistanceCm > 0) {
          newPreblastStepsState = {
            ...newPreblastStepsState,
            [PreblastSteps.SCALE_POINTS]: {
              complete: true,
              accessible: true,
            },
            [PreblastSteps.BLAST_PARAMETERS]: {
              accessible: true,
              complete: false,
            },
          };
          if (blastParameters.spacing > 0 && blastParameters.burden > 0) {
            newPreblastStepsState = {
              ...newPreblastStepsState,
              [PreblastSteps.BLAST_PARAMETERS]: {
                accessible: true,
                complete: true,
              },
              [PreblastSteps.DRILLMAP]: {
                accessible: true,
                complete: false,
              },
            };
            if (imageData.labelLines.length > 0 && !changedHoles) {
              newPreblastStepsState = {
                ...newPreblastStepsState,
                [PreblastSteps.DRILLMAP]: {
                  accessible: true,
                  complete: true,
                },
                [PreblastSteps.SUMMARY]: {
                  accessible: true,
                  complete: true,
                },
              };
            } else {
              newPreblastStepsState = {
                ...newPreblastStepsState,
                [PreblastSteps.DRILLMAP]: {
                  accessible: true,
                  complete: false,
                },
                [PreblastSteps.SUMMARY]: {
                  accessible: false,
                  complete: false,
                },
              };
            }
          } else {
            newPreblastStepsState = {
              ...newPreblastStepsState,
              [PreblastSteps.BLAST_PARAMETERS]: {
                accessible: true,
                complete: false,
              },
              [PreblastSteps.DRILLMAP]: {
                accessible: false,
                complete: false,
              },
              [PreblastSteps.SUMMARY]: {
                accessible: false,
                complete: false,
              },
            };
          }
        } else {
          newPreblastStepsState = {
            ...newPreblastStepsState,
            [PreblastSteps.SCALE_POINTS]: {
              complete: false,
              accessible: true,
            },
            [PreblastSteps.BLAST_PARAMETERS]: {
              accessible: false,
              complete: false,
            },
            [PreblastSteps.DRILLMAP]: {
              accessible: false,
              complete: false,
            },
            [PreblastSteps.SUMMARY]: {
              accessible: false,
              complete: false,
            },
          };
        }
      } else {
        newPreblastStepsState = {
          ...newPreblastStepsState,
          [PreblastSteps.HOLE_DETECTION]: {
            accessible: true,
            complete: false,
          },
          [PreblastSteps.SCALE_POINTS]: {
            accessible: false,
            complete: false,
          },
          [PreblastSteps.BLAST_PARAMETERS]: {
            accessible: false,
            complete: false,
          },
          [PreblastSteps.DRILLMAP]: {
            accessible: false,
            complete: false,
          },
          [PreblastSteps.SUMMARY]: {
            accessible: false,
            complete: false,
          },
        };
      }
    } else {
      newPreblastStepsState = {
        [PreblastSteps.UPLOAD_IMAGE]: {
          complete: false,
          accessible: true,
        },
        [PreblastSteps.HOLE_DETECTION]: {
          accessible: false,
          complete: false,
        },
        [PreblastSteps.SCALE_POINTS]: {
          accessible: false,
          complete: false,
        },
        [PreblastSteps.BLAST_PARAMETERS]: {
          accessible: false,
          complete: false,
        },
        [PreblastSteps.DRILLMAP]: {
          accessible: false,
          complete: false,
        },
        [PreblastSteps.SUMMARY]: {
          accessible: false,
          complete: false,
        },
      };
    }
    updatePreblastStepsState(newPreblastStepsState);
  }, [imageData, scale, blastParameters]);

  useEffect(() => {
    switch (activePreblastStep) {
      case PreblastSteps.HOLE_DETECTION: {
        updateActiveLabelType(LabelType.RECT);
        if (imageData && imageData.labelRects.length === 0) {
          updateActivePreblastPopup(PreblastPopups.HOLE_DETECTION);
        }
        break;
      }
      case PreblastSteps.SCALE_POINTS: {
        updateActiveLabelType(LabelType.POINT);
        if (scale.source === null && scale.target === null) {
          updateActivePreblastPopup(PreblastPopups.SCALE_POINTS);
        }
        break;
      }
      case PreblastSteps.BLAST_PARAMETERS: {
        updateActiveLabelType(null);
        break;
      }
      case PreblastSteps.DRILLMAP: {
        updateActiveLabelType(LabelType.LINE);
        if (imageData.labelLines.length === 0) {
          updateActivePreblastPopup(PreblastPopups.DRILLMAP);
        }
        break;
      }
    }
  }, [activePreblastStep]);

  useEffect(() => {
    switch (prevPreblastStep) {
      case PreblastSteps.HOLE_DETECTION: {
        PreblastApi.saveBoundingBoxes(siteId, blastId, imageData.labelRects);

        break;
      }
      case PreblastSteps.SCALE_POINTS: {
        if (preblastDocument.scale) {
          if (
            scale.scaleDistanceCm !== preblastDocument.scale.distance_m * 100 ||
            scale.source.point.x !== preblastDocument.scale.source.x ||
            scale.source.point.y !== preblastDocument.scale.source.y ||
            scale.source.point.y !== preblastDocument.scale.source.y ||
            scale.target.point.x !== preblastDocument.scale.target.x ||
            scale.target.point.y !== preblastDocument.scale.target.y
          ) {
            // if the scale has changed relative to the saved scale, update the scale in db
            PreblastApi.saveScalePoints({ siteId, blastId }, scale);
          }
        } else {
          // if there is no saved scale, save the scale in db
          if (
            scale.source !== null &&
            scale.target !== null &&
            scale.scaleDistanceCm > 0
          ) {
            PreblastApi.saveScalePoints({ siteId, blastId }, scale);
          }
        }

        break;
      }
      case PreblastSteps.BLAST_PARAMETERS: {
        if (preblastDocument.blast_parameters) {
          if (
            blastParameters.spacing !==
              preblastDocument.blast_parameters.spacing ||
            blastParameters.burden !== preblastDocument.blast_parameters.burden
          ) {
            // if the blast parameters have changed relative to the saved blast parameters, update the blast parameters in db

            (async () => {
              updateAppLoadingState(LoadingState.LOADING);
              await PreblastApi.saveBlastParameters(
                { siteId, blastId },
                blastParameters
              );
              updateAppLoadingState(LoadingState.IDLE);
            })();
          }
        } else {
          if (blastParameters.spacing > 0 && blastParameters.burden > 0) {
            (async () => {
              updateAppLoadingState(LoadingState.LOADING);
              PreblastApi.saveBlastParameters(
                { siteId, blastId },
                blastParameters
              );
              updateAppLoadingState(LoadingState.IDLE);
            })();
          }
        }
        break;
      }
      case PreblastSteps.DRILLMAP: {
        updateActivePreblastPopup(null);
        break;
      }
    }
  }, [prevPreblastStep]);

  const renderContent = () => {
    if (activePreblastStep === PreblastSteps.UPLOAD_IMAGE) {
      return <UploadImage />;
    } else if (activePreblastStep === PreblastSteps.SUMMARY) {
      return <PreblastSummary />;
    } else {
      return <EditorContainer />;
    }
  };

  const renderPopupContent = () => {
    switch (preblastActivePopup) {
      case PreblastPopups.HOLE_DETECTION: {
        return <HoleDetectionPopup />;
      }
      case PreblastPopups.HOLE_DETECTION_CONFIRMATION: {
        return <HoleDetectionConfirmationPopup />;
      }
      case PreblastPopups.SCALE_POINTS: {
        return <ScalePointsPopup />;
      }
      case PreblastPopups.DRILLMAP: {
        return <DrillmapPopup />;
      }
      case PreblastPopups.CHANGE_IMAGE_CONFIRMATION: {
        return <ChangeImageConfirmationDialog />;
      }
      default: {
        return <></>;
      }
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        {!isLoading ? (
          <>
            {renderContent()}
            <RightBar>
              <PreblastStepsComponent />
            </RightBar>
          </>
        ) : (
          <div className={styles.loadingWrapper}>
            <CircularProgress />
            <span>Loading...</span>
          </div>
        )}
      </div>
      <Snackbar open={preblastAlertState.open} autoHideDuration={4000}>
        <Alert severity={preblastAlertState.severity}>
          {preblastAlertState.message}
        </Alert>
      </Snackbar>
      {preblastActivePopup && (
        <div className={styles.popupWrapper}>{renderPopupContent()}</div>
      )}
    </>
  );
}

const mapDispatchToProps = {
  updateImageData,
  updateActiveLabelType,
  updateLabelNames,
  updatePreblastStepsState: updateStepsState,
  updateActivePreblastStep: updateActivePreblastStep,
  updatePreblastDocument,
  updateScale: updateScale,
  updateAppLoadingState,
  updateActivePreblastPopup: updateActivePopup,
  updatePreblastImage,
};

const mapStateToProps = (state: AppState) => ({
  imageData: state.labels.imageData,
  activePreblastStep: state.preblast.activeStep,
  preblastStepsState: state.preblast.stepsState,
  preblastAlertState: state.preblast.alertState,
  scale: state.preblast.scale,
  blastParameters: state.preblast.blastParameters,
  preblastDocument: state.preblast.preblastDocument,
  prevPreblastStep: state.preblast.prevStep,
  highlightedLabelId: state.labels.highlightedLabelId,
  preblastActivePopup: state.preblast.activePopup,
  changedHoles: state.preblast.changedHoles,
});

export default connect(mapStateToProps, mapDispatchToProps)(PreblastView);
