import {
  LabelLine,
  LabelName,
  LabelPoint,
  LabelPolygon,
  LabelRect,
} from "../store/labels/types";
import { v4 as uuidv4 } from "uuid";
import { find } from "lodash";
import { IRect } from "../interfaces/IRect";
import { LabelStatus } from "../data/enums/LabelStatus";
import { IPoint } from "../interfaces/IPoint";
import { ILine } from "../interfaces/ILine";
import { sample } from "lodash";
import { Settings } from "../settings/Settings";

export class LabelUtil {
  public static createLabelName(name: string): LabelName {
    return {
      id: uuidv4(),
      name,
      color: sample(Settings.LABEL_COLORS_PALETTE),
    };
  }

  public static createLabelRect(
    labelId: string,
    rect: IRect,
    isCreatedByAI = false
  ): LabelRect {
    return {
      id: uuidv4(),
      labelId,
      rect,
      isCreatedByAI,
      status: LabelStatus.ACCEPTED,
      suggestedLabel: null,
    };
  }

  public static createLabelPolygon(
    labelId: string,
    vertices: IPoint[]
  ): LabelPolygon {
    return {
      id: uuidv4(),
      labelId,
      vertices,
    };
  }

  public static createLabelLine(
    labelId: string,
    line: ILine,
    metadata?: {
      distanceMetres?: number;
      error?: number;
      target?: number;
      direction?: "vertical" | "horizontal";
    }
  ): LabelLine {
    return {
      id: uuidv4(),
      labelId,
      line,
      ...(metadata ? { metadata } : { metadata: {} }),
    };
  }

  public static createLabelPoint(params: {
    point: IPoint;
    id?: string;
    labelId?: string;
    isCreatedByAI?: boolean;
    status?: LabelStatus;
    suggestedLabel?: string;
  }): LabelPoint {
    return {
      id: params.id || uuidv4(),
      labelId: params.labelId || null,
      point: params.point,

      // AI
      isCreatedByAI: params.isCreatedByAI || false,
      status: params.status || LabelStatus.ACCEPTED,
      suggestedLabel: params.suggestedLabel || null,
    };
  }

  public static createLine(
    startX: number,
    startY: number,
    endX: number,
    endY: number
  ): ILine {
    return {
      start: { x: startX, y: startY },
      end: { x: endX, y: endY },
    };
  }

  public static labelNamesIdsDiff(
    oldLabelNames: LabelName[],
    newLabelNames: LabelName[]
  ): string[] {
    return oldLabelNames.reduce(
      (missingIds: string[], labelName: LabelName) => {
        if (!find(newLabelNames, { id: labelName.id })) {
          missingIds.push(labelName.id);
        }
        return missingIds;
      },
      []
    );
  }
}
