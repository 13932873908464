import DeletionConfirmationDialog from "views/Common/DeletionConfirmationDialog/DeletionConfirmationDialog";
import dialogStyles from "views/Common/DeletionConfirmationDialog/DeletionConfirmationDialog.module.scss";
import { TextField } from "@mui/material";
import { useState } from "react";
import { Blast } from "data/Elevat3dApiTypes/BlastTypes";
import styles from "./BlastDeletionConfirmationDialog.module.scss";
import BlastsApi from "@api/elevat3dApi/BlastsApi";
import { useNavigate } from "react-router-dom";
import {
  useAccountViewDispatch,
  updateAlertState,
  updateShowLoaderOverlay,
  updateLocalFavouriteBlasts,
  useAccountViewContextState,
} from "views/AccountView/accountViewContext";
import { Site } from "data/Elevat3dApiTypes/SiteTypes";

type Props = {
  open: boolean;
  onClose: () => void;
  blast: Blast;
  site: Site;
};

export default function BlastDeletionConfirmationDialog({
  open,
  onClose,
  blast,
  site,
}: Props) {
  const dispatch = useAccountViewDispatch();
  const navigate = useNavigate();
  const { localFavouriteBlasts } = useAccountViewContextState();
  const [error, setError] = useState(false);
  const [textMismatchError, setTextMismatchError] = useState(false);
  const [text, setText] = useState("");

  const handleDeleteBlast = async () => {
    if (text.trim() !== blast.block_name.trim()) {
      setTextMismatchError(true);
      return;
    }
    setTextMismatchError(false);
    dispatch(updateShowLoaderOverlay(true));
    try {
      await BlastsApi.deleteBlast({ blastId: blast.blast_id, siteId: site.id });
      dispatch(updateShowLoaderOverlay(false));
      dispatch(
        updateLocalFavouriteBlasts(
          localFavouriteBlasts.filter(
            (favouriteBlast) => favouriteBlast.blast_id !== blast.blast_id
          )
        )
      );
      dispatch(
        updateAlertState({
          open: true,
          message: "Blast deleted",
          severity: "success",
        })
      );
      navigate(`/sites/${site.id}/blasts?refresh=true`);
      onClose();
    } catch (error) {
      dispatch(updateShowLoaderOverlay(false));
      setError(true);
    }
  };

  return (
    <DeletionConfirmationDialog
      title="Delete blast"
      open={open}
      onClose={onClose}
      placeholder="Block name"
      onConfirm={handleDeleteBlast}
    >
      <div className={styles.DialogContent}>
        <p>
          Enter <span className={styles.TextToType}>{blast.block_name}</span> to
          confirm deletion.{" "}
          {blast.permissions !== "owner"
            ? "This action will only remove you from the blast."
            : "This action will delete the blast for you, as well as all users that have permissions to access this blast."}{" "}
          This action cannot be reverted.
        </p>
        <div className={styles.InputsWrapper}>
          <TextField
            value={text}
            onChange={(e) => setText(e.target.value)}
            className={styles.Input}
            placeholder="Block name"
            autoFocus={true}
            InputProps={{
              style: { color: "white" },
              sx: {},
            }}
            inputProps={{ style: { padding: "10px" } }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#aaaaaa",
                },
                "&:hover fieldset": {
                  borderColor: "#cccccc",
                },
                "&.Mui-focused fieldset": {
                  borderColor: "#cccccc",
                },
              },
            }}
            error={textMismatchError}
            autoComplete={"off"}
          />
          {textMismatchError && (
            <p className={dialogStyles.ErrorText}>
              The text you entered does not match the block name.
            </p>
          )}
        </div>
        {error && (
          <p className={dialogStyles.ErrorText}>
            An error occurred. Please try again later.
          </p>
        )}
      </div>
    </DeletionConfirmationDialog>
  );
}
