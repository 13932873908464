import React, { useEffect } from "react";
import "./App.scss";
import { connect } from "react-redux";
import AccountView from "views/AccountView/AccountView";
import ProfileView from "views/AccountView/ProfileView/ProfileView";
import RecentsView from "views/AccountView/RecentsView/RecentsView";
import SitesView from "views/AccountView/SitesView/SitesView";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import AuthView from "@views/AuthView/AuthView";
import ErrorBoundary from "views/AccountView/ErrorBoundary/ErrorBoundary";
import SiteView from "views/AccountView/SiteView/SiteView";
import SiteOverview from "views/AccountView/SiteView/SiteOverview/SiteOverview";
import BlastsView from "views/AccountView/SiteView/BlastsView/BlastsView";
import SiteSettingsView from "views/AccountView/SiteView/SiteSettingsView/SiteSettingsView";
import AccountBlastView from "views/AccountView/BlastView/BlastView";
import BlastOverview from "views/AccountView/BlastView/BlastOverview/BlastOverview";
import BlastSettingsView from "views/AccountView/BlastView/BlastSettingsView/BlastSettingsView";
import Cookies from "js-cookie";
import ProtectedRoute from "modules/hooksAndHOCs/ProtectedRoute";
import ProjectView from "@views/ProjectView/ProjectView";
import PreblastView from "@views/ProjectView/PreblastView/PreblastView";
import ProjectBlastOverview from "@views/ProjectView/BlastOverview/BlastOverview";
import PostBlastView from "@views/ProjectView/PostBlastView/PostBlastView";
import DuringBlastView from "@views/ProjectView/DuringBlastView/DuringBlastView";
import LoginForm from "@views/AuthView/LoginForm";
import ForgotPasswordForm from "@views/AuthView/ForgotPasswordForm";
import PasswordReset from "@views/AuthView/PasswordReset";
import PasswordResetSuccess from "@views/AuthView/PasswordResetSuccess";
import FumeAnalysisView from "@views/ProjectView/DuringBlastView/FumeAnalysisView/FumeAnalysisView";

const App: React.FC = () => {
  useEffect(() => {
    // set timezone cookie, formatted as, e.g., "Europe/London".
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    Cookies.set("timezone", timezone);
  });
  return (
    <Router>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<AccountView />}>
            <Route index element={<Navigate to="/recents" />} />
            <Route
              path="/profile"
              element={<ProfileView />}
              errorElement={<ErrorBoundary />}
            />
            <Route path="/recents" element={<RecentsView />} />
            <Route
              path="/sites"
              element={<SitesView />}
              errorElement={<ErrorBoundary />}
            />
            <Route
              path="/sites/:siteId"
              element={<SiteView />}
              errorElement={<ErrorBoundary />}
            >
              <Route index element={<SiteOverview />} />
              <Route path="/sites/:siteId/blasts" element={<BlastsView />} />
              <Route
                path="/sites/:siteId/settings"
                element={<SiteSettingsView />}
              />
            </Route>
            <Route
              path="/sites/:siteId/blasts/:blastId"
              element={<AccountBlastView />}
            >
              <Route index element={<BlastOverview />} />
              <Route
                path="/sites/:siteId/blasts/:blastId/settings"
                element={<BlastSettingsView />}
              />
            </Route>
          </Route>
        </Route>

        <Route element={<ProtectedRoute />}>
          <Route
            path="editor/sites/:siteId/blasts/:blastId"
            element={<ProjectView />}
          >
            <Route index element={<ProjectBlastOverview />} />
            <Route path="pre" element={<PreblastView />} />
            {/* Uncomment below when during and post blast analysis introduced */}
            {/* <Route path="during" element={<DuringBlastView />} />
            <Route path="during/fume-analysis" element={<FumeAnalysisView />} /> */}
            <Route path="post" element={<PostBlastView />} />
          </Route>
        </Route>
        <Route path="auth" element={<AuthView />}>
          <Route path="login" element={<LoginForm />} />
          <Route path="forgot-password" element={<ForgotPasswordForm />} />
          <Route path="reset-password" element={<PasswordReset />} />
          <Route
            path="password-reset-success"
            element={<PasswordResetSuccess />}
          />
        </Route>

        <Route path="*" element={<Navigate to="/recents" />} />
      </Routes>
    </Router>
  );
};

export default connect()(App);
