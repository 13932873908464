import { Outlet, useParams, useOutletContext, Link } from "react-router-dom";
import Elevat3dApi from "api/elevat3dApi/Elevat3dApi";
import { useState, useEffect } from "react";
import { Site } from "data/Elevat3dApiTypes/SiteTypes";
import styles from "./SiteView.module.scss";
import useSWR from "swr";
import SiteDeletionConfirmationDialog from "./SiteOverview/SiteDeletionConfirmationDialog";

export default function SiteView() {
  const { siteId } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [siteDeletionDialogOpen, setSiteDeletionDialogOpen] = useState(false);
  const [childKey, setChildKey] = useState(0);
  const {
    data: site,
    error,
    mutate,
  } = useSWR(
    `/sites/${siteId}/`,
    async (url) => {
      setIsLoading(true);
      const result = await Elevat3dApi.get(url);
      setIsLoading(false);
      return result as Site;
    },
    {
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    // if site is already cached, set isLoading to false
    if (site) {
      setIsLoading(false);
    }
  }, [site]);

  return (
    <div className={styles.Wrapper}>
      {error ? (
        <div>
          This site doesn't exist. Press <Link to="/sites">here</Link> to go
          back.
        </div>
      ) : (
        <Outlet
          context={{
            setSiteDeletionDialogOpen,
            site,
            refreshSiteData: mutate,
            siteIsLoading: isLoading,
          }}
        />
      )}
      {site && (
        <SiteDeletionConfirmationDialog
          key={childKey}
          open={siteDeletionDialogOpen}
          site={site}
          onClose={() => {
            setChildKey(childKey + 1);
            setSiteDeletionDialogOpen(false);
          }}
        />
      )}
    </div>
  );
}

export type SiteViewContext = {
  setSiteDeletionDialogOpen: (open: boolean) => void;
  site: Site;
  siteIsLoading: boolean;
  refreshSiteData: () => void;
};

export const useSiteViewContext = () => {
  const context = useOutletContext<SiteViewContext>();
  return context;
};
