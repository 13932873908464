import { BlastPattern } from "@data/enums/BlastPattern";
import { LabelPoint } from "@store/labels/types";
import { IDeserializedPreblastDocument } from "logic/import/DrillMapSerializer";

export type BlastParameters = {
  burden: number;
  spacing: number;
  blastPattern: BlastPattern;
};

export enum PreblastActions {
  UPDATE_BLAST_PARAMETERS = "@@UPDATE_BLAST_PARAMETERS",
  UPDATE_ACTIVE_STEP = "@@UPDATE_ACTIVE_STEP",
  UPDATE_ACTIVE_POPUP = "@@UPDATE_ACTIVE_POPUP",
  UPDATE_STEPS_STATE = "@@UPDATE_STEPS_STATE",
  UPDATE_ALERT_STATE = "@@UPDATE_ALERT_STATE",
  UPDATE_SCALE = "@@UPDATE_SCALE",
  UPDATE_PREBLAST_DOCUMENT = "@@UPDATE_PREBLAST_DOCUMENT",
  UPDATE_PREBLAST_IMAGE = "@@UPDATE_PREBLAST_IMAGE",
  UPDATE_CHANGED_HOLES = "@@UPDATE_CHANGED_HOLES",
  UPDATE_CHANGED_DRILLMAP = "@@UPDATE_CHANGED_DRILLMAP",
  RESET = "@@RESET",
}

export enum PreblastSteps {
  UPLOAD_IMAGE,
  HOLE_DETECTION,
  SCALE_POINTS,
  BLAST_PARAMETERS,
  DRILLMAP,
  SUMMARY,
}

export type StepsState = {
  [Key in PreblastSteps]: { complete: boolean; accessible: boolean };
};

export type AlertState = {
  message: string;
  severity: "error" | "success" | "info" | "warning";
  open: boolean;
};

export enum PreblastPopups {
  HOLE_DETECTION = "HOLE_DETECTION",
  HOLE_DETECTION_CONFIRMATION = "HOLE_DETECTION_CONFIRMATION",
  SCALE_POINTS = "SCALE_POINTS",
  DRILLMAP = "DRILLMAP",
  CHANGE_IMAGE_CONFIRMATION = "CHANGE_IMAGE_CONFIRMATION",
}

export type Scale = {
  source: LabelPoint | null;
  target: LabelPoint | null;
  scaleDistanceCm: number;
};

export type PreblastState = {
  blastParameters: BlastParameters;
  activeStep: PreblastSteps;
  prevStep: PreblastSteps;
  activePopup: PreblastPopups | null;
  stepsState: StepsState;
  alertState: AlertState;
  scale: Scale;
  preblastDocument: IDeserializedPreblastDocument | {};
  preblastImage: File | null;
  changedHoles: boolean;
  changedDrillMap: boolean;
};

interface UpdateDrillParameters {
  type: typeof PreblastActions.UPDATE_BLAST_PARAMETERS;
  payload: {
    blastParameters: BlastParameters;
  };
}

interface UpdateActiveStep {
  type: typeof PreblastActions.UPDATE_ACTIVE_STEP;
  payload: { activeStep: PreblastSteps };
}

interface UpdateActivePopup {
  type: typeof PreblastActions.UPDATE_ACTIVE_POPUP;
  payload: { activePopup: PreblastPopups | null };
}

interface UpdateStepsState {
  type: typeof PreblastActions.UPDATE_STEPS_STATE;
  payload: { stepsState: StepsState };
}

interface UpdateAlertState {
  type: typeof PreblastActions.UPDATE_ALERT_STATE;
  payload: { alertState: AlertState };
}

interface UpdateScale {
  type: typeof PreblastActions.UPDATE_SCALE;
  payload: { scale: Scale };
}

interface UpdatePreblastDocument {
  type: typeof PreblastActions.UPDATE_PREBLAST_DOCUMENT;
  payload: { preblastDocument: IDeserializedPreblastDocument };
}

interface UpdatePreblastImage {
  type: typeof PreblastActions.UPDATE_PREBLAST_IMAGE;
  payload: {
    preblastImage: File;
  };
}

interface UpdateChangedHoles {
  type: typeof PreblastActions.UPDATE_CHANGED_HOLES;
  payload: {
    changedHoles: boolean;
  };
}

interface UpdateChangedDrillmap {
  type: typeof PreblastActions.UPDATE_CHANGED_DRILLMAP;
  payload: {
    changedDrillMap: boolean;
  };
}

interface Reset {
  type: typeof PreblastActions.RESET;
}

export type PreblastActionTypes =
  | UpdateDrillParameters
  | UpdateActiveStep
  | UpdateActivePopup
  | UpdateAlertState
  | UpdateStepsState
  | UpdateScale
  | UpdatePreblastDocument
  | UpdatePreblastImage
  | UpdateChangedHoles
  | UpdateChangedDrillmap
  | Reset;
