import styles from "./DateInput.module.scss";
import React from "react";
import {
  MenuItem,
  TextField,
  Select,
  InputLabel,
  FormControl,
} from "@mui/material";
import { useState } from "react";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const years = Array.from({ length: 41 }, (_, i) => i + 2000);

const days = Array.from({ length: 31 }, (_, i) => i + 1);

type Props = {
  id: string;
  label?: string;
  value: string;
  onChange: (date: string) => void;
  className?: string;
};

function DateInput({ label, value, onChange, id }: Props) {
  const [day, setDay] = useState(parseInt(value.split("-")[2]));
  const [month, setMonth] = useState(parseInt(value.split("-")[1]) - 1);
  const [year, setYear] = useState(parseInt(value.split("-")[0]));

  const updateDate = (newYear: number, newMonth: number, newDay: number) => {
    onChange(newYear + "-" + (newMonth + 1) + "-" + newDay);
  };

  const handleDayChange = (event) => {
    const newDay = parseInt(event.target.value);
    setDay(newDay);
    updateDate(year, month, newDay);
  };

  const handleMonthChange = (event) => {
    const newMonth = parseInt(event.target.value);
    setMonth(newMonth);
    updateDate(year, newMonth, day);
  };

  const handleYearChange = (event) => {
    const newYear = parseInt(event.target.value);
    setYear(newYear);
    updateDate(newYear, month, day);
  };

  return (
    <div className={styles.Root}>
      <fieldset id={id} className={styles.InputsWrapper}>
        <legend className={styles.Legend}>{label}</legend>
        <div className={`MuiInput ${styles.Input} ${styles.DayInput}`}>
          <FormControl variant="filled">
            <InputLabel>Day</InputLabel>
            <Select
              onChange={handleDayChange}
              native={true}
              value={day}
              placeholder="Day"
              inputProps={{ style: { color: "white" } }}
              sx={{ height: "100%" }}
            >
              {days.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={`MuiInput ${styles.Input} ${styles.MonthInput}`}>
          <FormControl variant="filled">
            <InputLabel>Month</InputLabel>
            <Select
              native={true}
              id="month"
              placeholder="Month"
              value={month}
              onChange={handleMonthChange}
              variant="filled"
            >
              {months.map((option, index) => (
                <option key={option} value={index}>
                  {option}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className={`MuiInput ${styles.Input} ${styles.YearInput}`}>
          <FormControl variant="filled">
            <InputLabel>Year</InputLabel>
            <Select
              native={true}
              id="year"
              value={year}
              placeholder="Year"
              onChange={handleYearChange}
              variant="filled"
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>
      </fieldset>
    </div>
  );
}

export default DateInput;
