import styles from "./OutletLoadingScreen.module.scss";
import CircularProgress from "@mui/material/CircularProgress";

export default function OutletLoadingScreen() {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      className={styles.LoaderOverlay}
    >
      <div className={styles.Inner}>
        <CircularProgress />
        <div className={styles.Msg}>Loading...</div>
      </div>
    </div>
  );
}
