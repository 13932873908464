import { BlastPattern } from "@data/enums/BlastPattern";
import { BlastActionTypes, BlastState } from "./types";
import { Actions } from "@store/Actions";

export const initialState: BlastState = {
   blastDetails: {
      blastId: "",
      siteId: ""
   }
};

export function blastReducer(
  state = initialState,
  action: BlastActionTypes
): BlastState {
  switch (action.type) {
    case Actions.UPDATE_BLAST_DETAILS: {
      return {
        ...state,
        blastDetails: action.payload.blastDetails,
      };
    }
    default: {
      return state;
    }
  }
}
