import styles from "./LoadingScreen.module.scss";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

type Props = {
  position?: "fixed" | "absolute";
};

const LoadingScreen: React.FC<Props> = ({ position }) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={{ position, top: "0", left: "0", width: "100%", height: "100%" }}
      className={styles["loader-overlay"]}
    >
      <div className={styles.inner}>
        <CircularProgress />
        <div className={styles.msg}>Loading...</div>
      </div>
    </div>
  );
};

LoadingScreen.defaultProps = {
  position: "fixed",
};

export default LoadingScreen;
