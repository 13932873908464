import { Actions } from "@store/Actions";
import { BlastActionTypes } from "./types";

export function updateBlastDetails(blastDetails: {
  blastId: string;
  siteId: string;
}): BlastActionTypes {
  return {
    type: Actions.UPDATE_BLAST_DETAILS,
    payload: {
      blastDetails
    },
  };
}
