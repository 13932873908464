import Cookies from "js-cookie";

/**function that takes in date, gets the delta of days from present to then, and returns a string like "2 days ago", or "1 month ago", etc. If delta less than a day, returns "just now". If delta is greater than 30 days, return "1 month ago", etc.
 *
 */
export function convertDateDeltaToString(date: Date) {
  const delta = Math.abs(new Date().getTime() - date.getTime()) / 1000;
  const day = 86400;
  const week = 604800;
  const month = 2629743;
  const year = 31556926;
  if (delta < day) {
    return "Just now";
  } else if (delta < week) {
    return (
      Math.floor(delta / day) +
      " day" +
      (Math.floor(delta / day) > 1 ? "s" : "") +
      " ago"
    );
  } else if (delta < month) {
    return (
      Math.floor(delta / week) +
      " week" +
      (Math.floor(delta / week) > 1 ? "s" : "") +
      " ago"
    );
  } else if (delta < year) {
    return (
      Math.floor(delta / month) +
      " month" +
      (Math.floor(delta / month) > 1 ? "s" : "") +
      " ago"
    );
  } else {
    return (
      Math.floor(delta / year) +
      " year" +
      (Math.floor(delta / year) > 1 ? "s" : "") +
      " ago"
    );
  }
}

/**
 * Convert date to string formatted as "22 Nov 2022"
 */
export function formatDate(date: Date | string) {
  return (typeof date === "string" ? new Date(date) : date).toLocaleDateString(
    "en-GB",
    {
      day: "numeric",
      month: "short",
      year: "numeric",
    }
  );
}

/**
 * Convert date to another timezone
 * @param  date Date to convert
 * @param  tzString Timezone string, e.g. "Europe/London"
 */
export function convertTZ(date: Date | string, tzString?: string) {
  return new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-za", {
      timeZone: tzString ? tzString : Cookies.get("timezone"),
    })
  );
}
