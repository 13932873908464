import styles from "./LeftBar.module.scss";
import { useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import SiteIcon from "@mui/icons-material/AccountTree";
import RecentsIcon from "@mui/icons-material/AccessTime";
import RecentsTab from "./RecentsTab";
import SitesTab from "./SitesTab";
import FavouritesTab from "./FavouritesTab";

interface IProps {
  className?: string;
}

export const links = [
  {
    name: "Recently viewed",
    shortName: "Recents",
    href: "/recents",
    context: "Recently viewed",
    Icon: RecentsIcon,
    renderInMenu: true,
  },
  {
    name: "Sites",
    shortName: "Sites",
    href: "/sites",
    context: "Sites",
    Icon: SiteIcon,
    renderInMenu: true,
  },
];

function LeftBar({ className }: IProps) {
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<string>(
    location.pathname.includes("sites") ? "sites" : "recents"
  );
  const [showDropdownArrows, setShowDropdownArrows] = useState(false);

  useEffect(() => {
    setActiveTab(location.pathname.includes("sites") ? "sites" : "recents");
  }, [location.pathname]);

  const onLeftBarMouseOver = () => {
    setShowDropdownArrows(true);
  };

  const onLeftBarMouseLeave = () => {
    setShowDropdownArrows(false);
  };
  return (
    <div
      onMouseOver={onLeftBarMouseOver}
      onMouseLeave={onLeftBarMouseLeave}
      className={`${className} ${styles.LeftBar} ${
        showDropdownArrows ? styles.ShowDropdownArrows : ""
      }`}
    >
      <RecentsTab
        isActive={activeTab === "recents"}
        onClick={() => setActiveTab("recents")}
      />
      <SitesTab
        isActive={activeTab === "sites"}
        onClick={() => setActiveTab("sites")}
      />
      <FavouritesTab />
    </div>
  );
}

export default LeftBar;
