import { combineReducers } from "redux";
import { labelsReducer } from "./labels/reducer";
import { generalReducer } from "./general/reducer";
import { aiReducer } from "./ai/reducer";
import { preblastReducer } from "./preblast/reducer";
import { notificationsReducer } from "./notifications/reducer";
import {
  configureStore,
  createImmutableStateInvariantMiddleware,
} from "@reduxjs/toolkit";
import { blastReducer } from "./blast/reducer";
import { duringBlastReducer } from "./during-blast/reducer";

export const rootReducer = combineReducers({
  ai: aiReducer,
  preblast: preblastReducer,
  duringBlast: duringBlastReducer,
  blast: blastReducer,
  general: generalReducer,
  labels: labelsReducer,
  notifications: notificationsReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

const immutableInvariantMiddleware = createImmutableStateInvariantMiddleware({
  ignoredPaths: [
    "labels.imageData.fileData.lastModifiedDate",
    "preblast.preblastDocument.image_data.fileData.lastModifiedDate",
    "preblast.preblastImage.lastModifiedDate",
    "duringBlast.videoData.video.lastModifiedDate",
  ],
});

export function initializeStore() {
  return configureStore({
    reducer: rootReducer,
    devTools: true,
    middleware: [immutableInvariantMiddleware],
  });
}
