import styles from "./RecentsView.module.scss";
import { useState, Fragment } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BlastWithoutSiteContext } from "data/Elevat3dApiTypes/BlastTypes";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarIcon from "@mui/icons-material/Star";
import { convertDateDeltaToString } from "utils/DatetimeUtil";
import GenericTable, { Header } from "../GenericTable/GenericTable";
import tableStyles from "../GenericTable/GenericTable.module.scss";
import OutletLoadingScreen from "../OutletLoadingScreen/OutletLoadingScreen";
import ContextBar from "../ContextBar/ContextBar";
import BlastsApi from "@api/elevat3dApi/BlastsApi";
import { useRecentBlasts } from "modules/hooksAndHOCs/swrApiHooks";
import {
  useAccountViewDispatch,
  useAccountViewContextState,
  updateLocalFavouriteBlasts,
} from "../accountViewContext";

const headers: Header<BlastWithoutSiteContext>[] = [
  {
    name: "Block name",
    header: "block_name",
    sortable: true,
    sortDirection: "asc",
    sortFunction: (a: BlastWithoutSiteContext, b: BlastWithoutSiteContext) => {
      return a.block_name.localeCompare(b.block_name);
    },
  },
  {
    name: "Site name",
    header: "site_name",
    sortable: true,
    sortDirection: "asc",
    sortFunction: (a: BlastWithoutSiteContext, b: BlastWithoutSiteContext) => {
      return a.site_name.localeCompare(b.site_name);
    },
  },
  {
    name: "Last viewed",
    header: "last_viewed",
    sortable: true,
    sortDirection: "asc",
    sortFunction: (a: BlastWithoutSiteContext, b: BlastWithoutSiteContext) => {
      return (
        new Date(a.last_viewed).getTime() - new Date(b.last_viewed).getTime()
      );
    },
  },
];

function RecentsView() {
  const [activeItem, setActiveItem] = useState<BlastWithoutSiteContext>(null);
  const navigate = useNavigate();
  const { localFavouriteBlasts: localFavourites } =
    useAccountViewContextState();
  const dispatch = useAccountViewDispatch();
  const [sortState, setSortState] = useState<{
    header: string;
    direction: "asc" | "desc";
  }>({ header: "last_viewed", direction: "desc" });

  const { data: recentBlasts, error, isLoading } = useRecentBlasts();

  const handleDoubleClick = (blast: BlastWithoutSiteContext) => {
    navigate(`/sites/${blast.site_id}/blasts/${blast.blast_id}`);
  };

  /**
   * The function that is called when the user clicks on the favourite icon.
   * The value for favourite as passed in to the function is the PREVIOUS value. So it still needs to be toggled.
   * @param favourite
   * @param blastId
   * @param siteId
   */
  const handleToggleFavourite = async (
    blast: BlastWithoutSiteContext,
    favourite: boolean
  ) => {
    if (favourite) {
      dispatch(
        updateLocalFavouriteBlasts(
          localFavourites.filter((item) => item.blast_id !== blast.blast_id)
        )
      );
    } else {
      dispatch(updateLocalFavouriteBlasts([...localFavourites, blast]));
    }

    BlastsApi.favouriteBlast({
      blastId: blast.blast_id,
      siteId: blast.site_id,
      favourite: !favourite,
    });
  };

  const renderContent = () => {
    if (error) {
      return <div>There was an error</div>;
    }
    if (isLoading) {
      return <OutletLoadingScreen />;
    }

    return (
      <>
        <ContextBar
          activeRoute={[
            { name: "Recently viewed", shortName: "Recents", href: "/recents" },
          ]}
        />
        <GenericTable<BlastWithoutSiteContext>
          headers={headers}
          sortState={{ header: "last_viewed", direction: "desc" }}
          setSortState={setSortState}
        >
          {recentBlasts
            .sort((a, b) => {
              return sortState.direction === "asc"
                ? headers
                    .find((header) => header.header === sortState.header)
                    .sortFunction(a, b)
                : headers
                    .find((header) => header.header === sortState.header)
                    .sortFunction(b, a);
            })
            .map((blast: BlastWithoutSiteContext) => {
              const isFavourite = !!localFavourites.find(
                (favourite) => favourite.blast_id === blast.blast_id
              );
              return (
                <Fragment key={blast.blast_id}>
                  <Link
                    to={`/sites/${blast.site_id}/blasts/${blast.blast_id}`}
                    onClick={(e) => {
                      e.preventDefault();
                      setActiveItem(blast);
                    }}
                    onDoubleClick={() => handleDoubleClick(blast)}
                    className={`${tableStyles.Row} ${
                      activeItem && activeItem.blast_id === blast.blast_id
                        ? tableStyles.Active
                        : ""
                    }`}
                    style={{
                      gridTemplateColumns: `repeat(${headers.length}, 1fr)`,
                    }}
                  >
                    <div className={tableStyles.Cell}>
                      <div
                        onClick={(e) => {
                          e.preventDefault();
                          handleToggleFavourite(blast, isFavourite);
                        }}
                        className={tableStyles.FavouriteWrapper}
                      >
                        {isFavourite ? (
                          <StarIcon className={tableStyles.FilledStar} />
                        ) : (
                          <StarOutlineIcon
                            className={tableStyles.StarOutline}
                          />
                        )}
                      </div>
                      <p className={tableStyles.Text}>{blast.block_name}</p>
                    </div>
                    <div className={tableStyles.Cell}>
                      <p className={tableStyles.Text}>{blast.site_name}</p>
                    </div>
                    <div className={tableStyles.Cell}>
                      <p className={tableStyles.Text}>
                        {convertDateDeltaToString(new Date(blast.last_viewed))}
                      </p>
                    </div>
                  </Link>
                </Fragment>
              );
            })}
        </GenericTable>
      </>
    );
  };

  return <div className={styles.Wrapper}>{renderContent()}</div>;
}

export default RecentsView;
