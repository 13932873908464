import styles from "./SiteSettingsView.module.scss";
import ContextBar from "views/AccountView/ContextBar/ContextBar";
import { useLocation } from "react-router-dom";
import OutletLoadingScreen from "views/AccountView/OutletLoadingScreen/OutletLoadingScreen";
import { TextButton } from "views/Common/TextButton/TextButton";
import CustomTextInput from "views/Common/CustomTextInput/CustomTextInput";
import {
  updateAlertState,
  useAccountViewDispatch,
  updateLocalFavouriteBlasts,
  useAccountViewContextState,
} from "views/AccountView/accountViewContext";
import { useSiteViewContext } from "../SiteView";
import { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { Settings } from "settings/Settings";
import SitesApi from "@api/elevat3dApi/SitesApi";
import { useNavigate } from "react-router-dom";

const commonProps = {
  variant: "outlined" as "outlined",
  // make border color white
  InputProps: {
    style: {
      color: "white",
      borderColor: "white",
    },
  },
  InputLabelProps: {
    style: {
      color: "white",
    },
  },
  inputProps: {
    style: {
      padding: "10px",
      width: "200px",
    },
  },
  autoComplete: "off",
  sx: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#4F4F4F",
        transition: ".1s",
      },
      "&:hover fieldset": {
        borderColor: "#4F4F4F",
      },
      "&.Mui-focused fieldset": {
        borderColor: Settings.FOCUS_BORDER_COLOR,
      },
    },
  },
};

export default function SiteSettingsView() {
  const navigate = useNavigate();
  const dispatch = useAccountViewDispatch();
  const { localFavouriteBlasts } = useAccountViewContextState();
  const location = useLocation();
  const { site, siteIsLoading, refreshSiteData } = useSiteViewContext();
  const [error, setError] = useState<{ siteName: boolean; location: boolean }>({
    siteName: false,
    location: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [siteDetails, setSiteDetails] = useState({
    siteName: site?.site_name ?? "",
    location: site?.location ?? "",
  });

  useEffect(() => {
    setSiteDetails({
      siteName: site?.site_name ?? "",
      location: site?.location ?? "",
    });
  }, [site]);

  const handleUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (siteDetails.siteName === "") {
      return;
    }
    setIsLoading(true);
    try {
      await SitesApi.updateSite(site.id, {
        ...(siteDetails.siteName !== site.site_name && {
          site_name: siteDetails.siteName,
        }),
        ...(siteDetails.location !== site.location && {
          location: siteDetails.location,
        }),
      });
      dispatch(
        updateLocalFavouriteBlasts(
          localFavouriteBlasts.map((favouriteBlast) => {
            if (favouriteBlast.site_id === site.id) {
              return {
                ...favouriteBlast,
                site_name: siteDetails.siteName,
              };
            } else {
              return favouriteBlast;
            }
          })
        )
      );
      dispatch(
        updateAlertState({
          open: true,
          message: "Site updated successfully.",
          severity: "success",
        })
      );
      refreshSiteData();
      navigate("/sites/" + site.id);
    } catch (error) {
      dispatch(
        updateAlertState({
          open: true,
          message: "Something went wrong. Please try again later.",
          severity: "error",
        })
      );
    }
    setIsLoading(false);
  };

  const renderContent = () => {
    if (!site || siteIsLoading) {
      return <OutletLoadingScreen />;
    }

    return (
      <div>
        <ContextBar
          activeRoute={[
            { name: "Sites", shortName: "Sites", href: "/sites" },
            {
              name: site.site_name,
              shortName: site.site_name,
              href: `/sites/${site.id}`,
            },
            {
              name: "Settings",
              shortName: "Settings",
              href: location.pathname,
            },
          ]}
        />
        <div className={styles.Content}>
          <form onSubmit={handleUpdate} className={styles.FormGroupWrapper}>
            <dl className={styles.FormGroup}>
              <dt className={styles.Label}>
                <label htmlFor="site-name">Site name</label>
              </dt>
              <dd>
                <TextField
                  {...commonProps}
                  id="site-name"
                  value={siteDetails.siteName}
                  onChange={(e) => {
                    setError({ ...error, siteName: e.target.value === "" });
                    setSiteDetails({
                      ...siteDetails,
                      siteName: e.target.value,
                    });
                  }}
                />
                {error.siteName && (
                  <p className={styles.ErrorText}>Site name can't be empty.</p>
                )}
              </dd>
            </dl>
            <dl className={styles.FormGroup}>
              <dt className={styles.Label}>
                <label htmlFor="site-location">Location</label>
              </dt>
              <dd>
                <TextField
                  {...commonProps}
                  id="site-location"
                  value={siteDetails.location}
                  placeholder="No location"
                  onChange={(e) =>
                    setSiteDetails({ ...siteDetails, location: e.target.value })
                  }
                />
              </dd>
            </dl>

            <div className={styles.Actions}>
              <TextButton
                isDisabled={isLoading}
                type="submit"
                isLoading={isLoading}
                onClick={handleUpdate}
                buttonTheme="update"
                label="Update site"
              />
              <TextButton
                onClick={() => navigate("/sites/" + site.id)}
                buttonTheme="cancel"
                label="Cancel"
              />
            </div>
          </form>
        </div>
      </div>
    );
  };

  return <>{renderContent()}</>;
}
