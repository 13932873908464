import ContextBar from "../ContextBar/ContextBar";
import Actions, { Action } from "../Actions/Actions";
import styles from "./ProfileView.module.scss";
import { useUser } from "modules/hooksAndHOCs/swrApiHooks";
import OutletLoadingScreen from "../OutletLoadingScreen/OutletLoadingScreen";

export default function AccountView() {
  const { data: user, error, isLoading } = useUser();

  const renderContent = () => {
    if (error) {
      return <div>Error: {error}</div>;
    }

    if (isLoading || !user) {
      return <OutletLoadingScreen />;
    }

    if (user) {
      return (
        <div>
          <ContextBar
            activeRoute={[
              { name: "Profile", shortName: "Profile", href: "/profile" },
            ]}
          />
          {/* <Actions>
            <Action label="Settings" onClick={() => console.log("Settings")} />
          </Actions> */}
          <div className={styles.Content}>
            <div className={styles.Profile}>
              <div className={styles.InfoWrapper}>
                <dl>
                  <dt>Username</dt>
                  <dd>{user.username}</dd>
                </dl>
                <dl>
                  <dt>Company</dt>
                  <dd>{user.company}</dd>
                </dl>
                <dl>
                  <dt>Email</dt>
                  <dd>{user.email}</dd>
                </dl>
                <dl>
                  <dt>Account type</dt>
                  <dd>{user.account_type}</dd>
                </dl>
                <dl>
                  <dt>User ID</dt>
                  <dd>{user.uid}</dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };
  return <>{renderContent()}</>;
}
