// create context for the project selection view including
import { LoadingState } from "data/enums/LoadingState";
import { createContext, useContext } from "react";
import { AlertState } from "data/muiTypes/alert";
import { BlastWithoutSiteContext } from "data/Elevat3dApiTypes/BlastTypes";

// ------- TYPES -------

// ------- STATE -------

export type AccountViewStateType = {
  showLoaderOverlay: boolean;
  alertState: AlertState;
  localFavouriteBlasts: BlastWithoutSiteContext[];
  localRecentBlasts: BlastWithoutSiteContext[];
  isFavouritesLoading: boolean;
};

// ------- ACTIONS -------

export enum AccountViewActions {
  UPDATE_LOADER_OVERLAY_STATE,
  UPDATE_ALERT_STATE,
  UPDATE_LOCAL_FAVOURITE_BLASTS,
  UPDATE_LOCAL_RECENT_BLASTS,
  UPDATE_IS_FAVOURITES_LOADING,
}

interface UpdateShowLoaderOverlay {
  type: typeof AccountViewActions.UPDATE_LOADER_OVERLAY_STATE;
  payload: { showLoaderOverlay: boolean };
}

interface UpdateAlertState {
  type: typeof AccountViewActions.UPDATE_ALERT_STATE;
  payload: { alertState: AlertState };
}

interface UpdateLocalFavouriteBlasts {
  type: typeof AccountViewActions.UPDATE_LOCAL_FAVOURITE_BLASTS;
  payload: { localFavouriteBlasts: BlastWithoutSiteContext[] };
}

interface UpdateLocalRecentBlasts {
  type: typeof AccountViewActions.UPDATE_LOCAL_RECENT_BLASTS;
  payload: { localRecentBlasts: BlastWithoutSiteContext[] };
}

interface UpdateIsFavouritesLoading {
  type: typeof AccountViewActions.UPDATE_IS_FAVOURITES_LOADING;
  payload: { isLoading: boolean };
}

type AccountViewActionTypes =
  | UpdateShowLoaderOverlay
  | UpdateAlertState
  | UpdateLocalFavouriteBlasts
  | UpdateLocalRecentBlasts
  | UpdateIsFavouritesLoading;

// ------- ACTION CREATORS -------

export const updateShowLoaderOverlay = (
  showLoaderOverlay: boolean
): UpdateShowLoaderOverlay => ({
  type: AccountViewActions.UPDATE_LOADER_OVERLAY_STATE,
  payload: { showLoaderOverlay },
});

export const updateAlertState = (alertState: AlertState): UpdateAlertState => ({
  type: AccountViewActions.UPDATE_ALERT_STATE,
  payload: { alertState },
});

export const updateLocalFavouriteBlasts = (
  localFavouriteBlasts: BlastWithoutSiteContext[]
): UpdateLocalFavouriteBlasts => ({
  type: AccountViewActions.UPDATE_LOCAL_FAVOURITE_BLASTS,
  payload: { localFavouriteBlasts: localFavouriteBlasts },
});

export const updateLocalRecentBlasts = (
  localRecentBlasts: BlastWithoutSiteContext[]
): UpdateLocalRecentBlasts => ({
  type: AccountViewActions.UPDATE_LOCAL_RECENT_BLASTS,
  payload: { localRecentBlasts: localRecentBlasts },
});

export const updateIsFavouritesLoading = (
  isLoading: boolean
): UpdateIsFavouritesLoading => ({
  type: AccountViewActions.UPDATE_IS_FAVOURITES_LOADING,
  payload: { isLoading },
});

// ------- REDUCER -------

export const initialState: AccountViewStateType = {
  showLoaderOverlay: false,
  alertState: { open: false, message: "", severity: "success" },
  localFavouriteBlasts: [],
  localRecentBlasts: [],
  isFavouritesLoading: true
};

export const reducer = (
  state = initialState,
  action: AccountViewActionTypes
): AccountViewStateType => {
  switch (action.type) {
    case AccountViewActions.UPDATE_LOADER_OVERLAY_STATE: {
      return {
        ...state,
        showLoaderOverlay: action.payload.showLoaderOverlay,
      };
    }
    case AccountViewActions.UPDATE_ALERT_STATE: {
      return {
        ...state,
        alertState: action.payload.alertState,
      };
    }
    case AccountViewActions.UPDATE_LOCAL_FAVOURITE_BLASTS: {
      return {
        ...state,
        localFavouriteBlasts: action.payload.localFavouriteBlasts,
      };
    }
    case AccountViewActions.UPDATE_LOCAL_RECENT_BLASTS: {
      return {
        ...state,
        localRecentBlasts: action.payload.localRecentBlasts,
      };
    }
    case AccountViewActions.UPDATE_IS_FAVOURITES_LOADING: {
      return {
        ...state,
        isFavouritesLoading: action.payload.isLoading,
      };
    }
    default: {
      return state;
    }
  }
};

// ------- CONTEXT -------

export type Dispatch = (action: AccountViewActionTypes) => void;

export const AccountViewContext = createContext<{
  state: AccountViewStateType;
  dispatch: Dispatch;
} | null>(null);

export const useAccountViewDispatch = (): Dispatch => {
  const { dispatch } = useContext(AccountViewContext);
  return dispatch;
};

export const useAccountViewContextState = (): AccountViewStateType => {
  const { state } = useContext(AccountViewContext);
  return state;
};
