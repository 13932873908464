import { GeneralActionTypes, GeneralState } from "./types";
import { Actions } from "../Actions";
import { CustomCursorStyle } from "../../data/enums/CustomCursorStyle";
import { ViewPointSettings } from "../../settings/ViewPointSettings";
import { LoadingState } from "../../data/enums/LoadingState";
import { Settings } from "settings/Settings";
import { CursorMode } from "@data/enums/CursorMode";
import { EditorModel } from "staticModels/EditorModel";

const initialState: GeneralState = {
  windowSize: null,
  editorSize: null,
  leftBarOpen: true,
  rightBarOpen: true,
  activePopupType: null,
  cursorMode: CursorMode.CROSSHAIR,
  customCursorStyle: CustomCursorStyle.DEFAULT,
  activeContext: null,
  preventCustomCursor: false,
  enablePerClassColoration: true,
  zoom: ViewPointSettings.MIN_ZOOM,
  appLoadingState: LoadingState.IDLE,
};

export function generalReducer(
  state = initialState,
  action: GeneralActionTypes
): GeneralState {
  switch (action.type) {
    case Actions.UPDATE_WINDOW_SIZE: {
      return {
        ...state,
        windowSize: action.payload.windowSize,
      };
    }
    case Actions.UPDATE_EDITOR_SIZE: {
      return {
        ...state,
        editorSize: action.payload.editorSize,
      };
    }
    case Actions.UPDATE_LEFT_BAR_OPEN: {
      let size: { width: number; height: number } | null;
      if (state.windowSize) {
        const leftBarWidth = state.leftBarOpen
          ? Settings.SIDE_NAVIGATION_BAR_WIDTH_OPEN_PX
          : Settings.SIDE_NAVIGATION_BAR_WIDTH_CLOSED_PX;
        const rightBarWidth = state.rightBarOpen
          ? Settings.SIDE_NAVIGATION_BAR_WIDTH_OPEN_PX
          : Settings.SIDE_NAVIGATION_BAR_WIDTH_CLOSED_PX;
        size = {
          width: state.windowSize.width - leftBarWidth - rightBarWidth,
          height:
            state.windowSize.height -
            Settings.TOP_NAVIGATION_BAR_HEIGHT_PX -
            Settings.EDITOR_BOTTOM_NAVIGATION_BAR_HEIGHT_PX -
            Settings.EDITOR_TOP_NAVIGATION_BAR_HEIGHT_PX,
        };
      } else size = null;
      return {
        ...state,
        leftBarOpen: action.payload.open,
        editorSize: size,
      };
    }
    case Actions.UPDATE_RIGHT_BAR_OPEN: {
      let size: { width: number; height: number } | null;
      if (state.windowSize) {
        const leftBarWidth = state.leftBarOpen
          ? Settings.SIDE_NAVIGATION_BAR_WIDTH_OPEN_PX
          : Settings.SIDE_NAVIGATION_BAR_WIDTH_CLOSED_PX;
        const rightBarWidth = state.rightBarOpen
          ? Settings.SIDE_NAVIGATION_BAR_WIDTH_OPEN_PX
          : Settings.SIDE_NAVIGATION_BAR_WIDTH_CLOSED_PX;
        size = {
          width: state.windowSize.width - leftBarWidth - rightBarWidth,
          height:
            state.windowSize.height -
            Settings.TOP_NAVIGATION_BAR_HEIGHT_PX -
            Settings.EDITOR_BOTTOM_NAVIGATION_BAR_HEIGHT_PX -
            Settings.EDITOR_TOP_NAVIGATION_BAR_HEIGHT_PX,
        };
      } else size = null;
      return {
        ...state,
        rightBarOpen: action.payload.open,
        editorSize: size,
      };
    }

    case Actions.UPDATE_ACTIVE_POPUP_TYPE: {
      return {
        ...state,
        activePopupType: action.payload.activePopupType,
      };
    }
    case Actions.UPDATE_CUSTOM_CURSOR_STYLE: {
      return {
        ...state,
        customCursorStyle: action.payload.customCursorStyle,
      };
    }
    case Actions.UPDATE_CONTEXT: {
      return {
        ...state,
        activeContext: action.payload.activeContext,
      };
    }
    case Actions.UPDATE_PREVENT_CUSTOM_CURSOR_STATUS: {
      return {
        ...state,
        preventCustomCursor: action.payload.preventCustomCursor,
      };
    }
    case Actions.UPDATE_CURSOR_MODE: {
      return {
        ...state,
        cursorMode: action.payload.cursorMode,
      };
    }

    case Actions.UPDATE_ZOOM: {
      return {
        ...state,
        zoom: action.payload.zoom,
      };
    }
    case Actions.UPDATE_ENABLE_PER_CLASS_COLORATION_STATUS: {
      return {
        ...state,
        enablePerClassColoration: action.payload.enablePerClassColoration,
      };
    }

    case Actions.UPDATE_APP_LOADING_STATE: {
      if (EditorModel.cursor) {
        if (action.payload.newLoadingState === LoadingState.LOADING) {
          EditorModel.cursor.style.display = "none";
        } else {
          EditorModel.cursor.style.display = "visible";
        }
      }
      return {
        ...state,
        preventCustomCursor:
          action.payload.newLoadingState === LoadingState.LOADING
            ? true
            : false,
        appLoadingState: action.payload.newLoadingState,
      };
    }


    default:
      return state;
  }
}
