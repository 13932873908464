import Elevat3dApi  from "./Elevat3dApi";
import { Blast } from "data/Elevat3dApiTypes/BlastTypes";

export default class BlastsApi extends Elevat3dApi {
  public static async getBlasts(siteId: string) {
    const url = `sites/${siteId}/blasts/`;
    return await this.get(url);
  }

  public static async viewProject(siteId, blastId) {
    const url = `/sites/${siteId}/blasts/${blastId}/view/`;
    return await this.get(url);
  }
  public static async createBlast(params: {
    siteId: string;
    blockName: string;
    blastDate: string;
  }) {
    const { siteId, blockName, blastDate } = params;
    try {
      const response = await this.post(`/sites/${siteId}/blasts/`, {
        block_name: blockName,
        blast_date: blastDate,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async getBlast(params: { siteId: string; blastId: string }) {
    try {
      return (await this.get(
        `/sites/${params.siteId}/blasts/${params.blastId}/`
      )) as Blast;
    } catch (error) {
      throw error;
    }
  }

  public static async updateBlast(params: {
    blockName?: string;
    blastDate?: string;
    blastId: string;
    siteId: string;
  }) {
    const { blockName, blastDate } = params;
    try {
      const response = await this.patch(
        `/sites/${params.siteId}/blasts/${params.blastId}/`,
        {
          ...(blockName && { block_name: blockName }),
          ...(blastDate && { blast_date: blastDate }),
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  public static async deleteBlast(params: { blastId: string; siteId: string }) {
    try {
      const response = await this.delete(
        `/sites/${params.siteId}/blasts/${params.blastId}/`
      );

      return response;
    } catch (error) {
      throw error;
    }
  }

  /**
   * The value of favourite as passed in is the favourite value that should be updated to
   * @param params: blastId, siteId, favourite
   */
  public static async favouriteBlast(params: {
    siteId: string;
    blastId: string;
    favourite: boolean;
  }) {
    const { siteId, blastId, favourite } = params;
    try {
      const response = await this.patch(
        `/sites/${siteId}/blasts/${blastId}/favourite/`,
        {
          favourite: favourite,
        }
      );
      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }
}
