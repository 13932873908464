import styles from "./BlastSettingsView.module.scss";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { useBlastViewContext } from "../BlastView";
import ContextBar from "views/AccountView/ContextBar/ContextBar";
import OutletLoadingScreen from "views/AccountView/OutletLoadingScreen/OutletLoadingScreen";
import { TextField } from "@mui/material";
import { TextButton } from "views/Common/TextButton/TextButton";
import { useState, useEffect } from "react";
import BlastsApi from "@api/elevat3dApi/BlastsApi";
import {
  useAccountViewDispatch,
  updateAlertState,
  updateLocalFavouriteBlasts,
  useAccountViewContextState,
} from "views/AccountView/accountViewContext";
import { Settings } from "settings/Settings";
import DateInput from "views/Common/DateInput/DateInput";

const commonProps = {
  variant: "outlined" as "outlined",
  className: "MuiInput",
  // make border color white
  InputProps: {
    style: {
      color: "white",
      borderColor: "white",
    },
  },
  InputLabelProps: {
    style: {
      color: "white",
    },
  },
  inputProps: {
    style: {
      padding: "10px",
      width: "200px",
    },
  },
  autoComplete: "off",
  sx: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#4F4F4F",
        transition: ".1s",
      },
      "&:hover fieldset": {
        borderColor: "#4F4F4F",
      },
      "&.Mui-focused fieldset": {
        borderColor: Settings.FOCUS_BORDER_COLOR,
      },
    },
  },
};

export default function BlastSettingsView() {
  const { siteId, blastId } = useParams<{ siteId: string; blastId: string }>();
  const dispatch = useAccountViewDispatch();
  const { localFavouriteBlasts } = useAccountViewContextState();
  const navigate = useNavigate();
  const location = useLocation();
  const { data, dataIsLoading, refreshData } = useBlastViewContext();
  const [blastDetails, setBlastDetails] = useState<{
    blockName: string;
    blastDate: string;
  }>({
    blockName: "",
    blastDate: new Date().toISOString(),
  });
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState<{
    blockName: boolean;
    blastDate: boolean;
  }>({ blockName: false, blastDate: false });

  useEffect(() => {
    if (data) {
      setBlastDetails({
        blockName: data.blast!.block_name,
        blastDate: data.blast!.blast_date,
      });
    }
  }, [data]);

  const handleUpdate = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (blastDetails.blockName === "") {
      setError({ ...error, blockName: true });
      return;
    }

    setIsUpdating(true);
    try {
      await BlastsApi.updateBlast({
        blastId,
        siteId,
        blockName: blastDetails.blockName,
        blastDate: blastDetails.blastDate,
      });
      dispatch(
        updateLocalFavouriteBlasts(
          localFavouriteBlasts.map((favouriteBlast) => {
            if (favouriteBlast.blast_id === blastId) {
              return {
                ...favouriteBlast,
                block_name: blastDetails.blockName,
                blast_date: blastDetails.blastDate,
              };
            } else {
              return favouriteBlast;
            }
          })
        )
      );
      dispatch(
        updateAlertState({
          open: true,
          message: "Blast updated successfully",
          severity: "success",
        })
      );
      refreshData();
      navigate(`/sites/${siteId}/blasts/${blastId}`);
    } catch (error) {
      dispatch(
        updateAlertState({
          open: true,
          message: "Something went wrong. Please try again later.",
          severity: "error",
        })
      );
    }
    setIsUpdating(false);
  };

  const renderContent = () => {
    if (!data || dataIsLoading) {
      return <OutletLoadingScreen />;
    }

    const { site, blast } = data;

    return (
      <div>
        <ContextBar
          activeRoute={[
            { name: "Sites", href: "/sites", shortName: "Sites" },
            {
              name: site.site_name,
              href: `/sites/${siteId}`,
              shortName: site.site_name,
            },
            {
              name: "Blasts",
              href: `/sites/${siteId}/blasts`,
              shortName: "Blasts",
            },
            {
              name: blast.block_name,
              href: `/sites/${siteId}/blasts/${blastId}`,
              shortName: blast.block_name,
            },
            {
              name: "Settings",
              href: location.pathname,
              shortName: "Settings",
            },
          ]}
        />
        <div className={styles.Content}>
          <form onSubmit={handleUpdate} className={styles.FormGroupWrapper}>
            <div className={styles.FormGroup}>
              <label className={styles.Label} htmlFor="site-name">
                Site name
              </label>
              <TextField
                {...commonProps}
                id="site-name"
                value={blastDetails.blockName}
                onChange={(e) => {
                  setError({ ...error, blockName: e.target.value === "" });
                  setBlastDetails({
                    ...blastDetails,
                    blockName: e.target.value,
                  });
                }}
              />
              {error.blockName && (
                <p className={styles.ErrorText}>Block name can't be empty.</p>
              )}
            </div>
            <div className={styles.FormGroup}>
              <DateInput
                label="Date of blast"
                id="blast-date"
                onChange={(date: string) =>
                  setBlastDetails({
                    ...blastDetails,
                    blastDate: date,
                  })
                }
                value={blastDetails.blastDate}
              />
            </div>
            <div className={styles.Actions}>
              <TextButton
                isDisabled={isUpdating}
                type="submit"
                isLoading={isUpdating}
                onClick={handleUpdate}
                label="Update blast"
                buttonTheme="update"
              />
              <TextButton
                onClick={() =>
                  navigate("/sites/" + siteId + "/blasts/" + blastId)
                }
                buttonTheme="cancel"
                label="Cancel"
              />
            </div>
          </form>
        </div>
      </div>
    );
  };

  return <div>{renderContent()}</div>;
}
