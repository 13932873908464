import { storage } from "modules/firebaseModules";
import {
  ref,
  getDownloadURL,
  uploadBytes,
  getMetadata,
  updateMetadata,
} from "firebase/storage";

export async function fileExists(endpoint: string) {
  const storageRef = ref(storage, endpoint);
  return getMetadata(storageRef)
    .then(() => true)
    .catch((error) => {
      if (error.code === "storage/object-not-found") {
        // File doesn't exist
        return false;
      } else {
        // Some other error occurred
        throw error;
      }
    });
}

/**
 *
 * @param siteId
 * @param blastId
 * @param imageId
 * @returns the file downloaded from storage. The object is a File.
 */
export async function downloadFile(endpoint: string, name?: string) {
  if (!(await fileExists(endpoint))) {
    throw new Error("File does not exist");
  }

  const storageRef = ref(storage, endpoint);

  await updateMetadata(storageRef, {
    cacheControl: "no-cache",
  });

  try {
    const url = await getDownloadURL(storageRef);
    const response = await fetch(url);
    const blob = await response.blob();
    const file = new File([blob], name || "file");
    return file;
  } catch (error) {
    throw error;
  }
}

export async function uploadFile(file: File, endpoint: string) {
  const storageRef = ref(storage, endpoint);
  try {
    await uploadBytes(storageRef, file);
  } catch (error) {
    throw error;
  }
}
