import { auth } from "modules/firebaseModules";
import AccountCircle from "@mui/icons-material/AccountCircle";
import DownArrow from "@mui/icons-material/KeyboardArrowDown";
import LogoutIcon from "@mui/icons-material/Logout";
import { Popover } from "@mui/material";
import styles from "./AccountIconContainer.module.scss";
import { useState } from "react";
import AuthActions from "api/elevat3dApi/auth";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

const AccountIconWrapper = () => {
  return (
    <div className={styles.AccountIconWrapper}>
      {auth.currentUser && auth.currentUser.photoURL ? (
        <img
          className={styles.IconImage}
          src={auth.currentUser.photoURL}
          alt="Account icon"
          draggable={false}
        />
      ) : (
        <AccountCircle className={styles.Icon} />
      )}
    </div>
  );
};

interface IProps {}

const Account = ({}: IProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();

  const logout = () => {
    AuthActions.signOut();
    navigate("/auth/login");
  };

  const goToProfile = () => {
    setOpen(false);
    navigate("/profile");
  };

  const openDropDown = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const renderContent = () => {
    if (auth.currentUser) {
      return (
        <>
          <div
            onClick={openDropDown}
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            className={`${styles.AccountWrapper} ${
              hover || open ? styles.Hover : ""
            }`}
          >
            <AccountIconWrapper />
            <DownArrow className={styles.DownArrow} />
          </div>
          <Popover
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={open}
            onClose={() => setOpen(false)}
            anchorEl={anchorEl}
            sx={{ marginTop: "2px" }}
            PaperProps={{ style: { borderRadius: "2px" } }}
            transitionDuration={0}
            marginThreshold={0}
          >
            <div className={styles.DropDown}>
              <div className={styles.Top}>
                <AccountIconWrapper />
                <p>{auth.currentUser.email}</p>
              </div>
              <ul className={styles.List}>
                <li onClick={goToProfile}>
                  <AccountCircle className={styles.ListIcon} />
                  <p>Your profile</p>
                </li>
              </ul>
              <div className={styles.Divider} />
              <ul className={styles.List}>
                <li onClick={logout}>
                  <LogoutIcon className={styles.ListIcon} />
                  <p>Logout</p>
                </li>
              </ul>
            </div>
          </Popover>
        </>
      );
    } else {
      return (
        <div className={styles.AccountWrapper}>
          <AccountIconWrapper />
          <DownArrow className={styles.DownArrow} />
        </div>
      );
    }
  };

  return <>{renderContent()}</>;
};

const mapDispatchToProps = {};

export default connect(null, mapDispatchToProps)(Account);
