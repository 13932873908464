import React, { forwardRef, useState } from "react";
import styles from "./ImageUploader.module.scss";

type Props = {
  altText?: string;
  className?: string;
  file: File | null;
  onFilesChange: (files: FileList) => void;
  openFolder: () => void;
  ref: React.RefObject<HTMLInputElement>;
};

const ImageUploader = forwardRef<HTMLInputElement, Props>(
  function ImageUploader(
    { file, onFilesChange, altText, className, openFolder },
    ref
  ) {
    const [draggingOver, setDraggingOver] = useState(false);

    const handleDragover = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      setDraggingOver(true);
    };

    const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      setDraggingOver(false);
    };
    const handleDrop = (e) => {
      e.preventDefault();
      setDraggingOver(false);
      const uploadedFiles = e.dataTransfer.files;
      if (uploadedFiles && uploadedFiles.length > 0) {
        onFilesChange(uploadedFiles);
      }
    };

    const getImageUrl = () => {
      if (!file) return null;
      const url = URL.createObjectURL(file);
      return url;
    };

    const getDropzoneContent = () => {
      if (!file) {
        return (
          <>
            <img
            className={styles.placeholderImage}
              draggable={false}
              alt={"upload"}
              src={"/ico/box-opened.png"}
              style={{ filter: "invert(100%)" }}
            />
            <span className={styles.extraBold}>Drop images</span>
            <span>or</span>
            <span className={styles.extraBold}>Click here to browse files</span>
          </>
        );
      }

      return (
        <>
          <img
            alt={altText}
            src={getImageUrl()}
            className={styles.imagePreview}
          />
          <div className={styles.overlay} />
        </>
      );
    };

    return (
      <div
        onClick={openFolder}
        onDrop={handleDrop}
        onDragOver={handleDragover}
        onDragLeave={handleDragLeave}
        className={`${styles.dropzone} ${
          draggingOver ? styles.draggingOver : ""
        } ${file ? styles.fileUploaded : ""}`}
      >
        {getDropzoneContent()}
        <input
          ref={ref}
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          onChange={(e) => onFilesChange(e.target.files)}
        />
      </div>
    );
  }
);

export default ImageUploader;
