import "./LoaderOverlay.scss";
import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

const LoaderOverlay: React.FC = () => {
  return (
    <div onClick={e => {e.stopPropagation()}} className="loader-overlay">
      <div className="inner">
        <CircularProgress />
        <div className="msg">Loading...</div>
      </div>
    </div>
  );
};

export default LoaderOverlay;
