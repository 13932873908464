import {
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { AppState } from "@store/index";
import { PreblastSteps } from "@store/preblast/types";
import classNames from "classnames";
import styles from "./CustomStepper.module.scss";
import "./CustomStepper.scss";
import { StepsDataMap, StepsState } from "@data/info/project/Step";

type Props<T extends number> = {
  stepsData: StepsDataMap<T>;
  stepsState: StepsState<T>;
  activeStep: number;
  onStepClick: (step: T) => void;
};

export default function CustomStepper<T extends number>({
  stepsData,
  activeStep,
  onStepClick,
  stepsState,
}: Props<T>) {
  const getStepClassName = (step: PreblastSteps) => {
    return classNames(styles.step, {
      accessible: stepsState[step].accessible,
      complete: stepsState[step].complete,
      active: activeStep === step,
    });
  };

  return (
    <div className={styles.RightBarContent}>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        className={`${styles.stepper} CustomStepper`}
      >
        {Object.keys(stepsData).map((step) => {
          const label = stepsData[step].label;
          const description = stepsData[step].description;
          const stepInt = parseInt(step);
          return (
            <Step
              key={step}
              onClick={() => onStepClick(stepInt as T)}
              className={getStepClassName(stepInt)}
              active={activeStep === stepInt}
              completed={stepsState[stepInt].complete}
            >
              <StepLabel className={styles.label}>
                <span className={styles.text}>{label}</span>
              </StepLabel>
              <StepContent>
                <Typography className={styles.description}>
                  {description}
                </Typography>
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    </div>
  );
}

const mapDispatchToProps = {};

const mapStateToProps = (state: AppState) => ({});

