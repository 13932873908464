import classNames from "classnames";
import styles from "./LeftNavigationBar.module.scss";
import {  useParams, useLocation } from "react-router-dom";

import { AppState } from "@store/index";
import { connect } from "react-redux";
import BlastOverviewLeftNavigatioBarContent from "./LeftNavigationBarContent/LeftNavigationBarContent";

interface IProps {
  isOpen: boolean;
  onBorderDoubleClick: () => void;
}

function LeftNavigationBar({ isOpen, onBorderDoubleClick }: IProps) {
  const { siteId, blastId } = useParams<{ siteId: string; blastId: string }>();
  const getClassName = () => {
    return classNames(styles.leftNavigationBar, {
      [styles.closed]: !isOpen,
    });
  };

  const activeRoute = useLocation().pathname.split("/").slice(-1)[0];

  const renderContent = () => {
    switch (activeRoute) {
      // case blastId: {
      //   return <BlastOverviewLeftNavigatioBarContent />;
      // }
      // case "pre": {
      //   return <PreblastLeftNavigationBarContent />;
      // }
      default: {
        return <BlastOverviewLeftNavigatioBarContent />;
      }
    }
  };

  return (
    <div className={getClassName()}>
      {isOpen && <div className={styles.contentWrapper}>{renderContent()}</div>}
      {!isOpen && <div className={styles.closedBar} />}
      <div
        className={styles.draggableBorder}
        onDoubleClick={onBorderDoubleClick}
      />
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({});

export default connect(mapStateToProps, null)(LeftNavigationBar);
