import {
  updateActivePopup,
  updateActivePreblastStep,
  updateBlastParameters,
} from "@store/preblast/actionCreators";
import GenericPreblastPopup from "../PreblastPopupView/GenericPreblastPopup/GenericPreblastPopup";
import { connect } from "react-redux";
import { BlastParameters, PreblastSteps } from "@store/preblast/types";
import { AppState } from "@store/index";
import styles from "./BlastParametersDialog.module.scss";
import { BlastPattern } from "@data/enums/BlastPattern";
import React, { useState } from "react";
import { BlastDetails } from "@store/blast/types";
import { updateAppLoadingState } from "@store/general/actionCreators";

function isFloat(text: string) {
  return !isNaN(Number(text));
}

type Props = {
  updateActivePreblastPopup: typeof updateActivePopup;
  updateActivePreblastStep: typeof updateActivePreblastStep;
  updateBlastParameters: typeof updateBlastParameters;
  updateAppLoadingState: typeof updateAppLoadingState;
  blastParameters: BlastParameters;
  blastDetails: BlastDetails;
};

function BlastParametersDialog({
  updateActivePreblastStep,
  updateBlastParameters,
  blastParameters,
}: Props) {
  const [burden, setBurden] = useState(
    blastParameters?.burden ? blastParameters.burden.toString() : ""
  );
  const [spacing, setSpacing] = useState(
    blastParameters?.spacing ? blastParameters.spacing.toString() : ""
  );

  const onContinue = async () => {
    updateActivePreblastStep(PreblastSteps.DRILLMAP);
  };

  const onGoBack = () => {
    updateActivePreblastStep(PreblastSteps.SCALE_POINTS);
  };

  const onBurdenChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // validate floating point input
    if (!isFloat(e.target.value)) {
      return;
    }
    setBurden(e.target.value);
    updateBlastParameters({ ...blastParameters, burden: +e.target.value });
  };

  const onSpacingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isFloat(e.target.value)) {
      return;
    }
    setSpacing(e.target.value);
    updateBlastParameters({
      ...blastParameters,
      spacing: +e.target.value,
    });
  };

  const onBlastPatternChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateBlastParameters({
      ...blastParameters,
      blastPattern: e.target.value as BlastPattern,
    });
  };

  return (
    <GenericPreblastPopup
      acceptLabel={"Continue"}
      rejectLabel={"Back"}
      onAccept={onContinue}
      onReject={onGoBack}
      title="Blast Parameters"
      isAcceptDisabled={
        !(blastParameters.spacing > 0 && blastParameters.burden > 0)
      }
    >
      <div>
        <div className={`${styles.popupContent} blast-parameters-dialog`}>
          <div className={styles.inputWrapper}>
            <label htmlFor="burden" inputMode="numeric">
              Burden (m)
            </label>
            <input onChange={onBurdenChange} value={burden} />
          </div>
          <div className={styles.inputWrapper}>
            <label htmlFor="spacing">Spacing (m)</label>
            <input
              onChange={onSpacingChange}
              inputMode="numeric"
              value={spacing}
            />
          </div>
          <div className={styles.inputWrapper}>
            <label htmlFor="blast-pattern">Blast pattern</label>
            <select
              onChange={onBlastPatternChange}
              value={blastParameters.blastPattern}
              id="blast-pattern"
            >
              <option value={BlastPattern.BOX}>box</option>
              <option value={BlastPattern.STAGGERED}>staggered</option>
            </select>
          </div>
        </div>
      </div>
    </GenericPreblastPopup>
  );
}

const mapDispatchToProps = {
  updateActivePreblastPopup: updateActivePopup,
  updateActivePreblastStep: updateActivePreblastStep,
  updateBlastParameters: updateBlastParameters,
  updateAppLoadingState: updateAppLoadingState,
};

const mapStateToProps = (state: AppState) => ({
  blastParameters: state.preblast.blastParameters,
  blastDetails: state.blast.blastDetails,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlastParametersDialog);
