import styles from "./LeftBar.module.scss";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SiteIcon from "@mui/icons-material/AccountTree";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { useSites } from "modules/hooksAndHOCs/swrApiHooks";

type Props = {
  isActive: boolean;
  onClick: () => void;
};
export default function SitesTab({ isActive, onClick }: Props) {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const navigate = useNavigate();
  const { data: sites } = useSites();
  return (
    <div className={`${styles.ExpandableListWrapper}`}>
      <Link
        className={`${styles.Link} ${isActive ? styles.Active : ""}`}
        onClick={(e) => {
          // e.preventDefault();
          onClick();
        }}
        onDoubleClick={() => navigate("/sites")}
        to={"/sites"}
      >
        <div className={styles.ListHeader}>
          <SiteIcon className={styles.Icon} />
          <h2 className={styles.Text}>Sites</h2>
          {/* <div
            onClick={(e) => {
              e.preventDefault();
              setDropdownIsOpen(!dropdownIsOpen);
            }}
            className={`${styles.DropdownArrowWrapper} ${
              dropdownIsOpen ? styles.Open : ""
            }`}
          >
            <ArrowRightIcon className={`${styles.Icon}`} />
          </div> */}
        </div>
      </Link>

      <ul className={`${styles.List} ${dropdownIsOpen ? styles.Open : ""}`}>
        <li>No sites yet</li>
      </ul>
    </div>
  );
}
