import { uploadFile, downloadFile } from "./FirebaseStorageUtil";

export default class PreblastImageStorageUtil {
  static async uploadImage(
    file: File,
    fileName: string,
    blastDetails: { siteId: string; blastId: string }
  ) {
    const endpoint = `drillmap/${blastDetails.siteId}/${blastDetails.blastId}/${fileName}`;
    try {
      await uploadFile(file, endpoint);
    } catch (error) {
      throw error;
    }
  }

  static async uploadPlainImage(
    file: File,
    blastDetails: { blastId: string; siteId: string }
  ) {
    try {
      await this.uploadImage(file, "1", blastDetails);
    } catch (error) {
      throw error;
    }
  }

  static async uploadPreblastDrillmap(
    file: File,
    blastDetails: { blastId: string; siteId: string }
  ) {
    try {
      await this.uploadImage(file, "drill_graph.png", blastDetails);
    } catch (error) {
      throw error;
    }
  }

  static async downloadPreblastReport(
    reportName: string,
    blastDetails: { blastId: string; siteId: string }
  ) {
    try {
      const file = await downloadFile(
        `drillmap/${blastDetails.siteId}/${blastDetails.blastId}/${reportName}`
      );
      return file;
    } catch (error) {
      throw error;
    }
  }
}
