import {
  INotification,
  NotificationsActionType,
  NotificationsState,
} from "./types";
import { Actions } from "../Actions";

const initialState: NotificationsState = {
  queue: [],
};

export function notificationsReducer(
  state = initialState,
  action: NotificationsActionType
): NotificationsState {
  switch (action.type) {
    case Actions.SUBMIT_NEW_NOTIFICATION: {
      return {
        ...state,
        queue: [...state.queue, action.payload.notification],
      };
    }
    case Actions.DELETE_NOTIFICATION_BY_ID: {
      return {
        ...state,
        queue: state.queue.filter(
          (message: INotification) => message.id !== action.payload.id
        ),
      };
    }
    default:
      return state;
  }
}
