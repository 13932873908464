import styles from "./TopNavigationBar.module.scss";
import LogoDropdown from "./LogoDropdown/LogoDropdown";
import BlastPath from "./BlastPath/BlastPath";

export default function TopNavigationBar() {
  return (
    <div className={styles.TopNavigationBar}>
      {/* <StateBar /> */}
      <div className={styles.TopNavigationBarWrapper}>
        <div className={styles.NavigationBarGroupWrapper}>
          <LogoDropdown />
        </div>
        <div className={`${styles.NavigationBarGroupWrapper} ${styles.middle}`}>
          <BlastPath />
        </div>
        <div className={styles.NavigationBarGroupWrapper}></div>
      </div>
    </div>
  );
}
