/**
 * Actions wrapper at the top of table.
 */

import styles from "./Actions.module.scss";

type ActionProps = {
  onClick: () => void;
  label: string;
  special?: boolean;
  Icon?: any; // mui icon 
};

export function Action({ onClick, label, Icon, special = false }: ActionProps) {
  return (
    <div
      onClick={onClick}
      className={`${styles.Action} ${special ? styles.Special : ""}`}
    >
      <p className={styles.Text}>{label}</p>
      {Icon && <Icon className={styles.Icon} />}
    </div>
  );
}

type ActionsProps = {
  children: React.ReactNode;
  className?: string;
};

export default function Actions({
  children,
  className: externalClassName,
}: ActionsProps) {
  return (
    <div
      className={`${styles.ActionsWrapper} ${styles.ShowScrolline} ${
        externalClassName ? externalClassName : ""
      }`}
    >
      {children}
    </div>
  );
}
