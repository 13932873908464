import { auth } from "@modules/firebaseModules";

export default class Elevat3dApi {
  static readonly apiRoot =
    process.env.REACT_APP_NODE_ENV === "development"
      ? process.env.REACT_APP_API_BASE_URL
      : "https://elevat3d-api-yuyp64pc6a-ew.a.run.app";
  public static apiVersion = "/api/v1";

  static async request(
    endpoint: string,
    method: "GET" | "POST" | "PUT" | "PATCH" | "DELETE",
    requestBody?: { [key: string]: any } | null,
    headers?: { [key: string]: any }
  ) {
    try {
      let url = this.apiRoot + this.apiVersion + endpoint;
      if (url.includes("detection")) {
        // can't perform detection locally so use this for now
        url =
          "https://elevat3d-api-yuyp64pc6a-ew.a.run.app" +
          this.apiVersion +
          endpoint;
      }
      const response = await fetch(url, {
        method,
        ...(requestBody && { body: JSON.stringify(requestBody) }),
        headers: {
          Authorization: (await auth.currentUser.getIdToken(true)).toString(),
          "Content-Type": "application/json",
          ...headers,
        },
      });

      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }

      try {
        const result = await response.json();
        return result;
      } catch (error) {
        return response;
      }
    } catch (error) {
      console.log("Unexpected error: ", error);
      throw error;
    }
  }

  static async get(endpoint: string) {
    return await Elevat3dApi.request(endpoint, "GET");
  }

  static async post(
    endpoint: string,
    requestBody?: { [key: string]: any } | FormData
  ) {
    return await Elevat3dApi.request(endpoint, "POST", requestBody);
  }

  static async delete(endpoint: string, requestBody?: { [key: string]: any }) {
    return await Elevat3dApi.request(endpoint, "DELETE", requestBody);
  }

  static async put(endpoint: string, requestBody?: { [key: string]: any }) {
    return await Elevat3dApi.request(endpoint, "PUT", requestBody);
  }

  static async patch(endpoint: string, requestBody?: { [key: string]: any }) {
    return await Elevat3dApi.request(endpoint, "PATCH", requestBody);
  }

  static async protectedFetcher(url: string) {
    return await Elevat3dApi.get(url);
  }
}
