import { ISize } from "../../interfaces/ISize";
import { GeneralActionTypes } from "./types";
import { Actions } from "../Actions";
import { PopupWindowType } from "../../data/enums/PopupWindowType";
import { CustomCursorStyle } from "../../data/enums/CustomCursorStyle";
import { ContextType } from "../../data/enums/ContextType";
import { LoadingState } from "../../data/enums/LoadingState";
import { CursorMode } from "@data/enums/CursorMode";

export function updateWindowSize(windowSize: ISize): GeneralActionTypes {
  return {
    type: Actions.UPDATE_WINDOW_SIZE,
    payload: {
      windowSize,
    },
  };
}

export function updateEditorSize(editorSize: ISize): GeneralActionTypes {
  return {
    type: Actions.UPDATE_EDITOR_SIZE,
    payload: {
      editorSize,
    },
  };
}

export function updateLeftBarOpen(open: boolean): GeneralActionTypes {
  return {
    type: Actions.UPDATE_LEFT_BAR_OPEN,
    payload: {
      open,
    },
  };
}

export function updateRightBarOpen(open: boolean): GeneralActionTypes {
  return {
    type: Actions.UPDATE_RIGHT_BAR_OPEN,
    payload: {
      open,
    },
  };
}

export function invokeDrillMap(action: Actions): GeneralActionTypes {
  return {
    type: Actions.INVOKE_DRILLMAP,
    payload: {
      action: action,
    },
  };
}

export function updateActivePopupType(
  activePopupType: PopupWindowType
): GeneralActionTypes {
  return {
    type: Actions.UPDATE_ACTIVE_POPUP_TYPE,
    payload: {
      activePopupType,
    },
  };
}

export function updateCustomCursorStyle(
  customCursorStyle: CustomCursorStyle
): GeneralActionTypes {
  return {
    type: Actions.UPDATE_CUSTOM_CURSOR_STYLE,
    payload: {
      customCursorStyle,
    },
  };
}

export function updateActiveContext(
  activeContext: ContextType
): GeneralActionTypes {
  return {
    type: Actions.UPDATE_CONTEXT,
    payload: {
      activeContext,
    },
  };
}

export function updatePreventCustomCursorStatus(
  preventCustomCursor: boolean
): GeneralActionTypes {
  return {
    type: Actions.UPDATE_PREVENT_CUSTOM_CURSOR_STATUS,
    payload: {
      preventCustomCursor,
    },
  };
}

export function updateCursorMode(cursorMode: CursorMode): GeneralActionTypes {
  return {
    type: Actions.UPDATE_CURSOR_MODE,
    payload: {
      cursorMode,
    },
  };
}

export function updateZoom(zoom: number): GeneralActionTypes {
  return {
    type: Actions.UPDATE_ZOOM,
    payload: {
      zoom,
    },
  };
}

export function updatePerClassColorationStatus(
  enablePerClassColoration: boolean
): GeneralActionTypes {
  return {
    type: Actions.UPDATE_ENABLE_PER_CLASS_COLORATION_STATUS,
    payload: {
      enablePerClassColoration,
    },
  };
}

export function updateAppLoadingState(
  newLoadingState: LoadingState
): GeneralActionTypes {
  return {
    type: Actions.UPDATE_APP_LOADING_STATE,
    payload: { newLoadingState },
  };
}

