export enum Actions {
  // AI
  UPDATE_SUGGESTED_LABEL_LIST = "@@UPDATE_SUGGESTED_LABEL_LIST",
  UPDATE_REJECTED_SUGGESTED_LABEL_LIST = "@@UPDATE_REJECTED_SUGGESTED_LABEL_LIST",
  UPDATE_OBJECT_DETECTOR_STATUS = "@@UPDATE_OBJECT_DETECTOR_STATUS",
  UPDATE_POSE_DETECTOR_STATUS = "@@UPDATE_POSE_DETECTOR_STATUS",
  UPDATE_DISABLED_AI_FLAG = "@@UPDATE_DISABLED_AI_FLAG",

  // BLAST
  UPDATE_BLAST_DETAILS = "@@UPDATE_BLAST_DETAILS",




  // GENERAL
  UPDATE_EDITOR_SIZE = "@@UPDATE_EDITOR_SIZE",
  UPDATE_WINDOW_SIZE = "@@UPDATE_WINDOW_SIZE",
  UPDATE_LEFT_BAR_OPEN = "@@UPDATE_LEFT_BAR_OPEN",
  UPDATE_RIGHT_BAR_OPEN = "@@UPDATE_RIGHT_BAR_OPEN",
  UPDATE_ACTIVE_POPUP_TYPE = "@@UPDATE_ACTIVE_POPUP_TYPE",
  UPDATE_CUSTOM_CURSOR_STYLE = "@@UPDATE_CUSTOM_CURSOR_STYLE",
  UPDATE_CONTEXT = "@@UPDATE_CONTEXT",
  UPDATE_PREVENT_CUSTOM_CURSOR_STATUS = "@@UPDATE_PREVENT_CUSTOM_CURSOR_STATUS",
  UPDATE_CURSOR_MODE = "@@UPDATE_CURSOR_MODE",
  UPDATE_ENABLE_PER_CLASS_COLORATION_STATUS = "@@UPDATE_ENABLE_PER_CLASS_COLORATION_STATUS",
  UPDATE_ZOOM = "@@UPDATE_ZOOM",
  UPDATE_APP_LOADING_STATE = "@@UPDATE_GENERAL_LOADING_STATE",
  UPDATE_LOADING_SCREEN_STATE = "@@UPDATE_LOADING_SCREEN_STATE",

  // LABELS
  UPDATE_IMAGE_DATA_BY_ID = "@@UPDATE_IMAGE_DATA_BY_ID",
  UPDATE_IMAGE = "@@UPDATE_IMAGE",
  UPDATE_ACTIVE_LABEL_NAME_ID = "@@UPDATE_ACTIVE_LABEL_NAME_ID",
  UPDATE_ACTIVE_LABEL_TYPE = "@@UPDATE_ACTIVE_LABEL_TYPE",
  UPDATE_ACTIVE_LABEL_ID = "@@UPDATE_ACTIVE_LABEL_ID",
  UPDATE_HIGHLIGHTED_LABEL_ID = "@@UPDATE_HIGHLIGHTED_LABEL_ID",
  UPDATE_LABEL_NAMES = "@@UPDATE_LABEL_NAMES",
  UPDATE_FIRST_LABEL_CREATED_FLAG = "@@UPDATE_FIRST_LABEL_CREATED_FLAG",
  INVOKE_DRILLMAP = "@@INVOKE_DRILLMAP",
  RESET_LABELS = "@@RESET_LABELS",

  // NOTIFICATIONS
  SUBMIT_NEW_NOTIFICATION = "@@SUBMIT_NEW_NOTIFICATION",
  DELETE_NOTIFICATION_BY_ID = "@@DELETE_NOTIFICATION_BY_ID",
}
