import styles from "./LogoDropdown.module.scss";
import DownArrow from "@mui/icons-material/KeyboardArrowDown";
import { Popover, Box } from "@mui/material";
import { useState, useRef } from "react";
import { PopupWindowType } from "@data/enums/PopupWindowType";
import { updateActivePopupType } from "@store/general/actionCreators";
import { connect } from "react-redux";

interface IProps {
  updateActivePopupType: (activePopupType: PopupWindowType) => any;
}

function LogoDropdown({ updateActivePopupType }: IProps) {
  const [open, setOpen] = useState(false);
  const [hover, setHover] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const openExitPopup = () => {
    setOpen(false);
    updateActivePopupType(PopupWindowType.EXIT_PROJECT);
  };
  return (
    <>
      <div
        ref={anchorRef}
        className={`${styles.logoDropdown}  ${
          hover || open ? styles.hover : ""
        }`}
        onClick={() => setOpen(true)}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <img
          src="/elevated-drillmap-ico.png"
          alt="Logo"
          className={styles.logo}
        />
        <DownArrow className={`${styles.dropdownArrow}`} />
      </div>{" "}
      <Popover
        onClose={() => setOpen(false)}
        open={open}
        anchorEl={anchorRef.current}
        marginThreshold={5}
        // PaperProps={{ style: { borderRadius: "0", marginTop: "4px" } }}
        transitionDuration={0}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        PaperProps={{
          style: {
            backgroundColor: "transparent",
            boxShadow: "none",
            borderRadius: 0,
          },
        }}
      >
        <div className={styles.popover}>
          <Box className={styles.arrow} />
          <div className={styles.popoverContent}>
            <ul>
              <li onClick={openExitPopup}>Back to blast</li>
            </ul>
          </div>
        </div>
      </Popover>
    </>
  );
}

const mapDispatchToProps = {
  updateActivePopupType: updateActivePopupType,
};

export default connect(null, mapDispatchToProps)(LogoDropdown);
