import FirebaseAuth from "modules/firebaseModules/auth";
import Elevat3dApi from "./Elevat3dApi";

export default class AuthActions {
  static async verifyUser() {
    const response = await fetch(
      Elevat3dApi.apiRoot + "/api/v1/users/verify_session",
      {
        method: "POST",
        credentials: "include",
      }
    );
    return response;
  }

  static async signIn(email: string, password: string) {
    try {
      await FirebaseAuth.signIn(email, password);
    } catch (error) {
      throw error;
    }
  }

  static async signOut() {
    try {
      await FirebaseAuth.signOut();
    } catch (error) {
      throw error;
    }
  }
}
