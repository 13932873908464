import { ISize } from "../interfaces/ISize";

export class ImageUtil {
  public static getSize(image: HTMLImageElement): ISize {
    if (!image) return { width: 0, height: 0 };
    return {
      width: image.width,
      height: image.height,
    };
  }

  public static fixHeight(file: File, height: number) {
    const img = new Image();
    img.src = URL.createObjectURL(file);

    return new Promise((resolve, reject) => {
      img.onload = function () {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const width = (height * img.width) / img.height;
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);

        canvas.toBlob(
          (blob) => {
            const compressedFile = new File([blob], file.name, {
              type: file.type,
              lastModified: file.lastModified,
            }) as File;

            resolve(compressedFile);
          },
          file.type,
          1
        );
      };

      img.onerror = function () {
        reject(new Error("Failed to load image"));
      };
    })

  }

  public static compressToMaxSize(file: File, maxSizeInBytes: number) {
    const qualityStep = 0.1; // adjust to control quality steps
    let currentQuality = 1.0;
    let compressedFile = file;

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (event) {
        const img = new Image();
        img.src = event.target.result as string;

        img.onload = function () {
          let canvas = document.createElement("canvas");
          let ctx = canvas.getContext("2d");
          canvas.width = img.width;
          canvas.height = img.height;
          ctx.drawImage(img, 0, 0);

          const compressAndCheckSize = () => {
            canvas.toBlob(
              (blob) => {
                compressedFile = new File([blob], file.name, {
                  type: file.type,
                  lastModified: file.lastModified,
                }) as File;

                // Check if file size is below max
                if (
                  compressedFile.size <= maxSizeInBytes ||
                  currentQuality <= 0
                ) {
                  resolve(compressedFile);
                  return;
                }

                // If above max size, decrease quality and try again
                currentQuality -= qualityStep;
                canvas = document.createElement("canvas");
                ctx = canvas.getContext("2d");
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0);
                compressAndCheckSize();
              },
              file.type,
              currentQuality
            );
          };

          compressAndCheckSize();
        };

        img.onerror = function () {
          reject(new Error("Failed to load image"));
        };
      };
    });
  }
}
