import React, { useState } from "react";
import "./CustomTextInput.scss";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import FilledInput from "@mui/material/FilledInput";
import FormHelperText from "@mui/material/FormHelperText";
import classnames from "classnames";
import { InputAdornment, IconButton } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

interface IProps {
  value?: string;
  onChange?: any;
  placeholder?: string;
  id: string;
  required?: boolean;
  label?: string;
  helperText?: string;
  error?: boolean;
  className?: string;
  emptyAllowed?: boolean;
  type?: "text" | "password" | "email" | "date";
  autoComplete?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  labelOnTop?: boolean;
  fullWidth?: boolean;
}

const CustomTextInput: React.FC<IProps> = ({
  value,
  onChange,
  id,
  label,
  required = false,
  helperText,
  error,
  className,
  type = "text",
  autoComplete = false,
  onFocus,
  onBlur,
  placeholder,
  labelOnTop = false,
  fullWidth = false
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const getClassNames = () => {
    return classnames("input-field", "site-name", { error }, className);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="MuiInput">
      {labelOnTop && label && <label htmlFor={id}>{label}</label>}
      <FormControl
        className={getClassNames()}
        variant="filled"
        error={error ? true : false}
        required={required}
        fullWidth={fullWidth}
      >
        {label && <InputLabel htmlFor={id}>{label}</InputLabel>}
        <FilledInput
          autoComplete={autoComplete ? "on" : "off"}
          onChange={onChange}
          value={value}
          id={id}
          type={type}
          onFocus={onFocus}
          onBlur={onBlur}
          placeholder={placeholder}
          {...(type === "password" && {
            type: showPassword ? "text" : "password",
          })}
          {...(type === "password" && {
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={toggleShowPassword}
                >
                  {showPassword ? (
                    <Visibility sx={{ color: "white" }} />
                  ) : (
                    <VisibilityOff sx={{ color: "white" }} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          })}
        />
        {!!helperText && <FormHelperText style={{color: error ? "red" : ""}}>{helperText}</FormHelperText>}
      </FormControl>
    </div>
  );
};

export default CustomTextInput;
