import styles from "./SitesView.module.scss";
import dialogStyles from "views/Common/CustomDialog/CustomDialog.module.scss";
import CustomDialog from "views/Common/CustomDialog/CustomDialog";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import {
  useAccountViewDispatch,
  updateShowLoaderOverlay,
} from "../accountViewContext";
import SitesApi from "@api/elevat3dApi/SitesApi";
import CustomTextInput from "views/Common/CustomTextInput/CustomTextInput";
import { Site } from "data/Elevat3dApiTypes/SiteTypes";

type Props = {
  open: boolean;
  onAddSite: (newSiteId: string) => void;
  onClose: () => void;
};

export default function AddSiteDialog({ onAddSite, open, onClose }: Props) {
  const dispatch = useAccountViewDispatch();
  const [siteState, setSiteState] = useState<{
    siteName: string;
    error: boolean;
    location: string;
  }>({
    siteName: "",
    error: false,
    location: "",
  });
  const [error, setError] = useState(false);

  const handleAddSite = async () => {
    setError(false);
    if (siteState.siteName === "") {
      setSiteState({ ...siteState, error: true });
      return;
    }
    dispatch(updateShowLoaderOverlay(true));
    try {
      const newSite = (await SitesApi.createSite(
        siteState.siteName.trim(),
        siteState.location
      )) as Site;
      onAddSite(newSite.id);
      onClose();
    } catch (error) {
      setError(true);
    } finally {
      dispatch(updateShowLoaderOverlay(false));
    }
  };

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      acceptLabel="Add site"
      rejectLabel="Cancel"
      onAccept={handleAddSite}
      onReject={onClose}
      title="New site"
      Icon={AddIcon}
    >
      <div>
        <p>
          Enter the site name and (optionally) the location to add a new site.
        </p>
        <div className={styles.InputsWrapper}>
          <CustomTextInput
            onChange={(e) =>
              setSiteState({
                ...siteState,
                siteName: e.target.value,
                error: false,
              })
            }
            className={styles.Input}
            error={siteState.error}
            helperText={siteState.error ? "Site name is required" : ""}
            id="site-name"
            label="Site name"
            required={true}
          />
          <CustomTextInput
            className={styles.Input}
            onChange={(e) =>
              setSiteState({ ...siteState, location: e.target.value })
            }
            id="location"
            label="Location"
          />
        </div>
        {error && (
          <p className={dialogStyles.ErrorText}>
            Sorry, something went wrong while trying to add a new site. Please
            try again later.
          </p>
        )}
      </div>
    </CustomDialog>
  );
}
