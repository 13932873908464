import { Link, Navigate, Outlet } from "react-router-dom";
import LoadingScreen from "views/LoadingScreen/LoadingScreen";
import { auth } from "modules/firebaseModules";
import { useAuthState } from "react-firebase-hooks/auth";

export default function ProtectedRoute() {
  const [user, loading, error] = useAuthState(auth);

  if (loading) {
    return <LoadingScreen />;
  }
  if (error) {
    return (
      <div>
        Something went wrong. Press <Link to="/login">here</Link> to go to
        login.
      </div>
    );
  }

  if (!auth.currentUser) {
    return <Navigate to="/auth/login" />;
  }

  return <Outlet />;
}
