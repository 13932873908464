import useSWR from "swr";
import GenericTable, {
  Header,
  SortState,
} from "views/AccountView/GenericTable/GenericTable";
import {
  useLocation,
  useNavigate,
  Link,
  useSearchParams,
} from "react-router-dom";
import { Snackbar, Alert, AlertColor } from "@mui/material";
import ContextBar from "views/AccountView/ContextBar/ContextBar";
import Actions, { Action } from "views/AccountView/Actions/Actions";
import { useState, useEffect } from "react";
import { Blast } from "data/Elevat3dApiTypes/BlastTypes";
import tableStyles from "views/AccountView/GenericTable/GenericTable.module.scss";
import { formatDate, convertTZ } from "utils/DatetimeUtil";
import AddIcon from "@mui/icons-material/Add";
import Cookies from "js-cookie";
import AddBlastDialog from "./AddBlastDialog";
import { useSiteViewContext } from "../SiteView";
import OutletLoadingScreen from "views/AccountView/OutletLoadingScreen/OutletLoadingScreen";
import StarOutline from "@mui/icons-material/StarOutline";
import FilledStar from "@mui/icons-material/Star";
import BlastsApi from "@api/elevat3dApi/BlastsApi";

import {
  updateLocalFavouriteBlasts,
  useAccountViewContextState,
  useAccountViewDispatch,
} from "views/AccountView/accountViewContext";
import Elevat3dApi from "@api/elevat3dApi/Elevat3dApi";

const headers: Header<Blast>[] = [
  {
    name: "Block name",
    header: "block_name",
    sortable: true,
    sortDirection: "asc",
    sortFunction: (a: Blast, b: Blast) => {
      return a.block_name.localeCompare(b.block_name);
    },
  },
  {
    name: "Blast date",
    header: "blast_date",
    sortable: true,
    sortDirection: "asc",
    sortFunction: (a: Blast, b: Blast) => {
      return (
        new Date(a.blast_date).getTime() - new Date(b.blast_date).getTime()
      );
    },
  },
  {
    name: "Last viewed",
    header: "last_viewed",
    sortable: true,
    sortDirection: "asc",
    sortFunction: (a: Blast, b: Blast) => {
      return (
        new Date(a.last_viewed).getTime() - new Date(b.last_viewed).getTime()
      );
    },
  },
  {
    name: "Date created",
    header: "created_at_utc",
    sortable: true,
    sortDirection: "desc",
    sortFunction: (a: Blast, b: Blast) => {
      return (
        new Date(a.created_at_utc).getTime() -
        new Date(b.created_at_utc).getTime()
      );
    },
  },
];

function BlastsView() {
  const { site, siteIsLoading } = useSiteViewContext();
  const [addBlastDialogOpen, setAddBlastDialogOpen] = useState(false);
  const [alertState, setAlertState] = useState<{
    open: boolean;
    message: string;
    severity: AlertColor;
  }>({ open: false, message: "", severity: "success" });
  const [sortState, setSortState] = useState<SortState<Blast>>({
    header: "last_viewed" as keyof Blast,
    direction: "desc" as "asc" | "desc",
  });
  const [childKey, setChildKey] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const { localFavouriteBlasts: localFavourites } =
    useAccountViewContextState();
  const dispatch = useAccountViewDispatch();
  const {
    data: blasts,
    error,
    mutate,
  } = useSWR(
    location.pathname + "/",
    async (url) => {
      setIsLoading(true);
      return Elevat3dApi.get(url)
        .then((data) => data.blasts)
        .finally(() => setIsLoading(false));
    },
    {
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    if (searchParams.get("refresh") === "true") {
      // refresh cached data for cases like when site is deleted, to ensure data is not stale.
      searchParams.delete("refresh");
      setSearchParams(searchParams);
      mutate();
    }
  }, []);

  /**
   * The function that is called when the user clicks on the favourite icon.
   * The value for favourite as passed in to the function is the PREVIOUS value. So it still needs to be toggled.
   * @param favourite
   * @param blastId
   * @param siteId
   */
  const handleToggleFavourite = async (
    blast: Blast,
    prevFavouriteValue: boolean
  ) => {
    if (prevFavouriteValue === true) {
      dispatch(
        updateLocalFavouriteBlasts(
          localFavourites.filter(
            (otherBlast) => otherBlast.blast_id !== blast.blast_id
          )
        )
      );
    } else {
      dispatch(
        updateLocalFavouriteBlasts([
          ...localFavourites,
          { ...blast, site_id: site.id, site_name: site.site_name },
        ])
      );
    }

    BlastsApi.favouriteBlast({
      blastId: blast.blast_id,
      siteId: site.id,
      favourite: !prevFavouriteValue,
    });
  };

  const onAddBlast = (newBlastId: string) => {
    setAlertState({
      open: true,
      message: "Successfully created new blast.",
      severity: "success",
    });
    navigate(location.pathname + "/" + newBlastId);
  };

  const renderContent = () => {
    if (error) {
      return <div>There was an error</div>;
    }

    if (isLoading || !blasts || !site || siteIsLoading) {
      return <OutletLoadingScreen />;
    }

    return (
      <>
        <div>
          <ContextBar
            activeRoute={[
              { name: "Sites", shortName: "Sites", href: "/sites" },
              {
                name: site.site_name,
                shortName: site.site_name,
                href: `/sites/${site.id}`,
              },
              { name: "Blasts", shortName: "Blasts", href: location.pathname },
            ]}
          />
          <Actions>
            <Action
              Icon={AddIcon}
              onClick={() => setAddBlastDialogOpen(true)}
              label="New blast"
            />
          </Actions>
          <GenericTable<Blast>
            headers={headers}
            sortState={sortState}
            setSortState={setSortState}
          >
            {blasts
              .sort((a, b) => {
                return sortState.direction === "asc"
                  ? headers
                      .find((header) => header.header === sortState.header)
                      .sortFunction(a, b)
                  : headers
                      .find((header) => header.header === sortState.header)
                      .sortFunction(b, a);
              })
              .map((blast: Blast) => {
                const navigateToPath = `/sites/${site.id}/blasts/${blast.blast_id}`;
                const isFavourite = localFavourites.some(
                  (favouriteBlast) => favouriteBlast.blast_id === blast.blast_id
                );
                const hasLastViewed = blast.last_viewed !== null;
                const lastViewed = hasLastViewed
                  ? formatDate(
                      convertTZ(
                        new Date(blast.last_viewed),
                        Cookies.get("timezone")
                      )
                    )
                  : "Never";
                return (
                  <Link
                    to={navigateToPath}
                    className={`${tableStyles.Row}`}
                    style={{
                      gridTemplateColumns: `repeat(${headers.length}, 1fr)`,
                    }}
                    key={blast.blast_id}
                  >
                    <div className={tableStyles.Cell}>
                      <div
                        onClick={(e) => {
                          e.preventDefault();
                          handleToggleFavourite(blast, isFavourite);
                        }}
                        className={tableStyles.FavouriteWrapper}
                      >
                        {isFavourite ? (
                          <FilledStar className={tableStyles.FilledStar} />
                        ) : (
                          <StarOutline className={tableStyles.StarOutline} />
                        )}
                      </div>
                      <p className={tableStyles.Text}>{blast.block_name}</p>
                    </div>
                    <div className={`${tableStyles.Cell}`}>
                      <p className={tableStyles.Text}>
                        {formatDate(blast.blast_date)}
                      </p>
                    </div>
                    <div
                      className={`${tableStyles.Cell} ${
                        !hasLastViewed ? tableStyles.Null : ""
                      }`}
                    >
                      <p className={`${tableStyles.Text} `}>{lastViewed}</p>
                    </div>
                    <div className={`${tableStyles.Cell}`}>
                      <p className={`${tableStyles.Text} `}>
                        {formatDate(
                          convertTZ(
                            blast.created_at_utc,
                            Cookies.get("timezone")
                          )
                        )}
                      </p>
                    </div>
                  </Link>
                );
              })}
            <div></div>
          </GenericTable>
        </div>
        <AddBlastDialog
          key={childKey} // used to trigger re-render on close so that form is reset
          open={addBlastDialogOpen}
          onClose={() => {
            setAddBlastDialogOpen(false);
            setChildKey(childKey + 1);
          }}
          onAddBlast={onAddBlast}
        />

        <Snackbar
          open={alertState.open}
          autoHideDuration={4000}
          onClose={() => setAlertState({ ...alertState, open: false })}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert severity={alertState.severity}>{alertState.message}</Alert>
        </Snackbar>
      </>
    );
  };

  return <>{renderContent()}</>;
}

export default BlastsView;
