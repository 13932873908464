import { updateActivePopup } from "@store/preblast/actionCreators";
import GenericPreblastPopup from "../GenericPreblastPopup/GenericPreblastPopup";
import { connect } from "react-redux";

type Props = {
  updateActivePreblastPopup: typeof updateActivePopup;
};

function HoleDetectionConfirmationPopup({ updateActivePreblastPopup }: Props) {
  const onAccept = () => {
    updateActivePreblastPopup(null);
  };

  return (
    <GenericPreblastPopup
      acceptLabel={"Ok"}
      onAccept={onAccept}
      hideReject={true}
    >
      <div>
        You can manually edit and add bounding boxes if there are any errors.
        Once you're satisfied, you can continue to drawing the scale points by
        clicking 3 in the right navigation bar.
      </div>
    </GenericPreblastPopup>
  );
}

const mapDispatchToProps = {
  updateActivePreblastPopup: updateActivePopup,
};

export default connect(
  null,
  mapDispatchToProps
)(HoleDetectionConfirmationPopup);
