import styles from "./DeletionConfirmationDialog.module.scss";
import { Fragment } from "react";
import { TextButton } from "../TextButton/TextButton";
import { Dialog } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CircularProgress from "@mui/material/CircularProgress";

type Props = {
  open: boolean;
  title: string;
  children: React.ReactNode;
  setError?: (error: boolean) => void;
  onConfirm: () => Promise<void> | void;
  onClose: () => void;
  placeholder?: string;
  isLoading?: boolean;
};

export default function DeletionConfirmationDialog({
  title,
  children,
  onConfirm,
  open,
  onClose,
  setError,
  placeholder,
  isLoading,
}: Props) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        className: styles.CustomDialog,
        style: { borderRadius: "0" },
      }}
    >
      {isLoading ? (
        <div className={styles.Loader}>
          <CircularProgress />
          <p>Loading...</p>
        </div>
      ) : (
        <Fragment>
          <div className={styles.Header}>
            {title}
            <div onClick={onClose} className={styles.ClearIcon}>
              <ClearIcon />
            </div>
          </div>
          <div className={styles.Content}>{children}</div>
          <div className={styles.Footer}>
            <TextButton
              externalClassName={`${styles.TextButton} ${styles.DeleteButton}`}
              label={"Delete"}
              onClick={onConfirm}
            />
            <TextButton
              externalClassName={styles.TextButton}
              label={"Cancel"}
              onClick={onClose}
            />
          </div>
        </Fragment>
      )}
    </Dialog>
  );
}
