import { ContextType } from "../../../data/enums/ContextType";
import "./EditorTopNavigationBar.scss";
import React from "react";
import classNames from "classnames";
import { AppState } from "../../../store";
import { connect } from "react-redux";
import {
  updateCursorMode,
  updateCustomCursorStyle,
} from "../../../store/general/actionCreators";
import { GeneralSelector } from "../../../store/selectors/GeneralSelector";
import { ViewPointSettings } from "../../../settings/ViewPointSettings";
import { ImageButton } from "../../Common/ImageButton/ImageButton";
import { ViewPortActions } from "../../../logic/actions/ViewPortActions";
import { LabelType } from "../../../data/enums/LabelType";
import { ISize } from "../../../interfaces/ISize";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";
import { CursorMode } from "@data/enums/CursorMode";
import { CustomCursorStyle } from "@data/enums/CustomCursorStyle";
import ActiveAction from "./ActiveAction/ActiveAction";

const BUTTON_SIZE: ISize = { width: 30, height: 30 };
const BUTTON_PADDING: number = 10;

const getButtonWithTooltip = (
  key: string,
  tooltipMessage: string,
  imageSrc: string,
  imageAlt: string,
  isActive: boolean,
  href?: string,
  onClick?: () => any
): React.ReactElement => {
  return (
    <Tooltip
      key={key}
      disableFocusListener={true}
      title={tooltipMessage}
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      placement="bottom"
    >
      <div>
        <ImageButton
          buttonSize={BUTTON_SIZE}
          padding={BUTTON_PADDING}
          image={imageSrc}
          imageAlt={imageAlt}
          href={href}
          onClick={onClick}
          isActive={isActive}
        />
      </div>
    </Tooltip>
  );
};

interface IProps {
  activeContext: ContextType;
  activeLabelType: LabelType;
  cursorMode: CursorMode;
  updateCursorModeAction: typeof updateCursorMode;
  updateCustomCursorStyleAction: typeof updateCustomCursorStyle;
}

const EditorTopNavigationBar: React.FC<IProps> = ({
  cursorMode,
  updateCursorModeAction,
  updateCustomCursorStyleAction,
}) => {
  const getClassName = () => {
    return classNames("EditorTopNavigationBar");
  };

  const imageDragOnClick = () => {
    if (GeneralSelector.getZoom() !== ViewPointSettings.MIN_ZOOM) {
      updateCursorModeAction(CursorMode.DRAG);
    }
  };

  const crossHairOnClick = () => {
    updateCustomCursorStyleAction(CustomCursorStyle.DEFAULT);
    updateCursorModeAction(CursorMode.CROSSHAIR);
  };

  return (
    <div className={getClassName()}>
      <div className="ButtonWrapper">
        {getButtonWithTooltip(
          "zoom-in",
          "zoom in",
          "/ico/zoom-in.png",
          "zoom-in",
          false,
          undefined,
          () => ViewPortActions.zoomIn()
        )}
        {getButtonWithTooltip(
          "zoom-out",
          "zoom out",
          "/ico/zoom-out.png",
          "zoom-out",
          false,
          undefined,
          () => ViewPortActions.zoomOut()
        )}
        {getButtonWithTooltip(
          "zoom-fit",
          "fit image to available space",
          "/ico/zoom-fit.png",
          "zoom-fit",
          false,
          undefined,
          () => ViewPortActions.setDefaultZoom()
        )}
        {getButtonWithTooltip(
          "zoom-max",
          "maximum allowed image zoom",
          "/ico/zoom-max.png",
          "zoom-max",
          false,
          undefined,
          () => ViewPortActions.setOneForOneZoom()
        )}
      </div>
      <div className="ButtonWrapper">
        {getButtonWithTooltip(
          "image-drag-mode",
          cursorMode === CursorMode.DRAG
            ? "turn off image drag mode"
            : "turn on image drag mode - works only when image is zoomed",
          "/ico/hand.png",
          "image-drag-mode",
          cursorMode === CursorMode.DRAG,
          undefined,
          imageDragOnClick
        )}
        {getButtonWithTooltip(
          "cursor-cross-hair",
          cursorMode === CursorMode.CROSSHAIR
            ? "turn off cursor cross-hair"
            : "turn on cursor cross-hair",
          "/ico/cross-hair.png",
          "cross-hair",
          cursorMode === CursorMode.CROSSHAIR,
          undefined,
          crossHairOnClick
        )}
      </div>
      <ActiveAction />
    </div>
  );
};

const mapDispatchToProps = {
  updateCursorModeAction: updateCursorMode,
  updateCustomCursorStyleAction: updateCustomCursorStyle,
};

const mapStateToProps = (state: AppState) => ({
  activeContext: state.general.activeContext,
  cursorMode: state.general.cursorMode,
  activeLabelType: state.labels.activeLabelType,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditorTopNavigationBar);
