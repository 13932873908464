import { LabelsActionTypes, LabelsState, IImageData } from "./types";
import { Actions } from "../Actions";
import { LabelType } from "data/enums/LabelType";
import { EditorContext } from "@logic/context/EditorContext";

const initialState: LabelsState = {
  activeLabelNameId: null,
  activeLabelType: null,
  activeLabelId: null,
  highlightedLabelId: null,
  imageData: null,
  firstLabelCreatedFlag: false,
  labels: [],
};

export function labelsReducer(
  state = initialState,
  action: LabelsActionTypes
): LabelsState {
  switch (action.type) {
    case Actions.UPDATE_ACTIVE_LABEL_NAME_ID: {
      return {
        ...state,
        activeLabelNameId: action.payload.activeLabelNameId,
      };
    }
    case Actions.UPDATE_ACTIVE_LABEL_ID: {
      return {
        ...state,
        activeLabelId: action.payload.activeLabelId,
      };
    }
    case Actions.UPDATE_HIGHLIGHTED_LABEL_ID: {
      return {
        ...state,
        highlightedLabelId: action.payload.highlightedLabelId,
      };
    }
    case Actions.UPDATE_ACTIVE_LABEL_TYPE: {
      return {
        ...state,
        activeLabelType: action.payload.activeLabelType,
      };
    }
    case Actions.UPDATE_IMAGE: {
      EditorContext.historyStack.push(state.imageData);
      return {
        ...state,
        imageData: action.payload.newImageData,
      };
    }
    case Actions.UPDATE_LABEL_NAMES: {
      return {
        ...state,
        labels: action.payload.labels,
      };
    }
    case Actions.UPDATE_FIRST_LABEL_CREATED_FLAG: {
      return {
        ...state,
        firstLabelCreatedFlag: action.payload.firstLabelCreatedFlag,
      };
    }
    case Actions.RESET_LABELS: {
      return initialState;
    }
    default:
      return state;
  }
}
