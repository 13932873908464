import { useNavigate, useLocation } from "react-router-dom";
import OutletLoadingScreen from "../../OutletLoadingScreen/OutletLoadingScreen";
import ContextBar from "../../ContextBar/ContextBar";
import Actions, { Action } from "views/AccountView/Actions/Actions";
import { useSiteViewContext } from "../SiteView";
import styles from "./SiteOverview.module.scss";
import { formatDate, convertTZ } from "utils/DatetimeUtil";
import Cookies from "js-cookie";
import { TextButton } from "views/Common/TextButton/TextButton";

export default function SiteOverview() {
  const navigate = useNavigate();
  const location = useLocation();

  const { site, siteIsLoading, setSiteDeletionDialogOpen } =
    useSiteViewContext();

  const renderContent = () => {
    if (!site || siteIsLoading) {
      return <OutletLoadingScreen />;
    }

    const hasLocation = site.location && site.location.length > 0;
    const siteLocation = hasLocation ? site.location : "No location";

    return (
      <div>
        <ContextBar
          activeRoute={[
            { name: "Sites", shortName: "Sites", href: "/sites" },
            {
              name: site.site_name,
              shortName: site.site_name,
              href: location.pathname,
            },
          ]}
        />
        <Actions>
          <Action
            onClick={() => navigate(location.pathname + "/blasts")}
            label="Blasts"
          />
          <Action
            onClick={() => navigate(location.pathname + "/settings")}
            label="Settings"
          />
        </Actions>
        <div className={styles.Content}>
          <div className={styles.InfoWrapper}>
            <dl className={styles.Info}>
              <dt className={styles.Label}>Site name</dt>
              <dd className={styles.Value}>{site.site_name}</dd>
            </dl>
            <dl className={styles.Info}>
              <dt className={styles.Label}>Location</dt>
              <dd
                className={`${styles.Value} ${!hasLocation ? styles.Null : ""}`}
              >
                {siteLocation}
              </dd>
            </dl>
            <dl className={styles.Info}>
              <dt className={styles.Label}>Permissions</dt>
              <dd className={styles.Value}>
                {site.permissions.charAt(0).toUpperCase() +
                  site.permissions.slice(1)}
              </dd>
            </dl>
            <dl className={styles.Info}>
              <dt className={styles.Label}>Date created</dt>
              <dd className={styles.Value}>
                {formatDate(
                  convertTZ(site.created_at_utc, Cookies.get("timezone"))
                )}
              </dd>
            </dl>
            <dl className={styles.Info}>
              <dt className={styles.Label}>Site ID</dt>
              <dd className={styles.Value}>{site.id}</dd>
            </dl>
          </div>
          <div className={styles.Divider} />
          <footer className={styles.Footer}>
            <TextButton
              onClick={() => setSiteDeletionDialogOpen(true)}
              buttonTheme="delete"
              label="Delete site"
            />
          </footer>
        </div>
      </div>
    );
  };

  return <>{renderContent()}</>;
}
