import styles from "./PostBlastView.module.scss";


export default function PostBlastView() {

  return (
    <>

      <div className={styles.wrapper}>
        <div className={styles.content}>
          <span>Under construction</span>
        </div>
      </div>
    </>
  );
}
