import * as React from "react";
import styles from "./TextButton.module.scss";
import classNames from "classnames";
import CircularProgress from "@mui/material/CircularProgress";

interface IProps {
  key?: string;
  label: string;
  onClick?: (e?: any) => any;
  style?: React.CSSProperties;
  isActive?: boolean;
  isDisabled?: boolean;
  externalClassName?: string;
  isLoading?: boolean;
  type?: "submit" | "button";
  buttonTheme?: "cancel" | "delete" | "update" | "default";
  renderIcon?: () => React.ReactNode;
  id?: string;
}

export const TextButton = (props: IProps) => {
  const {
    key,
    label,
    onClick,
    style,
    isActive,
    isDisabled,
    externalClassName,
    isLoading,
    type = "button",
    buttonTheme = "default",
    renderIcon = () => null,
    id
  } = props;

  const getClassName = () => {
    return classNames(styles.TextButton, {
      [styles.active]: isActive,
      [styles.disabled]: isDisabled || isLoading,
      [styles.loading]: isLoading,
      [styles.cancel]: buttonTheme === "cancel",
      [styles.delete]: buttonTheme === "delete",
      [styles.update]: buttonTheme === "update",
    });
  };

  const onClickHandler = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation();
    if (onClick) {
      onClick(event);
    }
  };

  return (
    <button
      className={`${getClassName()} ${externalClassName}`}
      onClick={onClickHandler}
      key={key}
      style={style}
      type={type}
      id={id}
    >
      <CircularProgress size={20} className={styles.loadingIcon} />

      <div className={`${styles.content}`}>
        {renderIcon()}
        <span className={styles.text}>{label}</span>
      </div>
    </button>
  );
};
