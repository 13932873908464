import styles from "./MobileMainView.module.scss";
import { ISize } from "../../interfaces/ISize";
import { AppState } from "../../store";
import { connect } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

interface IProps {
  size: ISize;
}

const MobileMainView = ({ size }: IProps) => {
  return (
    <div className={styles.MobileMainView}>
      <div
        className={styles.MobileMainViewContent}
        style={{ width: size.width }}
      >
        <div className={styles.FirstStage}>
          <img
            draggable={false}
            alt={"main-logo"}
            src={"/elevated-drillmap-ico.png"}
          />
          <div className={`${styles.TriangleHorizontal} ${styles.Bottom}`}>
            <div className={styles.TriangleHorizontalContent} />
          </div>
        </div>
        <div className={styles.SecondStage}>
          <div className={styles.Message}>
            The editor unfortunately can't be accessed on mobile devices. Please
            revisit this tool on a desktop device to gain access. Click{" "}
            <Link className={styles.Link} to="/">
              here
            </Link>{" "}
            to go back
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  size: state.general.windowSize,
});

export default connect(mapStateToProps, null)(MobileMainView);
