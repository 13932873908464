import { useNavigate } from "react-router-dom";
import styles from "./AuthView.module.scss";

export default function PasswordReset() {
  const navigate = useNavigate();
  const goBackToLogin = () => {
    navigate("/auth/login");
  };
  return (
    <>
      <div className={styles.header}>
        <h2>Password Reset Email Sent</h2>
      </div>
      <main className={styles.header}>
        <p style={{ marginBottom: "10px" }}>
          We've sent you an email with a link to reset your password. If you
          don't see it, check your spam folder.
        </p>
        <span className={styles.link} onClick={goBackToLogin}>
          Go back to login
        </span>
      </main>
    </>
  );
}
