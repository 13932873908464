import GenericPreblastPopup from "../GenericPreblastPopup/GenericPreblastPopup";
import styles from "./ChangeImageConfirmationDialog.module.scss";
import { connect } from "react-redux";
import {
  updateActivePopup,
  updateActivePreblastStep,
  updateBlastParameters,
  updateScale,
  updateStepsState,
} from "@store/preblast/actionCreators";
import { PreblastSteps } from "@store/preblast/types";
import { updateAppLoadingState } from "@store/general/actionCreators";
import { LoadingState } from "@data/enums/LoadingState";
import { AppState } from "@store/index";
import { BlastDetails } from "@store/blast/types";
import {
  updateImageData,
} from "@store/labels/actionCreators";
import { ImageDataUtil } from "utils/ImageDataUtil";
import { BlastPattern } from "@data/enums/BlastPattern";
import { PreblastApi } from "@api/elevat3dApi/blast/PreblastApi";

type Props = {
  updateActivePreblastPopup: typeof updateActivePopup;
  updateActivePreblastStep: typeof updateActivePreblastStep;
  updateAppLoadingState: typeof updateAppLoadingState;
  updateImageData: typeof updateImageData;
  updatePreblastStepsState: typeof updateStepsState;
  updateBlastParameters: typeof updateBlastParameters;
  updateScale: typeof updateScale;
  preblastImage: File;
  blastDetails: BlastDetails;
};

function ChangeImageConfirmationDialog({
  updateActivePreblastPopup,
  updateActivePreblastStep,
  updateAppLoadingState,
  updateImageData,
  updatePreblastStepsState,
  updateScale,
  updateBlastParameters,
  preblastImage,
  blastDetails,
}: Props) {
  const onAccept = async () => {
    updateAppLoadingState(LoadingState.LOADING);
    try {
      // await DrillMapApi.uploadPreblastImage();
      updateActivePreblastPopup(null);
      await PreblastApi.uploadPreblastImage(preblastImage, {
        siteId: blastDetails.siteId,
        blastId: blastDetails.blastId,
      });
      restartPreblast();
      updateActivePreblastStep(PreblastSteps.HOLE_DETECTION);
      updateAppLoadingState(LoadingState.IDLE);
    } catch (error) {
      updateAppLoadingState(LoadingState.ERROR);
    }
  };

  const restartPreblast = () => {
    updateImageData(
      ImageDataUtil.createImageDataFromFileData(preblastImage)
    );

    updateBlastParameters({
      burden: 0,
      spacing: 0,
      blastPattern: BlastPattern.STAGGERED,
    });
    updateScale({
      source: null,
      target: null,
      scaleDistanceCm: 0,
    });
  };

  const onGoBack = () => {
    updateActivePreblastPopup(null);
  };
  return (
    <GenericPreblastPopup
      title="Change image"
      onAccept={onAccept}
      onReject={onGoBack}
      acceptLabel="Continue"
      rejectLabel="Cancel"
    >
      <div className={styles.msg}>
        Are you sure you want to change the image? This will reset all the
        previous progress made. Changes can&apos;t be undone.
      </div>
    </GenericPreblastPopup>
  );
}

const mapDispatchToProps = {
  updateActivePreblastPopup: updateActivePopup,
  updateActivePreblastStep: updateActivePreblastStep,
  updateAppLoadingState,
  updateImageData,
  updatePreblastStepsState: updateStepsState,
  updateBlastParameters,
  updateScale,
};

const mapStateToProps = (state: AppState) => ({
  preblastImage: state.preblast.preblastImage,
  blastDetails: state.blast.blastDetails,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeImageConfirmationDialog);
